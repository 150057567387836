import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import "./App.css";
// import SocialFollow from "./socialFollow";
import buildbear from "./assets/bblogo.png";
import { publicData } from "./api/landing";
import linkedin from "./landing/assets/linkedin.svg";
import mail from "./landing/assets/mail.svg";
import pp from "./landing/assets/pp.svg";
import telegram from "./landing/assets/telegram.svg";
import tos from "./landing/assets/tos.svg";
import twitter from "./landing/assets/twitter.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
// import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import ReactGA from 'react-ga4';
import LandingFooter from "./components/LandingFooter";


const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'rgba(255,255,255,0.87) !important',
    fontSize: 13,
  },

}));

function Landing() {
  const [avgNodeLiveTime, setAvgNodeLiveTime] = useState();

  const [totalNodes, setTotalNodes] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [avgNodeTimeInHours, setAvgNodeTimeInHours] = useState(0);

  const counterDuration = 0.8;
  // const gaEventTracker = useAnalyticsEventTracker('Landing Page');
  const howToUseEvent = () => {
    ReactGA.event({
      category: 'BuildBear category',
      action: 'How to Use',
    });
  };

  const launchAppEvent = () => {
    ReactGA.event({
      category: 'BuildBear category',
      action: 'Launch App',
    });
  };
  const formatHours = useCallback((hoursGiven) => {
    if (hoursGiven < 24) {
      const hours = hoursGiven;
      return `${hours} ${hours === 1 ? "Hour" : "Hours"}`;
    } else {
      const days = Math.floor(hoursGiven / 24);
      const hours = hoursGiven % 24;

      return `${days} ${days === 1 ? "Day" : "Days"} ${hours !== 0 ? `${hours} ${hours === 1 ? "Hour" : "Hours"}` : ""
        }`;
    }
  }, []);

  useEffect(() => {
    function secondsToHours() {
      var seconds = avgNodeLiveTime;
      const hours = Math.floor(seconds / 3600);
      setAvgNodeTimeInHours(hours);
    }

    secondsToHours();
  }, [avgNodeLiveTime]);

  useEffect(() => {
    const getAdminDetails = async () => {
      try {
        let response = await publicData();
        // setData(response.totalLiveNodes);
        // console.log("Response", response);
        if (response !== undefined) {
          const avgNodeLiveTime = response.data.avgNodeLiveTime ?? 28800;
          setAvgNodeLiveTime(avgNodeLiveTime < 0 ? 273600 : avgNodeLiveTime);
          setTotalNodes(response.data.totalNodes + 1);
          setTotalTransactions(response.data.totalTransactions + 447314);
        } else {
          setAvgNodeLiveTime(63000);
          setTotalNodes(155);
          setTotalTransactions(3450);
        }
      } catch (err) {
        // console.log("Err", err);
      }
    };

    getAdminDetails();
    setInterval(getAdminDetails, 5_000);
  }, []);

  return (
    <div>
      {/* <a href="http://bit.ly/3PE6p2q" target="_blank" rel="noreferrer">
        <h4 className="banner">Hi, You are currently using the Upgraded version of BuildBear.  More on this over here</h4>
      </a> */}
      <div className="main">
        <div>
          <img height="240vh" className="bb-logo-img" src={buildbear} alt="Build Bear logo" />
        </div>
        <div className="break"></div>
        <div className="bb-text ">build bear</div>
        <div className="break"></div>
        <div className="meta-data ">
          Testing <span>DApps</span> at Scale
        </div>
        <div className="break"></div>
        <div>
          <button
            className="custom-button1"
            onClick={() => {
              window.open("https://bit.ly/bb-notion-docs"); howToUseEvent();
              // gaEventTracker('How to Use'); 
            }}
          >
            How to use?
          </button>
        </div>
        <div>
          <button
            className="custom-button2"
            onClick={() => {
              window.open(`https://home.${process.env.REACT_APP_BASE_URL}`); launchAppEvent();
              // gaEventTracker('Launch App'); 
            }}
          >
            Launch App
          </button>
        </div>
        <div className="break"></div>
        <div className="stats-data ">BuildBear Stats</div>
        <div className="break"></div>
        <div className="statbox ">
          <h3>
            <CountUp
              end={avgNodeTimeInHours}
              duration={counterDuration}
              preserveValue={true}
              formattingFn={formatHours}
            ></CountUp>
          </h3>
          <p>Average Period / Testnet</p>
        </div>
        <div className="statbox ">
          <h3>
            <CountUp
              end={totalNodes}
              duration={counterDuration}
              preserveValue={true}
            ></CountUp>
          </h3>
          <p>Total Testnets Created</p>
        </div>
        <div className="statbox ">
          <h3>
            <CountUp
              end={totalTransactions}
              duration={counterDuration}
              preserveValue={true}
            ></CountUp>
          </h3>
          <p>Total Transactions Debugged</p>
        </div>
        <div className="break"></div>
        {/* <div className="team reach">
        <button
          className="custom-button3 poppins"
          onClick={() => window.open("mailto:team@buildbear.io")}
        >
          Reach out to us
        </button>
      </div>
      <div  className="team careers">
        <button
          className="custom-button3 poppins"
          onClick={() => window.open("https://bit.ly/buildbear-careers")}
        >
          Careers
        </button>
      </div> */}
        <div className="footer ">
          <p>Reach out to us</p>
          <h2 className="">
            <a href="mailto:team@buildbear.io">team@buildbear.io</a>
          </h2>
        </div>
        <div className="break"></div>
        {/* <hr style={{ width: '100%' }}></hr> */}
        <LandingFooter />
      </div>
    </div>
  );
}

export default Landing;

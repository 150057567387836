import "./App.css";
import { Routes, Route } from "react-router-dom";
import RpcInput from "../pages/RpcInput";
import Home from "../pages/ExplorerHome";
import { createContext, useReducer } from "react";
import { initialState, reducer } from "../store/reducer/explorer";
import Search from "../pages/Search";
// import Transaction from "./pages/Search/Transaction"
import Block from "../pages/Search/Block"
import Address from "../pages/Search/Address/index";
import PageNotFound from "../pages/PageNotFound";
import Logs from "../components/Transaction/Logs";
import Trace from "../components/Trace";
import AllTransactionsPage from "../pages/AllTransaction";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import TransactionDetails from "../components/TransactionDetails";
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import { getDefaultNetworks, getNodeUrl } from "../api/explorer";
import { Helmet } from "react-helmet";

export const AppContext = createContext();
const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#000",
    },
  }
});


function Explorer() {
  let [searchParams] = useSearchParams();
  let { nHash } = useParams();
  const location = useLocation();
  let rpcUrl = null;

  const nodeIndex = location?.pathname?.split('/')?.indexOf('node');
  // Decode Rpc from url
  if (nHash) {
    rpcUrl = getNodeUrl(nHash);
  } else if (nodeIndex >= 0) {
    nHash = location.pathname.split('/')[nodeIndex + 1];
    rpcUrl = getNodeUrl(nHash);
  }
  else if (searchParams.has("chainId")) {
    const chainId = decodeURIComponent(searchParams.get("chainId"));
    const index = getDefaultNetworks().findIndex((networkObj) => {
      return networkObj.chainId === chainId;
    });
    if (index !== -1) {
      rpcUrl = getDefaultNetworks()[index].rpc;
    }

  }
  else if (searchParams.has("rpc")) {
    rpcUrl = decodeURIComponent(searchParams.get("rpc"));
  }
  // console.log(rpcUrl);

  const [state, dispatch] = useReducer(reducer, rpcUrl ? { rpcUrl: rpcUrl } : initialState);
  return (

    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <AppContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <Helmet>
          <title>Explorer || BuildBear Testnet</title>
        </Helmet>
        {/* <a href="http://bit.ly/3PE6p2q" target="_blank" rel="noreferrer">
          <h4 className="banner">Hi, You are currently using the Upgraded version of BuildBear.  More on this over here</h4>
        </a> */}
        <div className="App">
          <Routes>
            <Route path="/" element={<RpcInput />} />
            <Route path="/home" element={<Home />} />
            <Route path="/node/:nHash" element={<Home />} />
            <Route path="/:nHash" element={<Home />} />

            <Route path="*" element={<Search />} >
              <Route path="tx/:txHash" element={<TransactionDetails />} />
              <Route path="tx/:txHash/logs" element={<Logs />} />
              <Route path="tx/:txHash/trace" element={<Trace />} />
              <Route path="block/:blockNum" element={<Block />} />
              <Route path="address/:addressHash/*" element={<Address />} />

              <Route path="node/:nHash/tx/:txHash" element={<TransactionDetails />} />
              <Route path="node/:nHash/tx/:txHash/logs" element={<Logs />} />
              <Route path="node/:nHash/tx/:txHash/trace" element={<Trace />} />
              <Route path="node/:nHash/block/" element={<PageNotFound />} />
              <Route path="node/:nHash/block/:blockNum" element={<Block />} />
              <Route path="node/:nHash/address/:addressHash/*" element={<Address />} />
              <Route path="node/:nHash/transactions/*" element={<AllTransactionsPage />} />

              <Route path=":nHash/tx/:txHash" element={<TransactionDetails />} />
              <Route path=":nHash/tx/:txHash/logs" element={<Logs />} />
              <Route path=":nHash/tx/:txHash/trace" element={<Trace />} />
              <Route path=":nHash/block/" element={<PageNotFound />} />
              <Route path=":nHash/block/:blockNum" element={<Block />} />
              <Route path=":nHash/address/:addressHash/*" element={<Address />} />
              <Route path=":nHash/transactions/*" element={<AllTransactionsPage />} />

              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </div>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default Explorer;

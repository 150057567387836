import "../styles/App.css";
import "./App.css"
import { Routes, Route } from "react-router-dom";
import { createContext, useReducer } from "react";
import { initialState, reducer } from "../store/reducer/faucet";

import Home from "../pages/Home";
import Node from "../pages/Node";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NotFound from "../pages/NotFound";
import { Helmet } from "react-helmet";
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const AppContext = createContext();
function Faucet() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Helmet>
        <title>Faucet || BuildBear Testnet</title>
      </Helmet>
      {/* <a href="http://bit.ly/3PE6p2q" target="_blank" rel="noreferrer">
        <h4 className="banner">Hi, You are currently using the Upgraded version of BuildBear.  More on this over here</h4>
      </a> */}
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path=":nodeHash" element={<Node />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default Faucet;

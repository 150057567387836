import { useLocation, Link, Navigate, useParams } from "react-router-dom";

function QueryLink({ to, children, ...props }) {
  let location = useLocation();
  let { nHash } = useParams();

  if (nHash) {
    if (!to || to?.charAt(0) !== '/') {
      // This is a relative link, let react-router handle it

      return (
        <Link to={`#${to}${location.search}`} {...props} >
          {children}
        </Link>
      )
    } else if (to === '/home') {
      // ReRoute home to nodeHome
      return (
        <Link to={`/${nHash}${location.search}`} {...props} >
          {children}
        </Link>
      )
    }
    else {
      // This is absolute link, prepend nodeHash to it

      return (
        <Link to={`/${nHash}${to}${location.search}`} {...props} >
          {children}
        </Link>
      )
    }
  } else {
    return (
      <Link to={`${to}${location.search}`} {...props} >
        {children}
      </Link>
    )
  }

}

function QueryNavigate({ to, ...props }) {
  let location = useLocation();
  let { nHash } = useParams();
  if (nHash) {
    if (!to || to?.charAt(0) !== '/') {
      // This is a relative link, let react-router handle it
      return (
        <Navigate to={`${to}${location.search}`} {...props} />
      )
    } else if (to === '/home') {
      // ReRoute home to nodeHome
      return (
        <Navigate to={`/${nHash}${location.search}`} {...props} />
      )
    }
    else {
      // This is absolute link, prepend nodeHash to it
      return (
        <Navigate to={`/${nHash}${to}${location.search}`} {...props} />
      )
    }
  } else {
    return (
      <Navigate to={to + location.search} {...props} />
    )
  }
}

export {
  QueryLink,
  QueryNavigate
};
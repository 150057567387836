import { ethers } from "ethers";
import { useEffect, useState } from "react";
import '../../../eth-scan/style.css'
import { Spinner } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../eth-scan/App";
import { getTransactions } from "../../../api/explorer";
// import TransactionList from "./TransactionList";
import { Tabs, Tab, AppBar } from '@mui/material'
import Footer from "../../../components/Footer";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AllTransactions from "../../../components/node/allTransactions";
import TokenToolTip from "../../../components/TokenToolTip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Account = () => {

    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const addressHash = useParams().addressHash;
    const { state } = useContext(AppContext);
    let { nHash } = useParams();
    const [tab, setTab] = useState(0);
    const rpcUrl = state.rpcUrl;
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

    const [balance, setBalance] = useState()
    const [transactionCount, setTransactionCount] = useState()

    // tabs
    // const [setTabVal] = useState(0)
    // const handleTabs = (e, val) => {
    //     setTabVal(val)
    // }

    async function getAddressData() {
        // console.log(await getTransactions(addressHash, 5));
        setLoading(true);
        setErrMsg(null);

        try {
            const balance = await provider.getBalance(addressHash);
            const txCount = await provider.getTransactionCount(addressHash)
            const gweiBalance = ethers.utils.formatUnits(balance, 'ether')
            setBalance(gweiBalance);
            setTransactionCount(txCount)

        } catch (err) {
            if (err.message.includes("could not detect network")) {
                setErrMsg("Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.")
            }
            else if (err.message.includes("bad address checksum")) {
                setErrMsg("Sorry, Address not found.")
            } else {
                setErrMsg("Sorry we encountered an error. Please try again later.");
            }
            // console.log(err.message);
        } finally {
            setLoading(false);
        }

    }
    useEffect(() => {
        getAddressData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useParams().addressHash]);

    if (errMsg) {
        return (
            <div className="containerbox">
                <div className="errContainer">
                    <i className='fas fa-exclamation-triangle' style={{ color: 'red', fontSize: '56px', marginTop: '40px' }}></i>
                    <h2 style={{ color: '#403F3F', fontSize: '30px', fontFamily: 'Poppins', marginTop: '20px' }}>Oh Snap!</h2>
                    <div className="errMsg">
                        {errMsg}
                    </div>
                    <button><a target="_blank" rel="noreferrer" href="https://mail.google.com/mail/?view=cm&fs=1&to=team@buildbear.io&su=Reporting Bug&body=Please-report-your-issue-and-provide-the-following-tx-hash-or-address-below">Report</a></button>

                </div>
            </div>
        )
    } else if (loading) {
        return  <>
        {/* <Spinner animation="grow" style={{zIndex: '100', color: 'white'}} /> */}
        <div
            style={{
                color: "black",
                textAlign: "center",
                padding: "400px 0",
            }}
        >
            Please wait. BuildBear Explorer is loading
        </div>
    </>
    }
    else {

        return (
            <>
                <div className="account-wrapper" style={{ minHeight: '65vh' }}>
                    <div style={{ display: 'flex', flexDirection: "row" }}>
                            <h3 style={{display: "flex",  alignItems: "center", }} ><span style={{}} >Address</span> <span style={{ color: '#808080', fontSize: 'medium', marginLeft: "10px", paddingTop: "10px"}}><TokenToolTip value={addressHash} /></span></h3>
                        
                        {/* <CopyToClipboard text={addressHash}><i style={{ cursor: 'pointer', padding: '12px' }} className="fa fa-clipboard"></i></CopyToClipboard> */}
                        <IconButton
                                color="primary"
                                style={{
                                    cursor: "pointer",
                                    margin: "0px 20px",
                                    borderRadius: "100%",
                                    height: "40px",
                                    width: "40px"
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(addressHash);
                                }}
                            >
                                <ContentCopyIcon sx={{ fontSize: "15px" }} />
                            </IconButton>
                    </div>
                    {/* <div className='tx-hr'><hr /></div> */}

                    <div className="containerbox" style={{ borderRadius: '16px' }}>

                        <AppBar position="static" style={{ backgroundColor: '#E8E8E8', boxShadow: 'none', borderRadius: '16px 16px 0 0' }}>
                            <Tabs value={tab} >
                                <Tab label="Overview" onClick={() => setTab(0)} />
                                {nHash && <Tab label="Transactions" onClick={() => setTab(1)} />}
                            </Tabs>
                        </AppBar>
                        {!tab ? <div className="transactionContainer">
                            {/* <div className="tx-navbar" style={{marginLeft: '-35px', marginTop: '-35px', width: '1300px'}}> */}
                            {/* <div className="tx-navbar" style={{marginLeft: '-35px', marginTop: '-35px'}}>
                            <div>
                                <Link to={`/address/${addressHash}`} >
                                    <h2>Overview</h2>
                                </Link>
                            </div>
                        </div> */}

                            {/* <div className="row" style={{ paddingTop: '45px' }}>
                            <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                <b>
                                    RPC URL:
                                </b>
                            </div>
                            <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                {rpcUrl}
                            </div>
                        </div>
                        <hr /> */}



                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Balance:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {balance} BB ETH
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Transaction Count:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {transactionCount}
                                </div>
                            </div>
                            <hr />



                        </div>
                            : <AllTransactions />}
                    </div>
                    {/* <div className="transactionlist-wrapper">
                <AppBar position="static" style={{maxWidth: '1100px', margin: 'auto', backgroundColor: '#E8E8E8'}}>
                            <Tabs value={0} >
                                <Tab label="Transactions" />
                            </Tabs>
                        </AppBar>
                <TransactionList />
                </div> */}


                </div>
                <div>
                    <Footer />
                </div>
            </>
        )
    }

}

export default Account;
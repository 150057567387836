import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import { getBackendBaseUrl } from "../../api/explorer";
import "../../styles/AllTx.css";
// import Footer1 from "../../components/Footer";
import TokenToolTip from "../../components/TokenToolTip"

import { ethers } from "ethers";
import moment from "moment";
// import Popover from "@mui/material/Popover";
import { Tooltip } from "@mui/material";
import { parseBytes32String } from "ethers/lib/utils";

const bbToken = "0xC014BA5EC014ba5ec014Ba5EC014ba5Ec014bA5E";

const AllTransactions = () => {
    const [data, setData] = useState([]);
    // const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    let { nHash } = useParams();
    let { blockNum } = useParams();
    let { addressHash } = useParams();

    const handlePageChange = (event, p) => {
        setPage(p);
    };

    const Footer = () => {
        return (
            <div
                style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItem: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                }}
            >
                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                    />
                )}
            </div>
        );
    };
    function formatNumber(num) {
        console.log(num);
        return parseFloat(num.toFixed(4));
    }
    const getEtherValue = (gasused, maxfee) => {
        try {
            const weiTxval = ethers.BigNumber.from(gasused).mul(maxfee);
            // const weiTxval = gasused * maxfee;
            // const gweiGas = ethers.utils.formatUnits(bignum, 'gwei')
            // const weiValue = gweiGas * 1000000000;

            return ethers.utils.formatEther(weiTxval);
        } catch (e) {
            //  console.log('no GasFee detected')
        }
    };

    //Snaphit(true) - purple
    //Failed(status 0) - red
    //Snapshot(true) and Failed(status 0) - purple and red
    //Snaphit(false) amf status 1 - green

    const columns = [
        // {
        //     field: "isSnapshot",
        //     headerName: "Status",
        //     width: "100",
        //     renderCell: (props) =>
        //         props.row.isSnapshot ? (
        //             (props.row.isSnapshot && props.row.receipt.status === 0 && (
        //                 <>
        //                     <Tooltip
        //                         arrow
        //                         title={"This is a Failed Transaction"}
        //                     >
        //                         <div
        //                             style={{
        //                                 width: "15px",
        //                                 height: "15px",
        //                                 borderRadius: "50%",
        //                                 backgroundColor: "#dd2e44",
        //                             }}
        //                         >
        //                             {" "}
        //                         </div>
        //                     </Tooltip>
        //                     <div style={{ padding: "5px" }}></div>
        //                     <Tooltip
        //                         arrow
        //                         title={"This is a Snapshot Transaction"}
        //                     >
        //                         <div
        //                             style={{
        //                                 width: "15px",
        //                                 height: "15px",
        //                                 borderRadius: "50%",
        //                                 backgroundColor: "#ab8ed7",
        //                             }}
        //                         >
        //                             {" "}
        //                         </div>
        //                     </Tooltip>
        //                 </>
        //             )) ||
        //             (props.row.isSnapshot && (
        //                 <Tooltip arrow title={"This is a Snapshot Transaction"}>
        //                     <div
        //                         style={{
        //                             width: "15px",
        //                             height: "15px",
        //                             borderRadius: "50%",
        //                             backgroundColor: "#ab8ed7",
        //                         }}
        //                     >
        //                         {" "}
        //                     </div>
        //                 </Tooltip>
        //             )) ||
        //             (props.row.receipt.status === 0 && (
        //                 <Tooltip arrow title={"This is a Failed Transaction"}>
        //                     <div
        //                         style={{
        //                             width: "15px",
        //                             height: "15px",
        //                             borderRadius: "50%",
        //                             backgroundColor: "#dd2e44",
        //                             display: "block",
        //                             marginLeft: "auto",
        //                             marginRight: "auto",
        //                         }}
        //                     >
        //                         {" "}
        //                     </div>
        //                 </Tooltip>
        //             ))
        //         ) : (
        //             //Change this logic
        //             <Tooltip arrow title={""}>
        //                 <div
        //                     style={{
        //                         width: "15px",
        //                         height: "15px",
        //                         borderRadius: "50%",
        //                         backgroundColor: "green",
        //                         display: "block",
        //                         marginLeft: "auto",
        //                         marginRight: "auto",
        //                     }}
        //                 >
        //                     {" "}
        //                 </div>
        //             </Tooltip>
        //         ),
        // },
        {
            field: "isSnapshot",
            headerName: "Status",
            width: "100",
            renderCell: (props) =>
                props.row.loadingStatus ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Tooltip arrow title={"This is a Pending Transaction"}>
                            <div
                                style={{
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    backgroundColor: "#7d8200",
                                }}
                            >
                                {" "}
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <div>
                                {props.row.isSnapshot ? (
                                    <Tooltip
                                        arrow
                                        title={"This is a Snapshot Transaction"}
                                    >
                                        <div
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                borderRadius: "50%",
                                                backgroundColor: "#ab8ed7",
                                                margin: "0px 5px"
                                            }}
                                        >
                                            {" "}
                                        </div>
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div>
                                {props.row.receipt.status === 1 ? (
                                    
                                     <Tooltip
                                     arrow
                                     title={
                                         "This is a Successfull Transaction"
                                     }
                                 >
                                     <div
                                         style={{
                                             width: "15px",
                                             height: "15px",
                                             borderRadius: "50%",
                                             backgroundColor: "#008000",
                                             margin: "0px 5px"

                                         }}
                                     >
                                         {" "}
                                     </div>
                                 </Tooltip>
                                ) : (
                                    <Tooltip
                                    arrow
                                    title={"This is a Failed Transaction"}
                                >
                                    <div
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            borderRadius: "50%",
                                            backgroundColor: "#dd2e44",
                                            margin: "0px 5px"
                                        }}
                                    >
                                        {" "}
                                    </div>
                                </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                ),
        },
        {
            field: "hash",
            headerName: "Transaction Hash",
            width: "350",
            renderCell: (props) =>
                props && props.row.loadingStatus === "pending" ? (
                    <span
                        style={{
                            marginLeft: "auto",
                            marginRight: "10px",
                            "text-overflow": "ellipsis",
                            "white-space": "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        {props.row.hash}
                    </span>
                ) : (
                    <a
                        className="tx-hash active-link"
                        target="_blank"
                        rel="noreferrer"
                        href={`/${nHash}/tx/${props.row.hash}`}
                    >
                        {props.row.hash}{" "}
                    </a>
                ),
        },
        {
            field: "block",
            headerName: "Block",
            width: "100",
            renderCell: (props) =>
                props && props.row.loadingStatus === "pending" ? (
                    <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                        Pending...
                    </span>
                ) : (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`/${nHash}/block/${props.row.blockNumber}`}
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                        className="active-link"
                    >
                        {props.row.blockNumber}
                    </a>
                ),
        },
        {
            field: "Age",
            headerName: "Age",
            width: "120",
            renderCell: (props) =>
                props && props.row.loadingStatus === "pending" ? (
                    <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                        Pending...
                    </span>
                ) : (
                    <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                        {moment(props.row.date).fromNow()}
                    </span>
                ),
        },
        {
            field: "from",
            headerName: "From",
            width: "210",
            renderCell: (props) =>
                props && props.row.loadingStatus === "pending" ? (
                    <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                        Pending...
                    </span>
                ) : (
                    <a
                        className="from-val active-link "
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                        target="_blank"
                        rel="noreferrer"
                        href={`/${nHash}/address/${props.row.from}`}
                    >
                        {/* {props.row.from} */}
                        <TokenToolTip value={props.row.from} class="link-blue" />
                    </a>
                ),
        },
        {
            field: "to",
            headerName: "To",
            width: "210",
            renderCell: (props) =>
                props && props.row.loadingStatus === "pending" ? (
                    <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                        Pending...
                    </span>
                ) : props.row.to == null ? (
                    "Contract Created"
                ) : (
                    <a
                        className="from-val active-link"
                        target="_blank"
                        rel="noreferrer"
                        href={`/${nHash}/address/${props.row.to}`}
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                        {props.row.to}
                    </a>
                ),
        },
        {
            field: "GasFee",
            headerName: "GasFee",
            width: "200",

            renderCell: (props) => (
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    {props && props.row.loadingStatus === "pending" ? (
                        <span>Pending...</span>
                    ) : (
                        <>
                            {props.row.receipt && props.row.maxFeePerGas ? (
                                <span>
                                    {getEtherValue(
                                        props.row.receipt.gasUsed,
                                        props.row.maxFeePerGas
                                    )}
                                </span>
                            ) : (
                                <span>Null</span>
                            )}
                        </>
                    )}
                </div>
            ),
        },
        // {
        //     field: "Status",
        //     headerName: "Status",
        //     width: "120",
        //     renderCell: (props) => (
        //         <p
        //             className=" "
        //             style={{ marginLeft: "auto", marginRight: "auto" }}
        //         >
        //             {props && props.row.loadingStatus === "pending" ? (
        //                 <span
        //                     className="transactions-Status"
        //                     style={{
        //                         backgroundColor: "red",
        //                         borderRadius: "5px",
        //                         padding: "2px 8px",
        //                     }}
        //                 >
        //                     Pending
        //                 </span>
        //             ) : (
        //                 <span
        //                     className="transactions-Status"
        //                     style={{
        //                         backgroundColor: "green",
        //                         borderRadius: "5px",
        //                         padding: "2px 8px",
        //                     }}
        //                 >
        //                     Complete
        //                 </span>
        //             )}{" "}
        //         </p>
        //     ),
        // },
    ];

    useEffect(() => {
        let query = "";
        if (blockNum) query += `&block=${blockNum}&`;
        if (addressHash) query += `&address=${addressHash}`;
        axios
            .get(
                `${getBackendBaseUrl()}/node/transaction/${nHash}?page=${page}${query}`
            )
            .then((res) => {
                let rowData = [];
                if (res.data.error) {
                    console.log("Error");
                    setData(rowData);
                } else {
                    // eslint-disable-next-line
                    res.data.transactions.map((transaction) => {
                        rowData.push({
                            ...transaction,
                            id: transaction._id,
                        });
                    });
                    // console.log(rowData);
                    // let temp = rowData.filter(
                    //     (item) => item && !item.loadingStatus
                    // );
                    setData(rowData);
                    // console.log(data);

                    setTotalPages(Math.ceil(res.data.length / 24));
                    // console.log(res);
                }
            })
            .catch((err) => {
                // console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nHash, page]);

    return (
        <>
            <div
                className="all-transactions-container"
                style={{ width: "100%", borderRadius: "16px" }}
            >
                <Container style={{ borderRadius: "16px", maxWidth: "1360px" }}>
                    <Box
                        sx={{ height: "80vh", width: 1, mb: 2 }}
                        style={{
                            background: "white",
                            textAlign: "center",
                            borderRadius: "16px",
                        }}
                    >
                        <DataGrid
                            style={{ borderRadius: "16px", color: "#000" }}
                            columns={columns}
                            rows={data}
                            disableColumnFilter
                            disableColumnMenu
                            autoPageSize={true}
                            rowsPerPageOptions={[24]}
                            pageSize={24}
                            components={{
                                Footer: Footer,
                            }}
                        />
                    </Box>
                </Container>
                {/* 
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "20px 0px",
                    }}
                >
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#dd2e44",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px", marginRight: "20px" }}>
                        <p style={{ fontSize: "18px" }}>
                            {" "}
                            - Snapshot Transaction
                        </p>
                    </div>
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#ab8ed7",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px", marginRight: "20px" }}>
                        <p style={{ fontSize: "18px" }}>
                            - Failed Transaction{" "}
                        </p>
                    </div>
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "green",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px" }}>
                        <p style={{ fontSize: "18px" }}>
                            - No Snapshot & Success
                        </p>
                    </div>
                </div> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "20px 0px",
                    }}
                >
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#ab8ed7",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px", marginRight: "20px" }}>
                        <p style={{ fontSize: "18px" }}>
                            {" "}
                            - Snapshot Transaction
                        </p>
                    </div>

                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "green",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px", marginRight: "20px" }}>
                        <p style={{ fontSize: "18px" }}>
                            - Successfull Transaction
                        </p>
                    </div>
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#7d8200",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px", marginRight: "20px" }}>
                        <p style={{ fontSize: "18px" }}>
                            - Pending Transaction
                        </p>
                    </div>
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            // backgroundColor: "#ab8ed7",
                            backgroundColor: "#dd2e44",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ padding: "0px 10px", marginRight: "20px" }}>
                        <p style={{ fontSize: "18px" }}>- Failed Transaction</p>
                    </div>
                </div>
            </div>
            {/* <Footer1 /> */}
        </>
    );
};

export default AllTransactions;

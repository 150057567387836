import React, { useContext, useRef, useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Styled from "styled-components";
import { AuthContext } from "../home/App";
import Box from "@mui/material/Box";
import "../styles/Dashboard.css";
import Nav from "../components/Nav";
import ApiComponent from "../components/ApiKeyComponent";
import { getAllUserContainers } from "../api/home";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import ReactGA from 'react-ga4';

const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'rgba(255,255,255,0.87) !important',
    fontSize: 13,
  },
}));

const createSidebarEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Create Sidebar',
  });
};

export default function Dashboard() {
  const { state, dispatch } = useContext(AuthContext);
  const freshContainers = useRef();
  const [loading, setLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [bool, setBool] = useState(false);
  useEffect(() => {
    async function getContainers() {
      const response = await getAllUserContainers();
      if (response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
      } else if (response.err) {
        setContainers([]);
        freshContainers.current = [];
      } else {
        const data = response.data.map((container) => {
          container.restart = {
            loading: false,
            disabled: false,
          };
          container.delete = {
            loading: false,
            disabled: false,
          };
          container.metamask = {
            loading: false,
            disabled: false,
          };
          container.nodeId = container.nodeId;
          container.showRpc = false
          return container;
        });
        setContainers(data);
        freshContainers.current = data;
      }
      setLoading(false);
    }
    if (mounted) {
      getContainers();
    }

    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dispatch, mounted, bool]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < containers.length; i++) {
      if (containers[i].status === "live" || containers[i].status === "started") {
        count++;
      }
    }
    if (count >= 2) {
      if (process.env.REACT_APP_BASE_URL === "buildbear.io") {
        setBool(true);
      }
      else {
        (setBool(false))
      }
    }
  }, [containers]);

  if (!state.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };
  return (
    <Wrapper>
      <>
        <Nav />
        <div className="dash-wrapper">
          <div className="dash-sidebar">
            <ul>
              <div>
              </div>
              <div className="sidebar-list-bottom">
                <Link className="nav-btn" to="/">
                  <li><b>
                    Home
                  </b>
                  </li>
                </Link>
                <Link className="nav-btn" to="/apikey">
                  <li><b>
                    API Keys
                  </b>
                  </li>
                </Link>
                <DarkToolTip
                  title={bool ? "Apologies, you already have 2 live testnets. Currently, all users are limited to have only 2 live testnets.  If you need to create additional testnets, you could reach out to us at team@buildbear.io" : ""}
                  arrow
                  placement="top"

                >
                  {bool ?
                    <Link className="nav-btn" to='#'>
                      <li><b className="tooltip">
                        Create
                      </b>
                      </li>
                    </Link> :
                    <Link className="nav-btn" to='/create' >
                      <li><b className="tooltip">
                        <a onClick={createSidebarEvent}>Create</a>
                      </b>
                      </li>
                    </Link>
                  }
                </DarkToolTip>
                <a className="nav-btn" href="https://buildbear.notion.site/BuildBear-Wiki-7ebf492288b44e0d84ee12d676d462b4/" target="_blank" rel="noreferrer">
                  <li><b>
                    How it works
                  </b>
                  </li>
                </a>
                {state.isAdmin && (
                  <Link className="nav-btn" to="/admin">
                    <li><b>
                      Admin
                    </b>
                    </li>
                  </Link>
                )}
                <li onClick={() => handleLogout()}>
                  <b>Sign Out</b>
                </li>
              </div>
            </ul>
          </div>
          <div className="dashboardBody">
            <Box className="dashBoardBox">
              <Box className="dashHeader">
              </Box>
              <ApiComponent />
            </Box>
          </div>
        </div>
      </>
    </Wrapper>
  );
}

const Wrapper = Styled.section`
.container{
  display: flex;
  flex-direction: column;
  height: 7vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: Arial;
  .container{
    display: flex;
    flex-direction: column;
    height: 60px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: Arial;
  
    button{
      all: unset;
      width: 100px;
      height: 35px;
      margin: 10px;
      background-color: #0F4492;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      transition: 0.06s ease-in;
  
      &:hover{
        background-color: #fff;
        color: #0041C2;
      }
    }
  }
  button{
    all: unset;
    width: 100px;
    height: 35px;
    margin: 10px;
    align-self: flex-end;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #bfbfbf;
    transition: 0.06s ease-in;

    
  }

  >div{
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;

    .content{
      display: flex;
      flex-direction: column;
      padding: 20px 100px;    
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      width: auto;
  
      img{
        height: 150px;
        width: 150px;
        border-radius: 50%;
      }
  
      >span:nth-child(2){
        margin-top: 20px;
        font-weight: bold;
      }
  
      >span:not(:nth-child(2)){
        margin-top: 8px;
        font-size: 14px;
      }
  
    }

  }
}
.endpoint-btn {
  margin-top: 21%;
  margin-left: 45%;
  padding: 15px 20px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  background-color: transparent;
  transition: 0.2s ease-in-out;
 span {
   color: #fff;
   text-decoration: none;
 }
}


`;

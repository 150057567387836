import { ethers } from "ethers";
import { useContext } from "react";
import { AppContext } from "../faucet/App";

// Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function useConnectWallet(onConnect) {
    const { dispatch } = useContext(AppContext);
    async function connectWallet() {
        try {
            if (!window.ethereum) {
                // alert("Please Install Metamask or enable the extension to continue.");
                toast.error('Please Install Metamask or enable the extension to continue.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false;
            } else {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                if (signer && signer._isSigner) {
                    dispatch({
                        type: "SET_SIGNER",
                        payload: {
                            signer: signer,
                        }
                    });
                    const address = await signer.getAddress();
                    onConnect(address);
                }
            }
        } catch (err) {
            // console.log(err);
            // alert(err.message);
            toast.error(err.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    return [connectWallet];
}

export default useConnectWallet;
import Footer from "../components/Footer";
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { QueryLink } from "../components/QueryLinkNavigate";
// import useNavigateQuery from "hooks/useNavigateQuery";
import '../styles/Layout.css'
import useRequireRpcParam from "../hooks/useRequireRpcParam";
import { useParams } from 'react-router-dom'
import BuildBear2 from '../assets/BuildBear2.png'
import SearchIcon from "@material-ui/icons/Search";

const Layout = (props) => {
    useRequireRpcParam();
    let { nHash } = useParams();
    const [searchInput, setSearchInput] = useState("");
    var url;
    if (searchInput.length > 50) {
        url = 'tx';
    } else if (searchInput.length === 42) {
        url = 'address';
    } else {
        url = 'block';
    }
    return (
        <>
            <div className="rpc-navbar">
                <div className="logo">
                    <img src={BuildBear2} alt="logo" width="250px" style={{ marginTop: '-87px', marginLeft: '30px' }} />
                </div>
                <div></div>
            </div>
            <div className="data-container">
                <h1>BuildBear Testnet Explorer</h1>
                <form >
                    <div>
                        <input
                            className="datainput-field"
                            name="transaction"
                            label="transaction"
                            value={searchInput}
                            variant="filled"
                            placeholder="Enter Address / Tx Hash / Block"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                    <QueryLink className="explorer-search" to={`/${url}/${searchInput}`}>
                        <button className="datainput-submit" variant="contained" type="submit">
                            <SearchIcon style={{ fill: "#e8e8e8" }} />
                        </button>
                    </QueryLink>
                    {/* <QueryLink to="/">
                        <div className="rpc-btn data-page-rpc-btn">
                            <button>Edit RPC</button>
                        </div>
                    </QueryLink> */}
                </form>
                {nHash &&
                    <div style={{ marginTop: "20px", flexGrow: 1, display: "flex", justifyContent: "center" }}>
                        <QueryLink style={{ textDecoration: "none" }} to={`/transactions`}>
                            <button style={{ borderRadius: "25px", height: '50px' }}>
                                <h5 className="transaction-button" style={{ color: "white", padding: "0px 20px" }}>Show all Transactions </h5>
                            </button>
                        </QueryLink>
                    </div>
                }
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}

export default Layout;

import { formatCall } from "../format/call.js";

export async function printStaticCall(
  op,
  gasCost,
  params,
  dependencies
) {

  if (params.types && params.output) {
    return "CONSOLE_LOG" +
      " " +
      params.output.join(" ")
  } else {
    const str = await formatCall(
      params.to,
      params.input,
      params.ret,
      0,
      params.gas,
      dependencies
    );
    // console.log(str)
    return "STATICCALL" +
      " " +
      str +
      ` (cost: ${gasCost})` +
      ` (to: ${params.to})`
  }
}

// import { BigNumber } from "ethers";
import { arrayify, Interface } from "ethers/lib/utils";



import { compareBytecode } from "../utils.js";

import { formatParam } from "./param.js";
import { formatResult } from "./result.js";

export async function formatContract(
  code,
  value,
  salt,
  deployedAddress,
  dependencies
) {


  for (const artifact of dependencies.artifacts) {
    const iface = new Interface(artifact.abi);

    if (
      artifact.bytecode.length > 2 &&
      artifact.bytecode.length <= code.length &&
      compareBytecode(artifact.bytecode, code) > 0.5
    ) {
      // we found the artifact with matching bytecode
      try {
        const constructorParamsDecoded = iface._decodeParams(
          iface.deploy.inputs,
          "0x" + code.slice(artifact.bytecode.length)
        );
        const inputArgs = formatResult(
          constructorParamsDecoded,
          iface.deploy,
          { decimals: -1, isInput: true, shorten: false },
          dependencies
        );
        const extra = [];
        if (value.gt(0)) {
          extra.push(`value: ${formatParam(value, dependencies)}`);
        }
        if (salt !== null) {
          extra.push(
            `salt: ${formatParam(
              salt.gt(2 ** 32) ? salt.toHexString() : salt,
              dependencies
            )}`
          );
        }
        // console.log("This is the return value", `${artifact.contractName}.${
        //   "constructor"
        // }${extra.length !== 0 ? `{${extra.join(",")}}` : ""}(${inputArgs})${
        //   deployedAddress
        //     ? ` => (${formatParam(deployedAddress, dependencies)})`
        //     : ""
        // }`)
        // console.log("This is the deployed address", deployedAddress)
        return `${artifact.contractName}.${"constructor"
          }${extra.length !== 0 ? `{${extra.join(",")}}` : ""}(${inputArgs})${deployedAddress
            ? ` => (${formatParam(deployedAddress, dependencies)})`
            : ""
          }`;
      } catch { }
    }
  }

  return `${"UnknownContract"}(${"deployCodeSize="}${arrayify(code).length
    })`;
}

export const initialState = {
  isLoggedIn: (JSON.parse(localStorage.getItem("isLoggedIn")) || true),
  user: (JSON.parse(localStorage.getItem("user")) || null),
  isAdmin: (JSON.parse(localStorage.getItem("isAdmin")) || false),
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      localStorage.setItem("isLoggedIn", JSON.stringify(action.payload.isLoggedIn))
      localStorage.setItem("user", JSON.stringify(action.payload.user))
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        user: action.payload.user
      };
    }
    case "LOGOUT": {
      fetch(`https://backend.${process.env.REACT_APP_BASE_URL}/user/logout`, {
        method: "GET",
        credentials: "include",
      });
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isAdmin: false,
      };
    }
    case "ADMIN": {
      localStorage.setItem("isAdmin", JSON.stringify(action.payload.isAdmin));
      return {
        ...state,
        isAdmin: action.payload.isAdmin
      };
    }
    default:
      return state;
  }
};

import { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../home/App";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import { getAllContainers, adminDelete } from "../../api/home";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

// Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function createData(containerId, createDate, hash, port, userId, deleting = false) {
  return {
    containerId,
    createDate,
    hash,
    // port,
    userId,
    deleting,
  };
}

let dateMap = {};
function descendingComparator(a, b, orderBy) {
  let LHS, RHS;
  if (orderBy === "createDate") {
    LHS = new Date(dateMap[b.createDate]);
    RHS = new Date(dateMap[a.createDate]);
  } else {
    LHS = b[orderBy];
    RHS = a[orderBy];
  }
  if (LHS < RHS) {
    return -1;
  }
  if (LHS > RHS) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "containerId",
    label: "Container ID",
  },
  {
    id: "createDate",
    label: "Create Date",
  },
  {
    id: "hash",
    label: "Hash",
  },
  // {
  //   id: "port",
  //   label: "Port",
  // },
  {
    id: "userId",
    label: "UserName",
  },
  {
    id: "controls",
    label: "Controls",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const TableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Running Containers
      </Typography>
    </Toolbar>
  );
};

export default function AllContainerTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dataRows, setDataRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(true);
  const { dispatch } = useContext(AuthContext);
  const history = useNavigate();

  const rowsRef = useRef();

  async function handleDelete(hash) {
    if (window.confirm("Are you sure you want to delete this node?")) {
      // console.log("Deleting", hash);
      try {
        let newContainers = rowsRef.current.map((container) => {
          if (container.nodeId !== hash) {
            return container
          } else {
            return {
              ...container,
              deleting: true,
            }
          }
        });
        rowsRef.current = newContainers;
        setDataRows(newContainers);

        const res = await adminDelete(hash);
        if (res.status === 200) {
          // console.log(`Deleted ${hash} Successfully`);
          newContainers = rowsRef.current.filter((container) => {
            return container.nodeId !== hash;
          });
          rowsRef.current = newContainers;
          setDataRows(newContainers);
          // alert("Deleted successfully.");
          toast.success('Deleted successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          // alert("Couldn't delete");
          toast.error("Couldn't delete", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (err) {
        // alert("Something went wrong.");
        toast.error('Something went wrong.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        let newContainers = rowsRef.current.map((container) => {
          if (container.nodeId !== hash) {
            return container
          } else {
            return {
              ...container,
              deleting: false,
            }
          }
        });
        setDataRows(newContainers);
      } finally {
      }
    } else {
    }
  }

  useEffect(() => {
    async function fetchContainers() {
      let rows = [];
      const response = await getAllContainers();
      // console.log("This is the response", response)
      if (response.status === 401 || response.status === 403) {
        dispatch({
          type: "LOGOUT",
        });
        history.replace("/login");
      } else {
        response.data.forEach(async (container) => {

          let displayDate;
          if (!container?.dateOfCreation && container.status === "Deleted due to inactivity") {
            displayDate = "Inactive-Deleted"
          } else if (!container?.dateOfCreation) {
            displayDate = "undefined"
          } else {
            const date = new Date(container.dateOfCreation);
            // displayDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear() % 100
            //   }\n${("0" + date.getHours()).slice(-2)}:${(
            //     "0" + date.getMinutes()
            //   ).slice(-2)}`;
            displayDate = date.toLocaleString()

            // console.log("This is the date", displayDate);
            // console.log("This", date)
            dateMap[displayDate] = date;
          }

          // console.log("Doc", container);

          let userName = null;
          try {
            userName = (await axios.get(`https://api.github.com/user/${container.userId}`))?.data?.login;
          } catch (err) {
            // console.log("Error when fetching name from github", err);
            userName = container.userId;
          }

          rows = [
            ...rows,
            createData(
              container.nodeId,
              displayDate,
              container.nodeId,
              container.portNumber,
              userName,
              false,
            ),
          ];
          // console.log("This is the ows", rows);
          setDataRows(rows);
          rowsRef.current = rows;
        });
      }
      setLoading(false);
      setDataRows(rows);
      rowsRef.current = rows;
    }
    if (mounted) {
      fetchContainers();
    }

    return () => {
      setMounted(false);
    };
  }, [dispatch, history, mounted]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty dataRows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataRows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={dataRows.length}
            />
            <TableBody>
              {loading && (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}

              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 dataRows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(dataRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row.containerId}>
                      <TableCell component="th" id={labelId} scope="row" style={{ maxWidth: '20ch', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {row.containerId}
                      </TableCell>
                      <TableCell align="left">{row.dateOfCreation}</TableCell>
                      <TableCell align="left">{row.nodeId}</TableCell>
                      {/* <TableCell align="left">{row.port}</TableCell> */}
                      <TableCell align="left">{row.userId}</TableCell>
                      <TableCell align="left">
                        <LoadingButton
                          color="error"
                          variant="outlined"
                          size="small"
                          loading={row.deleting}
                          onClick={() => {
                            handleDelete(row.hash);
                          }}
                        >
                          Delete
                        </LoadingButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
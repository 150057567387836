import React, { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";

function ReadContract({ contractInstance, readFunctions, setReadFunctions, readLoadedOnce, setReadLoadedOnce, abi, pageName }) {
  // const [readFunctions, setReadFunctions] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [, forceUpdateState] = useState();
  // eslint-disable-next-line 
  const forceUpdate = useCallback(() => forceUpdateState({}, []));
  useEffect(() => {
    if (!readLoadedOnce) {
      //console.log(abi);
      generateReadFunctionList();
    }
    // eslint-disable-next-line
  }, [readLoadedOnce, abi, contractInstance]);

  async function callReadFunctionBase(functionSignature, ...args) {
    try {
      // console.log(functionSignature,contractInstance.functions[functionSignature])
      let result = await contractInstance.functions[functionSignature](...args);
      // console.log(contractInstance)
      // console.log("1st result", result)
      result = result.map((elem) => {
        if (ethers.BigNumber.isBigNumber(elem)) {
          elem = elem.toString();
        }
        return elem;
      });
      // console.log("after mapping", result)
      // Result is an array of outputs corresponding to the output list in the functionData
      return result;
    } catch (err) {
      throw err;
      //   return ["An Error Occurred"];
    } finally {

    }
  }

  async function generateReadFunctionList() {
    try {

      // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      // console.log("Generating ReadFunction List");

      setErrMsg("");
      let readFunctions = [];
      for (const key of Object.keys(contractInstance.interface.functions)) {
        if (contractInstance.interface.functions[key].constant) {
          const functionData = {
            inputs: contractInstance.interface.functions[key].inputs.map(
              (param) => {
                return {
                  name: param.name,
                  type: param.type,
                  value: "", // The value of the input field in the frontend
                };
              }
            ),
            outputs: contractInstance.interface.functions[key].outputs.map(
              (param) => {
                return {
                  name: param.name,
                  type: param.type,
                  value: "", // Value to be shown as result in frontend
                };
              }
            ),
          };
          if (
            contractInstance.interface.functions[key].stateMutability ===
            "payable"
          ) {
            functionData.inputs.push({
              name: "Payable Amount",
              type: "eth",
              value: "", // The value of the input field in the frontend
            });
          }
          readFunctions.push({
            functionName: contractInstance.interface.functions[key].name,
            functionSignature: key,
            functionData: functionData,
            payable:
              contractInstance.interface.functions[key].stateMutability ===
              "payable",
            loading: false,
            err: null
          });

          // Populate the ReadFunction Values for those with no parameters
          // if (contractInstance.interface.functions[key].inputs.length === 0) {
          //   let result = [];
          //   try {
          //     result = await callReadFunctionBase(
          //       contractInstance.interface.functions[key].name
          //     );
          //     console.log("1>>", result)
          //     // The function in current consideration is the last one just pushed
          //     const index = readFunctions.length - 1;

          //     readFunctions[index].functionData.outputs = readFunctions[
          //       index
          //     ].functionData.outputs.map((output, index) => {
          //       console.log("output", output);
          //       output.value = result[index];
          //       return output;
          //     });
          //   } catch (err) {
          //     // console.log(err);
          //   }
          // }
        }
      }
      setReadFunctions(readFunctions);
      if (!readFunctions ||
        (Array.isArray(readFunctions) && readFunctions.length === 0)
      ) {
        setErrMsg("No Read Functions");
      }
      setReadLoadedOnce(true);
    } catch (err) {
      // console.log(err);
      setErrMsg("Unable to decode contract, Possibly wrong ABI?");
      return false;
    }
  }

  useEffect(() => {
    readFunctionNoParam()
    // eslint-disable-next-line
  }, []);

  async function readFunctionNoParam() {
    try {

      // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      // console.log("Generating ReadFunction List");

      setErrMsg("");
      let readFunctions = [];
      for (const key of Object.keys(contractInstance.interface.functions)) {
        setLoading(true)
        if (contractInstance.interface.functions[key].constant) {
          const functionData = {
            inputs: contractInstance.interface.functions[key].inputs.map(
              (param) => {
                return {
                  name: param.name,
                  type: param.type,
                  value: "", // The value of the input field in the frontend
                };
              }
            ),
            outputs: contractInstance.interface.functions[key].outputs.map(
              (param) => {
                return {
                  name: param.name,
                  type: param.type,
                  value: "", // Value to be shown as result in frontend
                };
              }
            ),
          };

          if (
            contractInstance.interface.functions[key].stateMutability ===
            "payable"
          ) {
            functionData.inputs.push({
              name: "Payable Amount",
              type: "eth",
              value: "", // The value of the input field in the frontend
            });
          }
          readFunctions.push({
            functionName: contractInstance.interface.functions[key].name,
            functionSignature: key,
            functionData: functionData,
            payable:
              contractInstance.interface.functions[key].stateMutability ===
              "payable",
            loading: false,
            err: null
          });
          // Populate the ReadFunction Values for those with no parameters
          if (contractInstance.interface.functions[key].inputs.length === 0) {
            let result = [];
            try {
              result = await callReadFunctionBase(
                contractInstance.interface.functions[key].name
              );
              // console.log("1>>", result)
              // The function in current consideration is the last one just pushed
              const index = readFunctions.length - 1;

              readFunctions[index].functionData.outputs = readFunctions[
                index
              ].functionData.outputs.map((output, index) => {
                // console.log("output", output);
                output.value = result[index];
                return output;
              });
            } catch (err) {
              // console.log(err);
            }
          }
        }
      }
      setReadFunctions(readFunctions);
      if (!readFunctions ||
        (Array.isArray(readFunctions) && readFunctions.length === 0)
      ) {
        setErrMsg("No Read Functions");
      }
      setReadLoadedOnce(true);
    } catch (err) {
      // console.log(err);
      setErrMsg("Unable to decode contract, Possibly wrong ABI?");
      return false;
    } finally {
      setLoading(false)
    }
  }

  async function queryReadFunction(functionSignature) {
    let index = -1;
    let readFunctionsCopy = readFunctions;
    try {
      index = readFunctionsCopy.findIndex((elem) => {
        return elem.functionSignature === functionSignature
      });

      readFunctionsCopy[index]['loading'] = true;
      readFunctionsCopy[index]['err'] = null;
      readFunctionsCopy[index].functionData.outputs =
        readFunctionsCopy[index].functionData.outputs.map(elem => {
          elem.value = "";
          return elem;
        })
      setReadFunctions(readFunctionsCopy);
      forceUpdate();
      let result = await callReadFunctionBase(
        functionSignature,
        ...readFunctionsCopy[index].functionData.inputs.map((input) => {
          return input.value;
        })
      );
      // console.log("result", typeof (result[0]), result[0]);
      readFunctionsCopy[index].functionData.outputs =
        readFunctionsCopy[index].functionData.outputs.map((elem, index) => {
          const retrieveData = (res) => {
            return Array.isArray(res) ? res.map((r) => retrieveData(r)) : res.toString();
          }
          elem.value = retrieveData(result[index]);
          return elem;
        });

    } catch (err) {
      // console.log(err.message);
      if (err.message.includes('could not detect network')) {
        err.message = "Could not detect network. Please check your internet connection or the rpc url (event='noNetwork')";
      }
      if (err.data && err.data.message) {
        err.message = `${err.message} | ${err.data.message}`;
      }
      readFunctionsCopy[index]['err'] = err;
    } finally {
      readFunctionsCopy[index]['loading'] = false;
      setReadFunctions(readFunctionsCopy);
      forceUpdate();
    }
  }

  function onInputChange(e, functionSignature, inputName, idx) {
    let readFunctionsCopy = readFunctions;
    let index = readFunctionsCopy.findIndex((elem) => {
      return elem.functionSignature === functionSignature
    });
    readFunctionsCopy[index].functionData.inputs =
      readFunctionsCopy[index].functionData.inputs.map((input, inputId) => {
        if (input.name + inputId === inputName + idx) {
          input.value = e.target.value;
          return input;
        } else {
          return input;
        }
      });
    setReadFunctions(readFunctionsCopy);
    forceUpdate();
  }

  return (
    <>
      <div className="abi-container">
        <div className="abi-wrapper">
          <div>
            <div>
              <h4>{pageName ?? "Read Contract"}</h4>
            </div>
          </div>
          <div className="readfunction-wrapper" style={{ fontSize: '16px' }}>

            {errMsg ? errMsg : ""}
            {/* {console.log(">>Readfunction", readFunctions)}. */}
            {readFunctions.map((el, idx) => {
              // console.log(el);
              return (
                <div key={idx + 1}>
                  <div className="readfunctionname">
                    <div className="readfunctionname-header">
                      <div className="left-column">
                        <div className="left-col-wrapper">
                          <div className="counter"> {idx + 1}.</div>
                          <div>{el.functionName}</div>
                        </div>
                      </div>
                      {/* {console.log(el)} */}
                      {/* <div className='right-column'>
                            <i className='fas fa-angle-down' style={{ fontSize: '24px' }}></i>
                        </div> */}
                    </div>
                    <div>
                      {el.functionData.inputs.length ? (
                        <div className="functionResponseHeader">
                          methodParams
                        </div>
                      ) : (
                        ""
                      )}
                      {!el.functionData.inputs.length
                        ? ""
                        : el.functionData.inputs && el.functionData.inputs.map((input, idx) => {
                          return (
                            <div className="input-readfunction" key={idx}>
                              <div>
                                {input.name} <i>({input.type})</i>
                              </div>
                              <input
                                type="text"
                                value={input.value}
                                style={{ width: "100%", color: "black" }}
                                onChange={(e) => {
                                  onInputChange(e, el.functionSignature, input.name, idx);
                                }}
                              />
                            </div>
                          );
                        })}
                      <button
                        className="functionQueryButton"
                        disabled={el.loading}
                        // onClick={async()=> await callReadFunction(el.functionName)}
                        onClick={async () =>
                          await queryReadFunction(el.functionSignature)
                        }
                      >
                        {el.loading ? "Fetching..." : "Query"}
                      </button>
                    </div>
                    <div>
                      <div className="functionResponseHeader">
                        methodResponses
                      </div>
                      {el.functionData.outputs.length === 0
                        ? ""
                        : el.functionData.outputs.map((output, idx) => {
                          return (
                            <div className="input-readfunction" key={idx}>
                              <div>
                                {"->> "}
                                {output.name} <i>({output.type}): </i>{" "}
                                {/* <br/> */}
                                {/* {console.log(output)} */}
                                {
                                  !el.functionData.inputs.length ? loading ? "Loading..." : "" : ""
                                }
                                {/* // {loading ? "Loading..." : ""} */}
                                {
                                  (() => {
                                    const outputFunc = (input) => {
                                      // console.log(input);
                                      return Array.isArray(input) ? "[" + input.map((i) => outputFunc(i)).join(", ") + "]" : input.toString();
                                    }
                                    return outputFunc(output.value);
                                  })()
                                }
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {el.err ? (
                      <div>
                        <div className="functionResponseHeader">
                          Error during function call:
                        </div>
                        {/* {"->>  "}{el.err.message} */}
                        {el.err.address}
                      </div>
                    ) : ("")}
                    {/* {el.functionData.inputs == '' ? <div></div> : console.log(el.functionData.inputs, 'el')} */}
                    {/* {console.log(el.functionData.outputs, 'el')} */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadContract;

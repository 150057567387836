import { formatContract } from "../format/contract.js";

export async function printCreate2(
  op,
  gasCost,
  params,
  dependencies
) {

  const str = await formatContract(
    params.code,
    params.value,
    params.salt,
    params.deployedAddress,
    dependencies
  );
  return "CREATE2" +
    " " +
    str +
    ` (cost: ${gasCost})`
}

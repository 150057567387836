import {
  formatUnits,
  // Fragment,
  // FunctionFragment,
  // Result,
} from "ethers/lib/utils";




import { formatParam } from "./param.js";


export function formatResult(
  result,
  fragment,
  { decimals, isInput, shorten },
  dependencies
) {
  decimals = decimals ? decimals : -1;
  isInput = isInput ? isInput : true;
  shorten = shorten ? shorten : false;
  const stringifiedArgs = [];
  const params = isInput
    ? fragment.inputs
    : (fragment ? fragment.outputs : undefined);
  if (!params) {
    return "";
  }
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    const name = param.name ? param.name : `arg_${i}`;
    stringifiedArgs.push([
      name,
      decimals !== -1 && i === 2 // display formatted value for erc20 transfer events
        ? formatUnits(result[2], decimals)
        : formatParam(result[i], dependencies),
    ]);
  }
  return `${stringifiedArgs
    .map(
      (entry) =>
        `${
          stringifiedArgs.length > 1 || !shorten ? `${entry[0]}=` : ""
        }${entry[1]}`
    )
    .join(", ")}`;
}

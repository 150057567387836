import { ethers } from "ethers";
import { useContext } from "react";
import { AppContext } from "../../../eth-scan/App";
import { useEffect, useState } from "react";
import '../../../eth-scan/style.css'
import { Spinner } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import Account from "./Account";
import Contract from "./Contract";
import useRequireRpcParam from "../../../hooks/useRequireRpcParam";


function Address() {
    const [errMsg, setErrMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isContract, setIsContract] = useState(false);

    const addressHash = useParams().addressHash;
    const { state } = useContext(AppContext);
    const rpcUrl = state.rpcUrl;
    const provider = new ethers.providers.JsonRpcProvider(state.rpcUrl);
    useRequireRpcParam();

    async function getAddressTypeAndRoute() {

        try {
            setLoading(true);
            const code = await provider.getCode(addressHash);
            setIsContract(code > 0);

        } catch (err) {
            if (err.message.includes("could not detect network")) {
                setErrMsg("Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.")
            }
            else if (err.message.includes("bad address checksum")) {
                setErrMsg("Sorry, Address not found.")
            } else {
                setErrMsg("Sorry we encountered an error. Please try again later.");
            }
            // console.log(err.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAddressTypeAndRoute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useParams().addressHash]);

    // console.log(rpcUrl)

    if (errMsg) {
        return (
            <div className="containerbox">
                <div className="errContainer">
                    <div className="errContainer">
                        <i className='fas fa-exclamation-triangle' style={{ color: 'red', fontSize: '56px', marginTop: '40px' }}></i>
                        <h2 style={{ color: '#403F3F', fontSize: '30px', fontFamily: 'Poppins', marginTop: '20px' }}>Oh Snap!</h2>
                        <div className="errMsg">
                            {errMsg}
                        </div>
                        <button><a target="_blank" rel="noreferrer" href="https://mail.google.com/mail/?view=cm&fs=1&to=team@buildbear.io&su=Reporting Bug&body=Please-report-your-issue-and-provide-the-following-tx-hash-or-address-below">Report</a></button>

                    </div>
                </div>
            </div>
        )
    } else if (loading) {
        return <Spinner animation="grow" style={{ width: '100px', height: '100px', marginLeft: '46vw', marginTop: '23vw' }} />
    }
    else if (isContract) {
        return <Contract />;
    } else {
        return <Account />;
    }

}

export default Address;
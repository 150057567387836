export const initialState = {
  rpcUrl: JSON.parse(localStorage.getItem("rpcUrl")) || "",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_RPC": {
      localStorage.setItem("rpcUrl", JSON.stringify(action.payload.rpcUrl));
      return {
        ...state,
        rpcUrl: action.payload.rpcUrl,
      };
    }
    //   case "LOGOUT": {
    //     localStorage.clear()
    //     return {
    //       ...state,
    //       isLoggedIn: false,
    //       user: null
    //     };
    //   }
    default:
      return state;
  }
};

import { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from "../home/App";
import "../styles/SideNav.css";

function SideNav() {
    const { state, dispatch } = useContext(AuthContext);

  if (!state.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };
  return (
    <div className="dash-sidebar">
      <ul>
        <div>
          {/* <Link to="/">
                  <li><b>Endpoints</b></li>
                </Link> */}
        </div>
        <div className="sidebar-list-bottom">
          <Link className="nav-btn" to="/create">
            <li>
              <b>Create</b>
            </li>
          </Link>
          {state.isAdmin && (
            <Link className="nav-btn" to="/admin">
              <li>
                <b>Admin</b>
              </li>
            </Link>
          )}
          <li onClick={() => handleLogout()}>
            <b>Sign Out</b>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default SideNav;
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { adminAllDetails } from "../../api/home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function AdminDetails() {
  const [response, setResponse] = useState();
  const [totalResponse, setTotalResponse] = useState();
  const [totalTransactions, setTotalTransactions] = useState();
  const [totalNodes, setTotalNodes] = useState();
  const [totalLiveNodes, setTotalLoveNodes] = useState();
  const [totalCallsMade, setTotalCallsMade] = useState();
  const [fetchResponse, setFetchResponse] = useState(false);

  useEffect(() => {
    const getAdminDetails = async () => {
      try {
        const response = await adminAllDetails();
        // console.log('response', response)
        setResponse(response.data.userInfo);
        setTotalResponse(response);
        // console.log(response.data.userInfo);
        if (response !== undefined) {
          setFetchResponse(true)
        }
        // setTotalLiveNodes(response.data.totalLiveNodes);
        setTotalTransactions(totalResponse.data.totalTransactions);
        setTotalNodes(totalResponse.data.totalNodes);
        setTotalLoveNodes(totalResponse.data.totalLiveNodes);
        setTotalCallsMade(totalResponse.data.totalMethodCalls);
      } catch (err) {
        // console.log('err', err);
      }
    };
    getAdminDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponse]);
  return (
    <div style={{ marginTop: "100px", marginBottom: "50px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F4F2",
            padding: "10px 20px",
            borderRadius: "10px",
            minWidth: "200px",
          }}
        >
          <div>Total Transactions</div>
          <p style={{ fontSize: "23px" }}>{totalTransactions}</p>
        </div>
        <div
          style={{
            backgroundColor: "#F4F4F2",
            padding: "10px 20px",
            borderRadius: "10px",
            minWidth: "200px",
          }}
        >
          <div>Total Nodes</div>
          <p style={{ fontSize: "23px" }}>{totalNodes}</p>
        </div>
        <div
          style={{
            backgroundColor: "#F4F4F2",
            padding: "10px 20px",
            borderRadius: "10px",
            minWidth: "200px",
          }}
        >
          <div>Total Live Nodes</div>
          <p style={{ fontSize: "23px" }}>{totalLiveNodes}</p>
        </div>
        <div
          style={{
            backgroundColor: "#F4F4F2",
            padding: "10px 20px",
            borderRadius: "10px",
            minWidth: "200px",
          }}
        >
          <div>Total no. of calls made</div>
          <p style={{ fontSize: "23px" }}>{totalCallsMade}</p>
        </div>
      </div>
      {response !== undefined &&
        response.map((e) => {
          return (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panelia-content"
                  id="paneli-header"
                >
                  <Typography>{e.user}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div>
                      <div>
                        <b>User ID:</b>
                        <span style={{ marginLeft: "10px" }}>{e.userId}</span>
                      </div>
                      <div>
                        <b> Average Node Live Period:</b>
                        <span style={{ marginLeft: "10px" }}>
                          {e.avgNodeLiveTime}
                        </span>
                      </div>
                      <div>
                        <b>Average Transactions:</b>{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {e.avgTransactions}
                        </span>
                      </div>
                      <div>
                        <b> Total no. of nodes:</b>
                        <span style={{ marginLeft: "10px" }}>{e.nodes}</span>
                      </div>
                      <div>
                        <b> Live Nodes: </b>
                        <span style={{ marginLeft: "10px" }}>
                          {e.liveNodes}
                        </span>
                      </div>
                      <div>
                        <b> No. of calls made: </b>
                        <span style={{ marginLeft: "10px" }}>
                          {e.methodCalls}
                        </span>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
}

export default AdminDetails;

import Config from "../components/Config";
import Nav from "../components/Nav";
// import Navbar from "../components/Navbar";

import "../styles/CreateNode.css";
function CreateNode() {
    return(
        <>
            {/* <Navbar/> */}
            <Nav />
            <Config/>
            <br/>
            <br/>
        </>
    )
}

export default CreateNode;
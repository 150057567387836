async function publicData() {
  const url = `https://backend.${process.env.REACT_APP_BASE_URL}/public`;
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    const data = await response.json();
    return { data };
  } catch (err) {
    // console.log(err);
  }
}
export { publicData };

import { formatLog } from "../format/log.js";

export async function printLog3(
  op,
  gasCost,
  params,
  address,
  dependencies
) {

  const str = await formatLog(
    {
      data: params.data,
      topics: [params.topic0, params.topic1, params.topic2]
    },
    address,
    dependencies
  );
  return "EVENT" +
    " " +
    str +
    ` (cost: ${gasCost})`
}

import { formatCall } from "../format/call.js";

export async function printDelegateCall(
  op,
  gasCost,
  params,
  dependencies
) {

  const str = await formatCall(
    params.to,
    params.input,
    params.ret,
    0,
    params.gas,
    dependencies
  );
  return "DELEGATECALL" +
    " " +
    str +
    ` (cost: ${gasCost})` +
    ` (to: ${params.to})`
}

import { BigNumber, ethers } from "ethers";
import { Interface } from "ethers/lib/utils";




import { compareBytecode, getfromNameTags } from "../utils.js";

import { getAbiForTracer } from "../../../api/explorer.js";
import { formatParam } from "./param.js";
import { formatResult } from "./result.js";


export async function formatCall(
  to,
  input,
  ret,
  value,
  gas,
  dependencies
) {
  const toBytecode = await dependencies.provider.send("eth_getCode", [to]);

  let contractName;
  let result;
  let result2;
  let functionFragment;
  for (const arti of dependencies.artifacts) {
    const _artifact = arti


    const iface = new Interface(_artifact.abi);

    // try to find the contract name
    if (
      compareBytecode(_artifact.deployedBytecode, toBytecode) > 0.5 ||
      (to === ethers.constants.AddressZero && toBytecode.length <= 2)
    ) {
      // if bytecode of "to" is the same as the deployed bytecode
      // we can use the artifact name
      contractName = _artifact.contractName;
    }

    // try to parse the arguments
    try {
      // if this doesnt throw, we likely found an Artifact that recognizes the input
      const signature = input.slice(0, 10);
      result = iface.decodeFunctionData(signature, input);
      try {
        result2 = iface.decodeFunctionResult(signature, ret);
      } catch { }

      functionFragment = iface.getFunction(signature);
    } catch { }

    // if we got both the contract name and arguments parsed so far, we can stop
    if (contractName && result) {
      break;
    }
  }

  if (!(result || functionFragment)) {
    const bArtifact = await getAbiForTracer(to, dependencies.nHash)
    // console.log("gotabi backend", bArtifact)
    try {
      const iface = new Interface(bArtifact);
      const signature2 = input.slice(0, 10);
      result = iface.decodeFunctionData(signature2, input);
      try {
        result2 = iface.decodeFunctionResult(signature2, ret);
      } catch {
        // ("abi error") 
      }
      functionFragment = iface.getFunction(signature2);
    } catch (err) {
      // console.log("abi error2", err) 
    }
  }

  if (result && functionFragment) {
    const inputArgs = formatResult(
      result,
      functionFragment,
      { decimals: -1, isInput: true, shorten: false },
      dependencies
    );
    const outputArgs = result2
      ? formatResult(
        result2,
        functionFragment,
        { decimals: -1, isInput: false, shorten: true },
        dependencies
      )
      : "";

    const extra = [];
    if ((value = BigNumber.from(value)).gt(0)) {
      extra.push(`value: ${formatParam(value, dependencies)}`);
    }
    if ((gas = BigNumber.from(gas)).gt(0) && dependencies.tracerEnv.gasCost) {
      extra.push(`gas: ${formatParam(gas, dependencies)}`);
    }
    const nameTag = getfromNameTags(to, dependencies);
    return `${nameTag
      ? nameTag
      : contractName
        ? contractName
        : `<${"UnknownContract"} ${formatParam(
          to,
          dependencies
        )}>`
      }.${functionFragment.name}${extra.length !== 0 ? `{${extra.join(",")}}` : ""
      }(${inputArgs})${outputArgs ? ` => (${outputArgs})` : ""}`;
  }

  // TODO add flag to hide unrecognized stuff
  if (toBytecode.length > 2 && contractName) {
    return `${contractName}.<${"UnknownFunction"
      }>(${"input="}${input}, ${"ret="}${ret})`;
  } else {
    return `${"UnknownContractAndFunction"}(${"to="
      }${to}, ${"input="}${input}, ${"ret="}${ret})`;
  }
}

import { Interface } from "ethers/lib/utils";
// import { Artifact } from "hardhat/types";
import { getAbiForTracer } from "../../../api/explorer.js";


import { compareBytecode, getfromNameTags } from "../utils.js";

import { formatParam } from "./param.js";
import { formatResult } from "./result.js";


export async function formatLog(
  log,
  currentAddress,
  dependencies
) {
  const nameTag = currentAddress
    ? getfromNameTags(currentAddress, dependencies)
    : undefined;

  const code =
    !nameTag && currentAddress
      ? ((await dependencies.provider.send("eth_getCode", [
        currentAddress,
        "latest",
      ])))
      : undefined;

  let str;
  let contractName = nameTag;
  for (const arti of dependencies.artifacts) {
    const artifact = arti
    const iface = new Interface(artifact.abi);

    // try to find the contract name
    if (compareBytecode(artifact.deployedBytecode, code ? code :  "0x") > 0.5) {
      contractName = artifact.contractName;
    }

    // try to parse the arguments
    try {
      const parsed = iface.parseLog(log);
      const decimals = -1;

      str = `${parsed.name}(${formatResult(
        parsed.args,
        parsed.eventFragment,
        { decimals, isInput: true, shorten: false },
        dependencies
      )})`;
    } catch { }

    // if we got both the contract name and arguments parsed so far, we can stop
    if (contractName && str) {
      return contractName + "." + str;
    }
  }

  const bArtifact = await getAbiForTracer(currentAddress, dependencies.nHash)
  const iface = new Interface(bArtifact);
  try {
    const parsed = iface.parseLog(log);
    const decimals = -1;

    str = `${parsed.name}(${formatResult(
      parsed.args,
      parsed.eventFragment,
      { decimals, isInput: true, shorten: false },
      dependencies
    )})`;
  } catch { }
  if (str) {
    return "." + str;
  }


  return (
    `<${"UnknownContract"} ${formatParam(
      currentAddress,
      dependencies
    )}>.` +
    (str ? str :
      `${"UnknownEvent"}(${formatParam(
        log.data,
        dependencies
      )}, ${formatParam(log.topics, dependencies)})`)
  );
}

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "../styles/RPCInput.css";
import { ethers } from "ethers";
import { useContext } from "react";
import { AppContext } from "../eth-scan/App";
import Footer from "../components/Footer";
import { getDefaultNetworks } from "../api/explorer";
import { useSearchParams, useNavigate } from "react-router-dom";
import BuildBear2 from '../assets/BuildBear2.png'

const RPCInput = (props) => {
  const networks = getDefaultNetworks();
  let [searchParams] = useSearchParams();
  // console.log(networks); 
  // const networks = [
  //   { name: "Mainnet", rpc: "https://eth-mainnet.alchemyapi.io/v2/9QgTwF5fdKsv_H7TKTvIrWkzctYKmEp1" },
  //   { name: "Polygon Mainnet", rpc: "https://polygon-mainnet.g.alchemy.com/v2/0NgDEIx1DVeP_LcRjOO4BD4dyUmHeE2E" },
  //   { name: "Polygon Mumbai", rpc: "https://polygon-mumbai.g.alchemy.com/v2/VBg5j34wNmSurLLcJhVrcngnyh3tgf21" },
  //   { name: "Custom", rpc: "" }
  // ]

  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [rpcUrl, setRpcUrl] = useState(state.rpcUrl);
  const [errMsg, setErrMsg] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  // const [network, setNetwork] = useState(networks[3]);
  const [network, setNetwork] = useState({ name: "Custom", rpc: "" });

  useEffect(() => {
    // const index = networks.findIndex((networkObj) => {
    //   return networkObj.rpc === state.rpcUrl;
    // });
    // if (index != -1) {
    //   setNetwork(networks[index]);
    // }
  }, [])

  useEffect(() => {
    if (network.name !== "Custom") {
      setRpcUrl(network.rpc);
    }
    else {
      setRpcUrl(rpcUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);


  async function onRpcSubmit(e) {
    e.preventDefault();
    setErrMsg("");
    setSubmitting(true);
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      // console.log("Provider", provider);
      let queryParam = "";
      const index = networks.findIndex((networkObj) => {
        return networkObj.rpc === rpcUrl;
      });
      // console.log("Index", index);
      if (index !== -1) {
        // we are using predefined network
        setNetwork(networks[index]);
        queryParam = `chainId=${encodeURIComponent(networks[index].chainId)}`;
      } else {
        setNetwork(networks[3]);
        queryParam = `rpc=${encodeURIComponent(rpcUrl)}`;
      }

      // Verifying if rpcUrl is Valid
      const providerNetwork = await provider.getNetwork();
      // console.log("Network", providerNetwork);
      // console.log("Network object", network);
      localStorage.setItem("networkType", network.name);
      dispatch({
        type: "SET_RPC",
        payload: { rpcUrl: rpcUrl },
      });
      // console.log(queryParam);
      if (searchParams.has("returnPath")) {
        const path = decodeURIComponent(searchParams.get("returnPath"));
        navigate(`${path}?${queryParam}`)
      } else {
        navigate(`/home?${queryParam}`);
      }
    } catch (err) {
      // console.log(err);
      setErrMsg("Couldn't connect to the network. Please recheck the RPC URL.");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <div className='rpc-navbar'>
        <div className="logo">
          <img src={BuildBear2} alt="logo" width="250px" style={{ marginTop: '-87px', marginLeft: '30px' }} />
        </div>

      </div>
      <div className="rpcinput-container">

        <form onSubmit={onRpcSubmit}>
          <h2>
            BuildBear Testnet Explorer
          </h2>
          <div className="input-submit-wrapper">
            {network.name === 'Custom' ? <>
              <input
                className="rpcinput-field"
                type="text"
                value={rpcUrl}
                onChange={(e) => { e.preventDefault(); setNetwork(networks[3]); setRpcUrl(e.target.value) }}
                placeholder="Enter RPC URL"
                aria-label="Enter RPC URL"
              />
              {!submitting ? (
                <input
                  className="rpcinput-submit"
                  type="submit"
                  value="Submit"
                ></input>
              ) : (
                <Spinner animation="border" className="spinner" />
              )}
            </> : ''}

          </div>
          {/* <div>OR</div> */}
          {/* <div class="dropdown" style={{ backgroundColor: '#495464', borderRadius: '8px' }}>
            <button class="dropbtn" style={{ backgroundColor: '#495464', borderRadius: '8px' }}>{network.name}</button>
            <i className="fa fa-caret-down" style={{ color: '#fff', paddingRight: '8px' }} aria-hidden="true"></i>
            <div class="dropdown-content">
              {networks.map(networkObj => (<>
                {

                  networkObj.name !== network.name ?
                    <a href="#" onClick={() => setNetwork(networkObj)}>{networkObj.name}</a> :
                    null
                }
              </>))}
            </div>
          </div> */}
          <div className="errMsg">{errMsg ? errMsg : <br />}</div>
        </form>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default RPCInput;

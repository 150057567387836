import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import "../../eth-scan/style.css";
import { Spinner } from "react-bootstrap";
import { Tooltip } from "@mui/material";

import { useParams } from "react-router-dom";
import { QueryLink } from "../../components/QueryLinkNavigate";
// import useNavigateQuery from "../../hooks/useNavigateQuery";
import useTransaction from "../../hooks/useTransaction";
import { useContext } from "react";
import { AppContext } from "../../eth-scan/App";
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import red from "@material-ui/core/colors/red";
import useRequireRpcParam from "../../hooks/useRequireRpcParam";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { printRevertError } from "../../components/ParseTrace/opcodes/revert";

import { getBlockData } from "../../api/publicExplorer";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ErrorIcon from "@mui/icons-material/Error";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import Button from "@mui/material/Button";

import { ReactComponent as EthLogo } from "../../assets/eth.svg";
import { ReactComponent as BbToken } from "../../assets/bbtoken.svg";
import BbLogo from "../../assets/bblogo.png";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from "@mui/material/Tooltip";

const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: 'rgba(255,255,255,0.87) !important',
      fontSize: 13,
      minWidth: "345px"
    },
  
  }));

const Transaction = ({ txDetails }) => {
    const { state } = useContext(AppContext);

    // const rpcUrl = state.rpcUrl;
    useRequireRpcParam();
    //states
    // const [rpcVisible, setRpcVisible] = useState(true);
    const [loading, setLoading] = useState(false); // this is the master loader
    const [blockLoading, setBlockLoading] = useState(true); // this is to display skeleton loader while fetching extra block data
    const [errMsg, setErrMsg] = useState(null);
    const [txFailMsg, setTxFailMsg] = useState(null);
    // const [isOpen, setIsOpen] = useState(false);

    const [info, setInfo] = useState("");
    const [to, setTo] = useState("");
    const [hash, setHash] = useState("");
    const [confirm, setConfirm] = useState("");
    const [block, setBlock] = useState("");
    const [nonce, setNonce] = useState("");
    const [data, setData] = useState("");
    const [gasPrice, setGasPrice] = useState("");
    const [value, setValue] = useState("");
    const [timeStamp, setTimeStamp] = useState("");
    const [gasused, setGasused] = useState("");
    const [txCost, setTxCost] = useState("");
    const [txType, setTxType] = useState();
    const [maxfeepergas, setMaxfeepergas] = useState();
    const [maxpriorgas, setMaxpriorgas] = useState();
    const [, setBlockTransfer] = useState();
    const [tokenTransfer, setTokenTransfer] = useState(0);
    const [isContract, setIsContract] = useState(false);
    const [isSnapshotTx, setIsSnapshotTx] = useState(false);
    const [contract, setContract] = useState();

    const bbToken = "0xC014BA5EC014ba5ec014Ba5EC014ba5Ec014bA5E";

    const [dependencies, setDependencies] = useState({
        // nHash,
        artifacts: [],
        tracerEnv: {
            enabled: false,
            logs: false,
            calls: false,
            sstores: false,
            sloads: false,
            gasCost: true,
            opcodes: [],
            nameTags: {},
            _internal: {
                printNameTagTip: undefined,
            },
        },
        // provider: new ethers.providers.JsonRpcProvider(`${getBackendBaseUrl()}/node/${nHash}`),
        nameTags: {},
    });

    const getData = async () => {
        setLoading(true);
        setBlockLoading(true);
        setErrMsg(null);

        if (!txDetails || Object.keys(txDetails).length === 0) {
            return;
        }

        try {
            // const response = await getTxDetails(state.rpcUrl, txHash);
            const response = txDetails;
            // console.log(response);

            //conversions
            let gweiGas;
            try {
                gweiGas = ethers.utils.formatUnits(response.gasPrice, "gwei");
            } catch (err) {
                gweiGas = "undefined";
            }

            let ethValue;
            try {
                ethValue = ethers.utils.formatUnits(response.value, "ether");
            } catch (err) {
                ethValue = "undefined";
            }

            let gweiGasused;
            try {
                gweiGasused = ethers.utils.formatUnits(
                    response.receipt.gasUsed,
                    0
                );
            } catch (err) {
                gweiGasused = "undefined";
            }

            if (gweiGasused !== "undefined" && gweiGas !== "undefined") {
                const txCost = ethers.utils
                    .parseUnits(gweiGas, "gwei")
                    .mul(gweiGasused);
                setTxCost(ethers.utils.formatUnits(txCost, "gwei"));
            }

            // type 2
            let gweiMaxFee, gweiMaxPriorGas;
            try {
                gweiMaxFee = ethers.utils.formatUnits(
                    response.maxFeePerGas,
                    "gwei"
                );
                gweiMaxPriorGas = ethers.utils.formatUnits(
                    response.maxPriorityFeePerGas,
                    "gwei"
                );
            } catch (err) {
                gweiMaxFee = "undefined";
                gweiMaxPriorGas = "undefined";
            }

            let revert;
            try {
                let op = response.trace.at(-1).op;
                let gasCost = response.trace.at(-1).gasCost;
                let params = response.trace.at(-1).params;

                async function myFunction() {
                    const result = await printRevertError(
                        op,
                        gasCost,
                        params,
                        dependencies
                    );
                    // console.log("Result ->>", result)
                    const unknown = result.match("UnknownError");
                    // console.log("Unknown ->>", unknown)
                    if (unknown === null) {
                        const errorReason = result.slice(7);
                        setTxFailMsg(errorReason);
                    }
                }
                myFunction();
                // revert = printRevert(op, gasCost, params, dependencies);
                // console.log(revert)
            } catch (err) {
                revert = "undefined";
            }
            // console.log("response", response.receipt.status);
            // console.log("Msg", txFailMsg)
            //setstate
            setInfo(response.from);
            setHash(response.hash);
            setConfirm(response.receipt.status);
            setBlock(response.blockNumber);
            setNonce(response.nonce);
            setData(response.data);
            setGasPrice(gweiGas);
            setValue(ethValue);
            setTo(response.to);
            setIsContract(response.isContract);
            setGasused(gweiGasused);
            setTxType(response.receipt.type);
            setIsSnapshotTx(response.isSnapshot);
            setContract(response.receipt.contractAddress);
            // Type 2
            setMaxfeepergas(gweiMaxFee);
            setMaxpriorgas(gweiMaxPriorGas);

            setBlockTransfer(response.blockNumber);
            setTimeStamp(response.date);

            const getTokenTransfer = async () => {
                if (response.erc20tokenTransferArray.length !== 0) {
                    // console.log(response.erc20tokenTransferArray.length, 'lengths')
                    setTokenTransfer(response.erc20tokenTransferArray);
                    // console.log('transfer', tokenTransfer)
                } else if (response.erc721tokenTransferArray.length !== 0) {
                    // console.log(response.erc721tokenTransferArray.length, 'lengths')
                    // console.log(response.erc721tokenTransferArray, 'before')
                    setTokenTransfer(response.erc721tokenTransferArray);
                    // console.log('mint', tokenTransfer)
                }
            };
            getTokenTransfer();

            if (response.date) {
                // We get date response from custom node Response, so don't fetch from block;
                try {
                    const date = new Date(response.date);
                    setTimeStamp(date.toLocaleString());
                    setBlockLoading(false);
                } catch (err) {
                    setTimeStamp("undefined");
                    setBlockLoading(false);
                }
            } else {
                // Not from custom node, hence query block manually
                getBlock(response.blockNumber);
            }
        } catch (err) {
            if (err.message.includes("could not detect network")) {
                setErrMsg(
                    "Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url."
                );
            } else if (err.message.includes("invalid hash")) {
                setErrMsg(
                    "This transaction address is not valid. Please recheck it."
                );
            } else {
                setErrMsg(
                    "Sorry we encountered an error. Please try again later."
                );
            }
            // console.log("Err", err);
        } finally {
            setLoading(false);
        }
    };

    async function getBlock(blockNum) {
        try {
            const block = await getBlockData(state.rpcUrl, blockNum);
            if (block.err) {
                throw new Error(block?.err?.msg);
            } else {
                const date = new Date(block.timestamp * 1000);
                setTimeStamp(date.toLocaleString());
            }
        } catch (err) {
            // if (err.message.includes("could not detect network")) {
            //     setErrMsg("Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.")
            // } else if (err.message.includes("Sorry, Block not found.")) {
            //     setErrMsg("Sorry, Block not found.")
            // } else {
            //     setErrMsg("Sorry we encountered an error. Please try again later.")
            // }
            setTimeStamp("undefined");
            // console.log("Err", err);
        } finally {
            setBlockLoading(false);
        }
    }

    // function handleRpcToggle(e) {
    //     setRpcVisible(e.target.checked);
    // }

    function handleLongUrl(string) {
        if (string.length < 30) {
            return <p>{string}</p>;
        } else {
            return (
                <div>
                    <Tooltip title={string} placement="right" arrow>
                        <span>{string.slice(0, 60) + "...."}</span>
                    </Tooltip>
                        <IconButton
                            color="primary"
                            style={{
                                cursor: "pointer",
                                margin: "0px 20px",
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(data);
                            }}
                        >
                            <ContentCopyIcon sx={{ fontSize: "15px" }} />
                        </IconButton> 

                </div>
            );
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useParams().txHash, txDetails]);

    if (errMsg) {
        return (
            <div className="containerbox">
                <div className="errContainer">
                    <i
                        className="fas fa-exclamation-triangle"
                        style={{
                            color: "red",
                            fontSize: "56px",
                            marginTop: "40px",
                        }}
                    ></i>
                    <h2
                        style={{
                            color: "#403F3F",
                            fontSize: "30px",
                            fontFamily: "Poppins",
                            marginTop: "20px",
                        }}
                    >
                        Oh Snap!
                    </h2>
                    <div className="errMsg">{errMsg}</div>
                    <button>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://mail.google.com/mail/?view=cm&fs=1&to=team@buildbear.io&su=Reporting Bug&body=Please-report-your-issue-and-provide-the-following-tx-hash-or-address-below"
                        >
                            Report
                        </a>
                    </button>
                </div>
            </div>
        );
    } else if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10vh",
                }}
            >
                <CircularProgress size="4rem" />
            </Box>
        );
        {
            /* <Spinner animation="grow" style={{ width: '100px', height: '100px', marginLeft: '30vw', marginTop: '10vw' }} /> */
        }
    } else {
        return (
            <div className="containerbox" style={{ paddingTop: "0" }}>
                <div className="transactionContainer">
                    {/* <div className="row">
                        <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                            <b>
                                RPC URL:
                                <Checkbox icon={<VisibilityOffIcon style={{ color: '#fff' }} />} checkedIcon={<VisibilityIcon />} checked={rpcVisible} onChange={handleRpcToggle} />
                            </b>
                        </div>
                        <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                            {rpcVisible ? rpcUrl : "**************************"}

                        </div>
                    </div>
                    <hr /> */}
                    {isSnapshotTx ? (
                        <>
                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Is Snaphot Transaction:</b>
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "15px" }}
                                >
                                    {isSnapshotTx.toString()}
                                </div>
                            </div>
                            <hr />
                        </>
                    ) : (
                        ""
                    )}

                    <div className="row">
                        <div
                            className="title"
                            style={{
                                width: "300px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <b>Transaction Hash:</b>
            
                        </div>
                        <div
                            className="value"
                            style={{ width: "700px", fontSize: "15px" }}
                        >
                            {hash}
                            <IconButton
                                color="primary"
                                style={{
                                    cursor: "pointer",
                                    margin: "0px 20px",
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(hash);
                                }}
                            >
                                <ContentCopyIcon sx={{ fontSize: "15px" }} />
                            </IconButton>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{
                                width: "300px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <b>Status:</b>
                        </div>
                        <div
                            className="value"
                            style={{ width: "700px", fontSize: "15px" }}
                        >
                            {confirm === 1 && (
                                <div
                                    className="value"
                                    style={{
                                        display: "flex",
                                        border: "1px solid green",
                                        borderRadius: "5px",
                                        width: " 100px ",
                                        justifyContent: "center",
                                        backgroundColor: "lightgreen",
                                    }}
                                >
                                    <p
                                        style={{
                                            padding: "1px",
                                        }}
                                    >
                                        Success
                                    </p>{" "}
                                    <CheckCircleIcon sx={{ fill: "green" }} />{" "}
                                </div>
                            )}
                            {confirm === 0 && (
                                <div
                                    className="value"
                                    style={{
                                        display: "flex",
                                        border: "1px solid red",
                                        borderRadius: "5px",
                                        width: " 100px ",
                                        justifyContent: "center",
                                        backgroundColor: "lightcoral",
                                        color: "white",
                                    }}
                                >
                                    <p
                                        style={{
                                            padding: "1px",
                                        }}
                                    >
                                        Failed
                                    </p>
                                    <ErrorIcon sx={{ fill: "red" }} />{" "}
                                </div>
                            )}
                        </div>
                    </div>
                    <hr />

                    {confirm === 0 && txFailMsg !== null ? (
                        <>
                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Reason:</b>
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "15px" }}
                                >
                                    <div className="value">{txFailMsg}</div>
                                </div>
                            </div>
                            <hr />
                        </>
                    ) : (
                        ""
                    )}

                    <div className="row">
                        <div
                            className="title"
                            style={{
                                width: "300px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <b>From: </b>
                        </div>
                        <div
                            className="value link-blue "
                            style={{
                                width: "700px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            
                           
                            <QueryLink to={`/address/${info}`}>

                                <span className="link-blue">
                                    { info == bbToken ? (<span>   
                                        <DarkToolTip
                                title={`${info}`}
                                arrow
                                placement="bottom"
                              >
                               <p className="link-blue" style={{pading: "0px"}} >Buildbear Faucet</p>
                              </DarkToolTip>

                               
                                </span>) : ( <span>{info}</span>)}
                                </span>
                            </QueryLink>
                            {/* {bbToken === info ? (
                                // <Tooltip
                                //     title={"Buildbear Faucet"}
                                //     placement="top"
                                //     arrow
                                // >
                                    <span>
                                        <img
                                            src={BbLogo}
                                            width="30px"
                                            style={{ marginLeft: "10px" }}
                                        />
                                    </span>
                                // </Tooltip>
                            ) : (
                                <></>
                            )} */}
                            <IconButton
                                color="primary"
                                style={{
                                    cursor: "pointer",
                                    margin: "0px 20px",
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(info);
                                }}
                            >
                                <ContentCopyIcon sx={{ fontSize: "15px" }} />
                            </IconButton>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{
                                width: "300px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <b>Interacted With (To):</b>
                        </div>
                        {/* {console.log(to, 'to')} */}
                        {to == null ? (
                            <div
                                className="value link-blue"
                                style={{ width: "700px", fontSize: "15px" }}
                            >
                                <b>Contract Deployed</b> at {""}
                                <QueryLink
                                    to={`/address/${contract}`}
                                    style={{
                                        color: "#403F3F",
                                        textDecoration: "none",
                                    }}
                                >
                                    {contract}
                                </QueryLink>
                                <IconButton
                                    color="primary"
                                    style={{
                                        cursor: "pointer",
                                        margin: "0px 20px",
                                    }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(contract);
                                    }}
                                >
                                    <ContentCopyIcon
                                        sx={{ fontSize: "15px" }}
                                    />
                                </IconButton>
                            </div>
                        ) : (
                            <div
                                className="value"
                                style={{ width: "700px", fontSize: "15px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    className="link-blue"
                                >
                                    {isContract ? (
                                        <div style={{ paddingRight: "5px" }}>
                                            <b>Contract</b>{" "}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <QueryLink
                                        to={`/address/${to}`}
                                        style={{
                                            color: "#403F3F",
                                            textDecoration: "none",
                                        }}
                                    >
                                        {to} <i className="fa fa-link"></i>
                                    </QueryLink>

                                    <IconButton
                                        color="primary"
                                        style={{
                                            cursor: "pointer",
                                            margin: "0px 20px",
                                        }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(to);
                                        }}
                                    >
                                        <ContentCopyIcon
                                            sx={{ fontSize: "15px" }}
                                        />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                    <hr />

                    {/* {tokenTransfer == 0 ? '' :
                        <>
                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Tokens Transferred:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '13px' }}>
                                    {console.log(tokenTransfer, 'token')}
                                    {tokenTransfer.map((token) => {
                                        var mint = '0x00000000000000000000000000000000000000000';
                                        return (
                                            <div className="tokentransfer-wrapper">
                                                <div> From: {token.from == mint ? <div><i>Mint</i></div> : <>{token.from}</>} </div>
                                                <div> To: {token.to == mint ? <div><i>Mint</i></div> : <>{token.to}</>} </div>
                                                {token.amount == undefined ? <div> ID: {token.id} </div>: <div> Amount: {token.amount} </div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <hr />
                        </>
                    } */}
                    {tokenTransfer === 0 ? (
                        ""
                    ) : (
                        <>
                            {/* {console.log(tokenTransfer === 0)} */}
                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Tokens Transferred:</b>
                                    {tokenTransfer[0].amount === undefined ? (
                                        <div style={{ fontSize: "13px" }}>
                                            <i>(ERC-721 Transfers found)</i>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "13px" }}
                                >
                                    {/* {console.log(tokenTransfer, 'token')} */}
                                    {tokenTransfer.map((token) => {
                                        var mint =
                                            "0x0000000000000000000000000000000000000000";
                                        // console.log('amt', token.amount)

                                        return (
                                            <div
                                                className="tokentransfer-wrapper"
                                                style={{ paddingBottom: "5px" }}
                                            >
                                                {/* <div> From: {token.from == mint ? <div><i>Mint</i></div> : <>{token.from}</>} </div>
                                                <div> To: {token.to == mint ? <div><i>Mint</i></div> : <>{token.to}</>} </div>
                                                {token.amount == undefined ? <div> ID: {token.id} </div>: <div> Amount: {token.amount} </div>} */}
                                                {token.amount === undefined ? (
                                                    <div
                                                        style={{
                                                            display: " flex",
                                                            gap: "5px",
                                                            flexDirection:
                                                                "column",
                                                            marginBottom:
                                                                "14px",
                                                        }}
                                                    >
                                                        <div
                                                            className="from"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <b>From:&nbsp;</b>
                                                            {token.from ===
                                                            mint ? (
                                                                <p
                                                                    style={{
                                                                        padding:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    <i>
                                                                        Null
                                                                        Address
                                                                        (Token
                                                                        Minted)
                                                                    </i>
                                                                </p>
                                                            ) : (
                                                                <QueryLink
                                                                    to={`/address/${token.from}`}
                                                                    style={{
                                                                        color: "#403F3F",
                                                                        textDecoration:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {token.from}{" "}
                                                                    <i className="fa fa-link"></i>
                                                                </QueryLink>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="to"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <b>To:&nbsp;</b>
                                                            {token.to ===
                                                            mint ? (
                                                                <p
                                                                    style={{
                                                                        padding:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    <i>
                                                                        Null
                                                                        Address
                                                                        (Token
                                                                        Burnt)
                                                                    </i>
                                                                </p>
                                                            ) : (
                                                                <QueryLink
                                                                    to={`/address/${token.to}`}
                                                                    style={{
                                                                        color: "#403F3F",
                                                                        textDecoration:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {token.to}{" "}
                                                                    <i className="fa fa-link"></i>
                                                                </QueryLink>
                                                            )}
                                                        </div>
                                                        {/* <div style={{ display: 'flex' }}>
                                                            <div className="from" style={{ display: 'flex' }}><b>From:</b>{token.from === mint ? <p style={{ padding: '0px' }}><i>Null Address (Token Minted)</i></p> : token.from}</div>
                                                            <div className="to" style={{ display: 'flex' }}><b>To:</b> {token.to === mint ? <p style={{ padding: '0px' }}><i>Null Address (Token Burnt)</i></p> : token.to}</div>
                                                        </div> */}
                                                        <div>
                                                            <i>
                                                                For ERC-721
                                                                Token ID [
                                                                {token.id}]
                                                            </i>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            flexDirection:
                                                                "column",
                                                            marginBottom:
                                                                "14px",
                                                        }}
                                                    >
                                                        {/* <div style={{ display: 'flex' }}> */}
                                                        <div
                                                            className="from"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <b>From:&nbsp;</b>
                                                            {token.from ===
                                                            mint ? (
                                                                <p
                                                                    style={{
                                                                        padding:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    <i>
                                                                        Null
                                                                        Address
                                                                        (Token
                                                                        Minted)
                                                                    </i>
                                                                </p>
                                                            ) : (
                                                                <QueryLink
                                                                    to={`/address/${token.from}`}
                                                                    style={{
                                                                        color: "#403F3F",
                                                                        textDecoration:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {token.from}{" "}
                                                                    <i className="fa fa-link"></i>
                                                                </QueryLink>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="to"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <b>To:&nbsp;</b>
                                                            {token.to ===
                                                            mint ? (
                                                                <p
                                                                    style={{
                                                                        padding:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    <i>
                                                                        Null
                                                                        Address
                                                                        (Token
                                                                        Burnt)
                                                                    </i>
                                                                </p>
                                                            ) : (
                                                                <QueryLink
                                                                    to={`/address/${token.to}`}
                                                                    style={{
                                                                        color: "#403F3F",
                                                                        textDecoration:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    {token.to}{" "}
                                                                    <i className="fa fa-link"></i>
                                                                </QueryLink>
                                                            )}
                                                        </div>

                                                        {/* <div><b>Amount: </b>{ethAmount} ETH</div> */}
                                                        {/* </div> */}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <b>Amount:&nbsp;</b>
                                                            {token.amount}&nbsp;
                                                            <QueryLink
                                                                to={`/address/${token.address}`}
                                                                style={{
                                                                    color: "#403F3F",
                                                                    textDecoration:
                                                                        "none",
                                                                }}
                                                            >
                                                                {token.symbol}{" "}
                                                                <i className="fa fa-link"></i>
                                                            </QueryLink>
                                                        </div>
                                                        {/* <div style={{ display: 'flex' }}><b>Amount:</b> {ethers.utils.formatEther(token.amount)} ETH</div> */}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <hr />
                        </>
                    )}

                    <div className="row">
                        <div
                            className="title"
                            style={{
                                width: "300px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <b>Input Data:</b>
                        </div>
                        <div
                            className="value"
                            style={{
                                width: "700px",
                                overflowX: "auto",
                                fontSize: "15px",
                            }}
                        >
                            {/* {data ? data : "No Input Data"} */}
                            {/* {handleLongUrl(data)} */}
                            {handleLongUrl(data)}
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title link-blue"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Block:</b>
                        </div>

                        <div
                            className="value link-blue"
                            style={{ width: "700px", fontSize: "15px" }}
                        >
                            <QueryLink
                                to={`/block/${block}`}
                                style={{
                                    color: "#403F3F",
                                    display: "flex",
                                    color: "blue",
                                    width: "20%",
                                }}
                            >
                                <GridViewIcon sx={{ fill: "#0155af" }} />
                                &nbsp;
                                <span className="link-blue">{block}</span>{" "}
                            </QueryLink>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Nonce:</b>
                        </div>
                        <div
                            className="value"
                            style={{ width: "700px", fontSize: "15px" }}
                        >
                            {nonce}
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Gas Price:</b>
                        </div>
                        <div
                            className="value"
                            style={{
                                width: "700px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LocalGasStationIcon /> &nbsp;
                            <span className="pt-0">{gasPrice} (gwei)</span>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Value:</b>
                        </div>
                        <div
                            className="value"
                            style={{
                                width: "700px",
                                fontSize: "15px",
                                display: "flex",
                                alignContent: "center",
                            }}
                        >
                            <BbToken width="20px" height="22px" fill="red" />
                            <div style={{ marginLeft: "5px" }}>
                                {value} BB ETH
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Timestamp:</b>
                        </div>
                        <div
                            className="value"
                            style={{
                                width: "700px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <AccessTimeFilledIcon /> &nbsp;
                            {blockLoading ? (
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        borderRadius: "0",
                                        backgroundColor:
                                            "rgba(255, 255, 255, 0.13)",
                                    }}
                                    width="28ch"
                                />
                            ) : (
                                <span style={{ marginTop: "2px" }}>
                                    {timeStamp}
                                </span>
                            )}
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Gas Used:</b>
                        </div>
                        <div
                            className="value"
                            style={{ width: "700px", fontSize: "15px" }}
                        >
                            {gasused}
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Tx Cost:</b>
                        </div>
                        <div
                            className="value"
                            style={{
                                width: "700px",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <EthLogo width="20px" height="22px" fill="red" />

                            <div style={{ marginLeft: "5px" }}>
                                {txCost} (gwei){" "}
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div
                            className="title"
                            style={{ width: "300px", fontSize: "15px" }}
                        >
                            <b>Tx Type:</b>
                        </div>
                        <div
                            className="value"
                            style={{ width: "700px", fontSize: "15px" }}
                        >
                            {txType === "2" ? (
                                <>
                                    {txType} <b>(EIP-1559)</b>
                                </>
                            ) : (
                                <>
                                    {txType} <b>(Legacy)</b>
                                </>
                            )}
                        </div>
                    </div>

                    {txType === "2" ? (
                        <>
                            <hr />
                            {/* enter data */}
                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Max Fee Per Gas:</b>
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "15px" }}
                                >
                                    {maxfeepergas} gwei
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Max Priority Fee Per Gas:</b>
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "15px" }}
                                >
                                    {maxpriorgas} gwei
                                </div>
                            </div>
                            {/* <QueryLink to="/transaction/logs">
                                <div style={{ marginTop: '5px' }}>
                                    <p>See Logs</p>
                                </div>
                            </QueryLink> */}
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
};

export default Transaction;

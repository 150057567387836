import Code from "./Code";
import ReadContract from "./ReadContract";
import WriteContract from "./WriteContract";
import { Stack, Button, Tooltip } from "@mui/material";
import { useParams, Route, Routes, useLocation } from "react-router-dom";
// import { useEffect, useState } from "react";
import { QueryLink, QueryNavigate } from "../QueryLinkNavigate";
// import ProxyAddress from "./ProxyAddress";

import "./ContractTab.css"

function ContractTab(props) {
    const {
        abi,
        contractInstance,
        setAbi,

        readFunctions,
        setReadFunctions,
        readLoadedOnce,
        setReadLoadedOnce,

        writeFunctions,
        setWriteFunctions,
        writeLoadedOnce,
        setWriteLoadedOnce,

        proxyContractAddress,
        proxyContractAbi,
        proxyContractInstance,

        readProxyFunctions,
        setReadProxyFunctions,
        readProxyLoadedOnce,
        setReadProxyLoadedOnce,

        writeProxyFunctions,
        setWriteProxyFunctions,
        writeProxyLoadedOnce,
        setWriteProxyLoadedOnce,
        soliditySource
    } = props;
    const params = useParams();
    // const [readFunctions, setReadFunctions] = useState([]);
    // const [readLoadedOnce, setReadLoadedOnce] = useState();
    let location = useLocation();
    const hash = location.hash;
    console.log(hash)
    
    function CustomToolTip(props) {
        return (
            <Tooltip
                arrow
                title={!abi ? "Upload the abi to interact with the contract" : ""}>
                {props.children}
            </Tooltip>
        )
    }
    function getVariant(urlFrag) {
        if (urlFrag === params['*'])
            return "contained";
        else
            return "outlined";
    }


    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                sx={{ width: "100%", marginBottom: '10px' }}
            >
                <QueryLink
                    className="code-button"
                    style={{ textDecoration: "none" }}
                    to="code"
                >
                    <Button
                        sx={{ textTransform: 'none' }}
                        variant={getVariant('code')}
                        size="small"
                        className={ (hash === "#code" || hash === "" ) ? "active-tab" : ""}
                        
                    >Code</Button>
                </QueryLink>
                { abi ? <QueryLink
                    style={{ textDecoration: "none" }}
                    to={abi ? "readContract" : "code"}
                >
                    <CustomToolTip>
                        <span>
                            <Button
                                sx={{ textTransform: 'none' }}
                                variant={getVariant('readContract')}
                                size="small"
                                disabled={!abi}
                                className={hash == "#readContract" ? "active-tab" : ""} 
                            >Read Contract</Button>
                        </span>
                    </CustomToolTip>
                </QueryLink>:""}
                
                { abi ? <QueryLink
                    style={{ textDecoration: "none" }}
                    to={abi ? "writeContract" : "code"}>
                    <CustomToolTip>
                        <span>
                            <Button
                                sx={{ textTransform: 'none' }}
                                variant={getVariant('writeContract')}
                                size="small"
                                disabled={!abi}
                                className={hash == "#writeContract" ? "active-tab" : ""} 
                            >Write Contract</Button>
                        </span>
                    </CustomToolTip>
                </QueryLink>:""}
                { proxyContractAddress ? <QueryLink
                    style={{ textDecoration: "none" }}
                    to={proxyContractAddress ? "readContractProxy" : "code"}>
                    <CustomToolTip>
                        <span>
                            <Button
                                sx={{ textTransform: 'none' }}
                                variant={getVariant('readContractProxy')}
                                size="small"
                                disabled={!proxyContractAddress}
                                className={hash == "#readContractProxy" ? "active-tab" : ""} 

                            >Read Contract as Proxy</Button>
                        </span>
                    </CustomToolTip>
                </QueryLink>:""}
                { proxyContractAddress?<QueryLink
                    style={{ textDecoration: "none" }}
                    to={proxyContractAddress ? "writeContractProxy" : "code"}>
                    <CustomToolTip>
                        <span>
                            <Button
                                sx={{ textTransform: 'none' }}
                                variant={getVariant('writeContractProxy')}
                                size="small"
                                disabled={!proxyContractAddress}
                                className={hash == "#writeContractProxy" ? "active-tab" : ""} 
                            >Write Contract as Proxy</Button>
                        </span>
                    </CustomToolTip>
                </QueryLink>:""}
            </Stack>
                    
                    { 
                    hash == "#code" && <><Code
                        abi={abi}
                        contractInstance={contractInstance}
                        setAbi={setAbi}
                        setReadLoadedOnce={setReadLoadedOnce}
                        setWriteLoadedOnce={setWriteLoadedOnce}
                        soliditySource = {soliditySource}
                    />
                    </>
                    }
                    { 
                        hash == "#readContract" &&  <ReadContract
                            abi={abi}
                            contractInstance={contractInstance}
                            readFunctions={readFunctions}
                            setReadFunctions={setReadFunctions}
                            readLoadedOnce={readLoadedOnce}
                            setReadLoadedOnce={setReadLoadedOnce}
                        />
                    }
                    { 
                        hash == "#writeContract" &&  <WriteContract
                        abi={abi}
                        contractInstance={contractInstance}
                        writeFunctions={writeFunctions}
                        setWriteFunctions={setWriteFunctions}
                        writeLoadedOnce={writeLoadedOnce}
                        setWriteLoadedOnce={setWriteLoadedOnce}
                        />
                    }
                    {
                        hash == "#readContractProxy" && <ReadContract
                        abi={proxyContractAbi}
                        contractInstance={proxyContractInstance}
                        readFunctions={readProxyFunctions}
                        setReadFunctions={setReadProxyFunctions}
                        readLoadedOnce={readProxyLoadedOnce}
                        setReadLoadedOnce={setReadProxyLoadedOnce}
                        pageName="Read Contract as Proxy"
                    />
                    }
                    {
                        hash =="#writeContractProxy" && <WriteContract
                        abi={proxyContractAbi}
                        contractInstance={proxyContractInstance}
                        writeFunctions={writeProxyFunctions}
                        setWriteFunctions={setWriteProxyFunctions}
                        writeLoadedOnce={writeProxyLoadedOnce}
                        setWriteLoadedOnce={setWriteProxyLoadedOnce}
                        pageName="Write Contract as Proxy"
                    />
                    }
                    
             <Routes>
        
                <Route
                    path="/code"
                    element={<><Code
                        abi={abi}
                        contractInstance={contractInstance}
                        setAbi={setAbi}
                        setReadLoadedOnce={setReadLoadedOnce}
                        setWriteLoadedOnce={setWriteLoadedOnce}
                        soliditySource = {soliditySource}
                    />
                    </>}
                />

                <Route
                    path="/readContract"
                    element={abi
                        ? <ReadContract
                            abi={abi}
                            contractInstance={contractInstance}
                            readFunctions={readFunctions}
                            setReadFunctions={setReadFunctions}
                            readLoadedOnce={readLoadedOnce}
                            setReadLoadedOnce={setReadLoadedOnce}
                        />
                        : <QueryNavigate to="code" replace />
                    }
                />
                <Route
                    path="/writeContract"
                    element={abi
                        ? <WriteContract
                            abi={abi}
                            contractInstance={contractInstance}
                            writeFunctions={writeFunctions}
                            setWriteFunctions={setWriteFunctions}
                            writeLoadedOnce={writeLoadedOnce}
                            setWriteLoadedOnce={setWriteLoadedOnce}
                        />
                        : <QueryNavigate to="code" replace />
                    }
                />
                <Route
                    path="/readContractProxy"
                    element={abi
                        ? <ReadContract
                            abi={proxyContractAbi}
                            contractInstance={proxyContractInstance}
                            readFunctions={readProxyFunctions}
                            setReadFunctions={setReadProxyFunctions}
                            readLoadedOnce={readProxyLoadedOnce}
                            setReadLoadedOnce={setReadProxyLoadedOnce}
                            pageName="Read Contract as Proxy"
                        />
                        : <QueryNavigate to="code" replace />
                    }
                />
                <Route
                    path="/writeContractProxy"
                    element={abi
                        ? <WriteContract
                            abi={proxyContractAbi}
                            contractInstance={proxyContractInstance}
                            writeFunctions={writeProxyFunctions}
                            setWriteFunctions={setWriteProxyFunctions}
                            writeLoadedOnce={writeProxyLoadedOnce}
                            setWriteLoadedOnce={setWriteProxyLoadedOnce}
                            pageName="Write Contract as Proxy"
                        />
                        : <QueryNavigate to="code" replace />
                    }
                />
                {/* <Route
                    path="*"
                    element={<QueryNavigate to={abi ? "readContract" : "code"} replace />}
                /> */}
            </Routes>
            {/* <Code 
            abi={abi} 
            contractInstance={contractInstance} 
        />

        <ReadContract
            contractInstance={contractInstance}
        />
        <WriteContract
            contractInstance={contractInstance}
        /> */}
        </>
    )
}

export default ContractTab;
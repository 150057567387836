import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function UploadIndicator(props) {
  if (props.uploading === "uploading") {
    return (
      <Box sx={{ width: "100%", mx:2 }}>
        Uploading Parsed Artifacts...
        <LinearProgress color="success" sx={{width:'90%'}}/>
      </Box>
    );
  } else if (props.uploading === "uploaded") {
    return (
      <Box sx={{ width: "100%", mx:2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 2 }}>Uploading Complete</Box>
          <Box sx={{ minWidth: 35, color: "green" }}>
            <CheckCircleIcon />
          </Box>
        </Box>
      </Box>
    );
  } else if (props.uploading === "error") {
    return (
      <Box sx={{ width: "100%", mx:2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 2 }}>Upload Failed</Box>
          <Box sx={{ minWidth: 35, color: "red" }}>
            <CancelIcon />
          </Box>
        </Box>
      </Box>
    );
  }
}
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
// import { Link } from "react-router-dom";
import "../styles/Node.css";
import Navbar from "../components/Navbar";
const NotFound = () => {

  let url = window.location.href
  const words = url.split('/');
  let nodeName = words[words.length - 1]
  return (
    <>
      <Navbar />
      <Box className="wrapper">
        <Stack
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ height: "75vh", fontSize: '16px' }}
        >
          <Typography variant="h3" sx={{ fontWeight: 'medium' }}> 404 | Not Found </Typography>
          <Typography variant="subTitle" sx={{ fontWeight: 'medium' }}> Please check the Testnet Name</Typography>
          <Typography variant="subTitle" sx={{ fontWeight: 'medium' }}> Testnet: {nodeName} is not reachable</Typography>
          {/* <Link to="/"> Go to Home </Link> */}
        </Stack>
      </Box>
    </>
  );
};

export default NotFound;

import { formatLog } from "../format/log.js";

export async function printLog0(
  op,
  gasCost,
  params,
  currentAddress,
  dependencies
) {

  const str = await formatLog(
    {
      data: params.data,
      topics: [],
    },
    currentAddress,
    dependencies
  );
  return "EVENT" +
    " " +
    str +
    ` (cost: ${gasCost})`
}

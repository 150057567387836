import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import axios from "axios";
// import { Spinner } from 'react-bootstrap'
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
// import { useContext } from "react";
// import { AppContext } from "../../App";
import useTransaction from "../hooks/useTransaction";
import { getBackendBaseUrl } from "../api/explorer";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

import { printCall } from "../components/ParseTrace/opcodes/call";
import { printCreate } from "../components/ParseTrace/opcodes/create";
import { printCreate2 } from "../components/ParseTrace/opcodes/create2";
import { printCallCode } from "../components/ParseTrace/opcodes/callcode";
import { printStaticCall } from "../components/ParseTrace/opcodes/staticcall";
import { printDelegateCall } from "../components/ParseTrace/opcodes/delegatecall";
import { printLog0 } from "../components/ParseTrace/opcodes/log0";
import { printLog1 } from "../components/ParseTrace/opcodes/log1";
import { printLog2 } from "../components/ParseTrace/opcodes/log2";
import { printLog3 } from "../components/ParseTrace/opcodes/log3";
import { printLog4 } from "../components/ParseTrace/opcodes/log4";
// import { printSload } from '../components/ParseTrace/opcodes/sload';
// import { printSstore } from '../components/ParseTrace/opcodes/sstore';
import { printRevert } from "../components/ParseTrace/opcodes/revert";
import { arrayify } from "ethers/lib/utils";

// const DEPTH_INDENTATION = "      ";

export default function RichObjectTreeView({
    nodeDetails,
    txDetails,
    trace,
    setTrace,
    loading,
    setLoading,
}) {
    const txHash = useParams().txHash;
    const { nHash } = useParams();

    const [bigTrace, setBigTrace] = useState(false);

    const [info, setInfo] = useState();
    const [dependencies, setDependencies] = useState({
        nHash,
        artifacts: [],
        tracerEnv: {
            enabled: false,
            logs: false,
            calls: false,
            sstores: false,
            sloads: false,
            gasCost: true,
            opcodes: [],
            nameTags: {},
            _internal: {
                printNameTagTip: undefined,
            },
        },
        // provider: new ethers.providers.JsonRpcProvider(`${getBackendBaseUrl()}/node/${nHash}`),
        nameTags: {},
    });
    // const [trace, setTrace] = useState([])

    // const [loadingText, setLoadingText] = useState("Loading Contract Information...")
    // const [loadingDots, setLoadingDots] = useState(2);
    // const [loading, setLoading] = useState({
    //   text: "Loading Contract Information...",
    //   dots: 3,
    //   state: true
    // });

    useEffect(() => {
        if (
            nodeDetails &&
            info &&
            nodeDetails.blockNumber &&
            info.blockNumber &&
            nodeDetails.blockNumber < info.blockNumber &&
            info.trace &&
            info.trace.length === 0
        ) {
            setBigTrace(true);
        }
    }, [nodeDetails, info]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoading((loading) => {
                if (loading.state) {
                    if (!loading.text.startsWith("Loading")) {
                        return {
                            text: "Loading Contract Information...",
                            dots: 3,
                            state: true,
                        };
                    } else {
                        const dots = (loading.dots % 3) + 1;
                        return {
                            text:
                                "Loading Contract Information" +
                                ".".repeat(dots),
                            dots,
                            state: true,
                        };
                    }
                } else {
                    return {
                        text: "Loaded Contract Information",
                        dots: 0,
                        state: false,
                    };
                }
            });
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const getData = async () => {
        try {
            // const response = await getTxDetails(state.rpcUrl, txHash);
            const response = txDetails;
            // console.log("This is the response", response);

            //setstate
            setInfo(response);
        } finally {
        }
    };

    const getDependencies = async () => {
        const contracts = [];
        const abis = [];

        const rpc = `${getBackendBaseUrl()}/node/${nHash}`;
        const provider = new ethers.providers.JsonRpcProvider(rpc);

        if (!loading.state) {
            return;
        }

        if (info) {
            for (const item of info.trace) {
                if (item?.params?.to && !contracts.includes(item.params.to)) {
                    contracts.push(item.params.to);
                    let response;
                    try {
                        response = await axios.get(
                            `${getBackendBaseUrl()}/abi/${nHash}/${
                                item.params.to
                            }`
                        );
                    } catch (_) {
                        continue;
                    }
                    const data = {
                        abi: JSON.parse(response.data.abi),
                        contractName: response.data.contractName,
                    };
                    data.deployedBytecode = await provider.getCode(
                        item.params.to
                    );
                    abis.push(data);
                }
            }
        }

        let artifacts;

        try {
            artifacts = await axios.get(`${getBackendBaseUrl()}/abis/${nHash}`);
        } catch {}

        artifacts = artifacts ? (artifacts.data[0] ? artifacts.data : []) : [];
        artifacts = artifacts.filter((artifact) => artifact.abi);
        artifacts.push(...abis);
        // console.log("This is the artifacts", artifacts);

        setDependencies({
            nHash,
            artifacts,
            tracerEnv: {
                enabled: false,
                logs: false,
                calls: false,
                sstores: false,
                sloads: false,
                gasCost: true,
                opcodes: [],
                nameTags: {},
                _internal: {
                    printNameTagTip: undefined,
                },
            },
            provider,
            nameTags: {},
        });
    };

    useEffect(getData, [useParams().txHash, txDetails]);

    useEffect(getDependencies, [info]);

    useEffect(async () => {
        if (!loading.state) {
            return;
        }

        if (info && info.trace && info.trace.length > 0) {
            info.trace = info.trace.filter((i) => i);

            const addresses = [];
            const trace = [];
            let value;

            for (const item of info.trace) {
                switch (item.op) {
                    case "CREATE":
                        value =
                            "CREATE" +
                            " " +
                            `${"UnknownContract"}(${"deployCodeSize="}${
                                arrayify(item.params.code).length
                            })` +
                            ` (cost: ${item.gasCost})`;
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.deployedAddress);
                        break;
                    case "CREATE2":
                        value =
                            "CREATE" +
                            " " +
                            `${"UnknownContract"}(${"deployCodeSize="}${
                                arrayify(item.params.code).length
                            })` +
                            ` (cost: ${item.gasCost})`;
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.deployedAddress);
                        break;
                    case "CALL":
                        value =
                            "CALL" +
                            " " +
                            `${"UnknownContractAndFunction"}(${"to="}${
                                item.params.to
                            }, ${"input="}${item.params.input}, ${"ret="}${
                                item.params.ret
                            })` +
                            ` (cost: ${item.gasCost})` +
                            ` (to: ${item.params.to})`;
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.to);
                        break;
                    case "CALLCODE":
                        value =
                            "CALLCODE" +
                            " " +
                            `${"UnknownContractAndFunction"}(${"to="}${
                                item.params.to
                            }, ${"input="}${item.params.input}, ${"ret="}${
                                item.params.ret
                            })` +
                            ` (cost: ${item.gasCost})`;
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "STATICCALL":
                        value =
                            item.params.types && item.params.output
                                ? "CONSOLE_LOG" +
                                  " " +
                                  item.params.output.join(" ")
                                : "STATICCALL" +
                                  " " +
                                  `${"UnknownContractAndFunction"}(${"to="}${
                                      item.params.to
                                  }, ${"input="}${
                                      item.params.input
                                  }, ${"ret="}${item.params.ret})` +
                                  ` (cost: ${item.gasCost})` +
                                  ` (to: ${item.params.to})`;
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.to);
                        break;
                    case "DELEGATECALL":
                        value =
                            "DELEGATECALL" +
                            " " +
                            `${"UnknownContractAndFunction"}(${"to="}${
                                item.params.to
                            }, ${"input="}${item.params.input}, ${"ret="}${
                                item.params.ret
                            })` +
                            ` (cost: ${item.gasCost})` +
                            ` (to: ${item.params.to})`;
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(addresses[addresses.length - 1]);
                        break;
                    case "LOG0":
                        value = "EVENT";
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG1":
                        value = "EVENT";
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG2":
                        value = "EVENT";
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG3":
                        value = "EVENT";
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG4":
                        value = "EVENT";
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    // case "SLOAD":
                    //   value = await printSload(item.op, item.gasCost, item.params, dependencies);
                    //   trace.push({
                    //     ...item,
                    //     value,
                    //   })
                    //   break;
                    // case "SSTORE":
                    //   value = await printSstore(item.op, item.gasCost, item.params, dependencies);
                    //   trace.push({
                    //     ...item,
                    //     value,
                    //   })
                    //   break;
                    case "REVERT":
                        value = "REVERT";
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.pop();
                        break;
                    case "RETURN":
                        addresses.pop();
                        break;
                    case "STOP":
                        addresses.pop();
                        break;
                    default:
                        // if (dependencies.tracerEnv.opcodes.includes(structLog.op)) {
                        //   console.log(DEPTH_INDENTATION.repeat(structLog.depth) + structLog.op);
                        // }
                        break;
                }

                // console.log("This is the value", info.trace[index].value);
            }

            const finalTrace = trace.map((item, index) => ({
                ...item,
                abs: (index + 1).toString(),
            }));
            setTrace([...finalTrace]);
        }
    }, [info]);

    useEffect(async () => {
        if (!loading.state) {
            return;
        }

        if (info && info.trace && info.trace.length > 0) {
            info.trace = info.trace.filter((i) => i);

            const addresses = [];
            const trace = [];
            let value;

            for (const item of info.trace) {
                switch (item.op) {
                    case "CREATE":
                        value = await printCreate(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.deployedAddress);
                        break;
                    case "CREATE2":
                        value = await printCreate2(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.deployedAddress);
                        break;
                    case "CALL":
                        value = await printCall(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.to);
                        break;
                    case "CALLCODE":
                        value = await printCallCode(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "STATICCALL":
                        value = await printStaticCall(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(item.params.to);
                        break;
                    case "DELEGATECALL":
                        value = await printDelegateCall(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.push(addresses[addresses.length - 1]);
                        break;
                    case "LOG0":
                        value = await printLog0(
                            item.op,
                            item.gasCost,
                            item.params,
                            addresses[addresses.length - 1],
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG1":
                        value = await printLog1(
                            item.op,
                            item.gasCost,
                            item.params,
                            addresses[addresses.length - 1],
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG2":
                        value = await printLog2(
                            item.op,
                            item.gasCost,
                            item.params,
                            addresses[addresses.length - 1],
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG3":
                        value = await printLog3(
                            item.op,
                            item.gasCost,
                            item.params,
                            addresses[addresses.length - 1],
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    case "LOG4":
                        value = await printLog4(
                            item.op,
                            item.gasCost,
                            item.params,
                            addresses[addresses.length - 1],
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        break;
                    // case "SLOAD":
                    //   value = await printSload(item.op, item.gasCost, item.params, dependencies);
                    //   trace.push({
                    //     ...item,
                    //     value,
                    //   })
                    //   break;
                    // case "SSTORE":
                    //   value = await printSstore(item.op, item.gasCost, item.params, dependencies);
                    //   trace.push({
                    //     ...item,
                    //     value,
                    //   })
                    //   break;
                    case "REVERT":
                        value = await printRevert(
                            item.op,
                            item.gasCost,
                            item.params,
                            dependencies
                        );
                        trace.push({
                            ...item,
                            value,
                        });
                        addresses.pop();
                        break;
                    case "RETURN":
                        addresses.pop();
                        break;
                    case "STOP":
                        addresses.pop();
                        break;
                    default:
                        // if (dependencies.tracerEnv.opcodes.includes(structLog.op)) {
                        //   console.log(DEPTH_INDENTATION.repeat(structLog.depth) + structLog.op);
                        // }
                        break;
                }
            }

            const finalTrace = trace.map((item, index) => ({
                ...item,
                abs: (index + 1).toString(),
            }));
            setTrace([...finalTrace]);
            setLoading({ ...loading, state: false });
        }
    }, [dependencies]);

    const renderTree = (trace) => {
        if (!trace || trace.length === 0) {
            return undefined;
        } else {
            trace = trace.filter((n) => n);
            const depth = trace[0].depth;

            const indexes = trace
                .map((x, index) => [x.depth, index])
                .filter(([xDepth]) => xDepth === depth)
                .map((x) => x[1]);

            const pairs = [];
            for (let i = 0; i < indexes.length - 1; i++) {
                pairs.push([indexes[i] + 1, indexes[i + 1]]);
            }
            pairs.push([indexes[indexes.length - 1] + 1, trace.length]);

            const children = pairs.map(([start, end]) => {
                return renderTree(trace.slice(start, end));
            });

            return indexes.map((index, i) => {
                return (
                    <TreeItem
                        sx={{
                            maxWidth: 1100,
                            width: "90vw",
                            wordWrap: "break-word",
                        }}
                        key={trace[index].abs}
                        nodeId={trace[index].abs}
                        label={trace[index].value ? trace[index].value : ""}
                    >
                        {children[i]}
                    </TreeItem>
                );
            });
        }
    };
    return (
        <>
            <div className="tracer-box">
                {bigTrace ? (
                    <>
                        <h1
                            style={{
                                fontSize: 20,
                                margin: "10px 10px 0px 0px",
                            }}
                        >
                            Trace cannot be generated. Transaction is too huge
                            to be processed.
                        </h1>
                        <h1
                            style={{
                                fontSize: 20,
                                margin: "00px 10px 10px 0px",
                            }}
                        >
                            For further assistance reach out to
                            team@buildbear.io
                        </h1>
                    </>
                ) : (
                    <>
                        <p
                            style={{
                                fontSize: 15,
                                margin: "0px 10px 10px 10px",
                                padding: "20px",
                            }}
                        >
                            {loading.text}
                        </p>
                        <TreeView
                            aria-label="rich object"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpanded={["root"]}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{
                                height: 510,
                                flexGrow: 1,
                                maxWidth: 1100,
                                width: "90vw",
                                whiteSpace: "initial",
                            }}
                        >
                            {renderTree(trace)}
                        </TreeView>
                    </>
                )}
            </div>
        </>
    );
}

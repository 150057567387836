// import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import '../../eth-scan/style.css'
import { useParams } from "react-router-dom";
// import { useContext } from "react";
// import { AppContext } from "../../App";
import { Tabs, Tab, AppBar } from '@mui/material'
import Footer from "../../components/Footer";
import AllTransactions from "../../components/node/allTransactions";
// import { getBlockData } from "../../api/publicExplorer";
import useBlock from "../../hooks/useBlock";

// function TabPanel(props) {
//     const { children, value, index } = props;
//     return (
//         <div>
//             {value === index && (<h1>{children}</h1>)}
//         </div>
//     )
// }
const Block = (props) => {
    const blockNum = useParams().blockNum;
    // const { state } = useContext(AppContext);
    // let { nHash } = useParams();
    // const rpcUrl = state.rpcUrl;

    // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const getBlockData = useBlock();
    // tabs
    // const [tabVal, setTabVal] = useState(0)
    // const handleTabs = (e, val) => {
    //     setTabVal(val)
    // }

    //states
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const [tab, setTab] = useState(0);
    const [timeStamp, setTimeStamp] = useState('')
    const [transactionLength, setTransactionLength] = useState()
    const [miner, setMiner] = useState()
    const [difficulty, setDifficulty] = useState()
    const [gasUsed, setGasUsed] = useState()
    const [extraData, setExtraData] = useState()
    const [nonce, setNonce] = useState()
    const [parentHash, setParentHash] = useState()
    const [baseFeePerGas, setBaseFeePerGas] = useState()
    const intBlockNum = Number(blockNum)


    // conversions
    const getBlock = async () => {
        setLoading(true);
        setErrMsg(null);
        try {

            const block = await getBlockData(intBlockNum);
            if (block.err) {
                throw new Error(block?.err?.msg);
            }
            // console.log("Block", block);
            const date = new Date(block.timestamp * 1000);
            const blockExtraData = block.extraData;

            const lengtharr = block.transactions.length;
            const blockMiner = block.miner;
            const blockDifficulty = block.difficulty;
            const blockGasUsed = block.gasUsed;
            let gweiBlockGasUsed;
            try {
                gweiBlockGasUsed = ethers.utils.formatUnits(blockGasUsed, 'gwei')
            } catch (err) {
                gweiBlockGasUsed = "undefined"
            }
            let gweibasefee;
            try {
                gweibasefee = ethers.utils.formatUnits(block.baseFeePerGas, 'gwei')
            } catch (err) {
                gweibasefee = "undefined"
            }

            // set state
            setTimeStamp(date.toLocaleString());
            setTransactionLength(lengtharr)
            setMiner(blockMiner);
            setDifficulty(blockDifficulty)
            setGasUsed(gweiBlockGasUsed);
            setExtraData(blockExtraData)
            setNonce(block.nonce)
            setParentHash(block.parentHash)
            setBaseFeePerGas(gweibasefee);
        } catch (err) {
            if (err.message.includes("could not detect network")) {
                setErrMsg("Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.")
            }
            else if (err.message.includes("block not found") ||
                err.message.includes("invalid block hash or block tag") ||
                err.message.includes("Sorry, Block not found.")) {
                setErrMsg("Sorry, Block not found.")
            } else {
                setErrMsg("Sorry we encountered an error. Please try again later.")
            }
            // console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getBlock();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useParams().blockNum]);

    if (errMsg) {
        return (
            <div className="containerbox">
                <div className="errContainer">
                    <i className='fas fa-exclamation-triangle' style={{ color: 'red', fontSize: '56px', marginTop: '40px' }}></i>
                    <h2 style={{ color: '#403F3F', fontSize: '30px', fontFamily: 'Poppins', marginTop: '20px' }}>Oh Snap!</h2>
                    <div className="errMsg">
                        {errMsg}
                    </div>
                    <button><a target="_blank" rel="noreferrer" href="https://mail.google.com/mail/?view=cm&fs=1&to=team@buildbear.io&su=Reporting Bug&body=Please-report-your-issue-and-provide-the-following-tx-hash-or-address-below">Report</a></button>

                </div>
            </div>
        )
    } else if (loading) {
        return <Spinner animation="grow" style={{ width: '100px', height: '100px', marginLeft: '46vw', marginTop: '23vw' }} />
    }
    else {

        return (
            <>
                <div className="block-wrapper">
                    <h3>Block <span style={{ color: '#808080', fontSize: 'medium' }}>#{intBlockNum}</span></h3>
                    <div className='tx-hr'><hr /></div>
                    <div className="containerbox" style={{ borderRadius: '16px' }}>
                        <AppBar position="static" style={{ backgroundColor: '#E8E8E8', borderRadius: '16px 16px 0 0', boxShadow: 'none' }}>
                            <Tabs value={tab} >
                                <Tab label="Overview" onClick={() => setTab(0)} />
                                {/* {nHash &&  <Tab label="Transactions" onClick={()=>setTab(1)}/> } */}
                            </Tabs>
                        </AppBar>
                        {!tab ? <div className="transactionContainer">
                            {/* <div className="tx-navbar" style={{marginLeft: '-35px', marginTop: '-35px', width: '1300px'}}> */}
                            {/* <div className="tx-navbar" style={{marginLeft: '-35px', marginTop: '-35px'}}>
                            <div>
                                <Link to={`/block/${blockNum}`} >
                                    <h2>Overview</h2>
                                </Link>
                            </div>
                        </div> */}
                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Block Height:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {intBlockNum}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Timestamp:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {timeStamp}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Transactions:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {transactionLength} transactions in this block
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Mined by:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {miner}
                                </div>
                            </div>
                            <hr />


                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Difficulty:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {difficulty}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Gas Used:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {gasUsed}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Extra Data:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', overflowX: 'auto', fontSize: '15px' }}>
                                    {extraData}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Nonce:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {nonce}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Parent Hash:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {parentHash}
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Base Fee Per Gas:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {baseFeePerGas} (Gwei)
                                </div>
                            </div>
                            <hr />


                        </div>
                            : <AllTransactions />}
                    </div>
                </div>
                <Footer />
            </>
        )
    }

}

export default Block
import React, { useContext } from "react";
import { Navigate, Link } from "react-router-dom";
import Styled from "styled-components";
import { AuthContext } from "../home/App";

export default function Navbar() {
  const { state, dispatch } = useContext(AuthContext);

  if (!state.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };
  // console.log("isAdmin", state.isAdmin);
  return (
    <Wrapper>
      <div className="container">
        <div style={{ justifyContent: "right" }} className="navbar">
          <Link className="nav-btn home-btn" to="/">
            <button className="nav-btn">
              Home
            </button>
          </Link>
          {state.isAdmin && (
            <Link className="nav-btn" to="/admin">
              <button className="nav-btn">
                Admin
              </button>
            </Link>
          )}

          <button className="nav-btn" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = Styled.section`
.container{
    display: flex;
    flex-direction: column;
    height: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: Arial;
    .home-btn{
      position: absolute;
      left: 10px;
    }
    button{
      all: unset;
      width: 100px;
      height: 35px;
      margin: 10px;
      background-color: #0F4492;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      transition: 0.06s ease-in;
  
      &:hover{
        background-color: #fff;
        color: #0041C2;
      }
    }
  
    >div{
      height: 100%;
      width: 100%;
      display: flex;
      font-size: 18px;
      justify-content: center;
      align-items: center;
  
      .content{
        display: flex;
        flex-direction: column;
        padding: 20px 100px;    
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        width: auto;
    
        img{
          height: 150px;
          width: 150px;
          border-radius: 50%;
        }
    
        >span:nth-child(2){
          margin-top: 20px;
          font-weight: bold;
        }
    
        >span:not(:nth-child(2)){
          margin-top: 8px;
          font-size: 14px;
        }
    
      }
  
    }
  }
  .endpoint-btn {
    margin-top: 21%;
    margin-left: 45%;
    padding: 15px 20px;
    background-color: #0F4492;
    color: #fff;
    border: none;
    cursor: pointer;
  
  }
  `;

import { ethers } from "ethers";
// import { txResponseToString, blockResponseToString } from "../../utils/JsonConverter";

async function getTxDetails(rpcUrl, txHash) {
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    try {
        let response;
        let txReceipt;
        // if (localStorage.getItem(rpcUrl + '|' + txHash)) {
        //     // Cache exists
        //     response = JSON.parse(localStorage.getItem(rpcUrl + '|' + txHash));
        //     console.log("Found Tx in cache");
        // } else {

        response = await provider.getTransaction(txHash);

        if (!response) {
            throw new Error("invalid hash");
        }
        // try {
        txReceipt = await response.wait();
        // }catch(err) {
        //     // console.log(err);
        //     console.log(err.toString())
        // }
        // response.receipt = {};
        // response.receipt.type = txReceipt?.type;
        // response.receipt.gasUsed = txReceipt?.gasUsed;
        response.receipt = txReceipt;
        // localStorage.setItem(rpcUrl + '|' + txHash, txResponseToString(response));
        // }

        try {
            const code = await provider.getCode(response.to);
            response.isContract = (code > 0);
        } catch (e) {
            // console.log(e)
        }

        // token transfer
        try {
            const erc20TransferEvent = new ethers.utils.Interface([
                "event Transfer(address indexed from, address indexed to, uint256 amount)"
            ]);
            const erc721TransferEvent = new ethers.utils.Interface([
                "event Transfer(address indexed from, address indexed to, uint256 indexed id)"
            ]);

            const erc20TransferTopic = erc20TransferEvent.getEventTopic("Transfer");
            // const erc721TransferTopic = erc721TransferEvent.getEventTopic("Transfer");

            const logsArray = txReceipt.logs

            let erc20tokenTransferArray = [];
            let erc721tokenTransferArray = [];

            logsArray.forEach((e) => {
                if (e.topics[0] === erc20TransferTopic) {
                    try {
                        // console.log(e)
                        const temp = erc20TransferEvent.parseLog(e)
                        const parsedEventData = {
                            from: temp["args"]["from"],
                            to: temp["args"]["to"],
                            amount: (temp["args"]["amount"]).toString(),
                        }
                        erc20tokenTransferArray.push(parsedEventData)
                    } catch (err) {
                        const temp = erc721TransferEvent.parseLog(e)
                        const parsedEventData = {
                            from: temp["args"]["from"],
                            to: temp["args"]["to"],
                            id: (temp["args"]["id"]).toString(),
                        }
                        erc721tokenTransferArray.push(parsedEventData)
                    }
                }
            })

            response.erc20tokenTransferArray = erc20tokenTransferArray;
            response.erc721tokenTransferArray = erc721tokenTransferArray;
            // console.log("Public Response, ethers", response);
        } catch (e) {
            // console.log(e)
        }
        return response;
    } catch (err) {
        let errMsg;
        if (err.message.includes("could not detect network")) {
            errMsg = "Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.";
        } else if (err.message.includes("invalid hash")) {
            errMsg = "This transaction address is not valid. Please recheck it.";
        } else {
            errMsg = "Sorry we encountered an error. Please try again later.";
        }
        // console.log("Err", err);
        return {
            data: null,
            err: {
                msg: errMsg,
            },
        }
    }
}

async function getBlockData(rpcUrl, blockNum) {
    try {
        let block;
        // if (localStorage.getItem(intBlockNum)) {
        //     // cache exists
        //     block = JSON.parse(localStorage.getItem(intBlockNum));
        //     console.log("Found Block in cache");
        // } else {
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        block = await provider.getBlock(blockNum);
        if (!block)
            throw new Error("block not found");
        // localStorage.setItem(blockNum, blockResponseToString(block));
        // }
        // const blockExtraData = block.extraData;
        // const lengtharr = block.transactions.length;
        // const blockMiner = block.miner;
        // const blockDifficulty = block.difficulty;
        // const blockGasUsed = block.gasUsed;
        // let gweiBlockGasUsed;
        // try {
        //     gweiBlockGasUsed = ethers.utils.formatUnits(blockGasUsed, 'gwei')
        // } catch (err) {
        //     gweiBlockGasUsed = "undefined"
        // }
        // let gweibasefee;
        // try {
        //     gweibasefee = ethers.utils.formatUnits(block.baseFeePerGas, 'gwei')
        // } catch (err) {
        //     gweibasefee = "undefined"
        // }
        return block;
    } catch (err) {
        let errMsg;
        if (err.message.includes("could not detect network")) {
            errMsg = "Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.";
        } else if (err.message.includes("block not found") || err.message.includes("invalid block hash or block tag")) {
            errMsg = "Sorry, Block not found.";
        } else {
            errMsg = "Sorry we encountered an error. Please try again later.";
        }
        // console.log("Err", err.message);
        return {
            data: null,
            err: {
                msg: errMsg,
            },
        }
    }
}

export {
    getTxDetails,
    getBlockData,
}
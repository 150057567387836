import { useNavigate, useLocation, useParams } from "react-router-dom"


function useNavigateQuery() {
    const navigate = useNavigate();
    const location = useLocation();
    let { nHash } = useParams();
    const Navigate = (to)=>{
        if( nHash ) {
            if(!to || to?.charAt(0) !== '/') {
                // This is a relative link, let react-router handle it
                navigate(`${to}${location.search}`);
            } else if(to === '/home'){
                // ReRoute home to nodeHome
                navigate(`/${nHash}${location.search}`);
            }
             else {
                // This is absolute link, prepend nodeHash to it
                navigate(`/${nHash}${to}${location.search}`);
            }
        } else {
            navigate(`${to}${location.search}`);
        }
    }

    return Navigate;
}

export default useNavigateQuery;
import { ethers } from "ethers";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BACKEND_URL = `https://backend.${BASE_URL}`;
const RPC_URL = `https://rpc.${BASE_URL}`;
const EXPLORER_URL = `https://explorer.${BASE_URL}`;


function getNodeRPC(nodeHash) {
    const rpcUrl = `${RPC_URL}/${nodeHash}`;
    return rpcUrl;
}
function getExplorerUrl(nodeHash) {
    const explorerUrl = `${EXPLORER_URL}/${nodeHash}`;
    return explorerUrl;
}

async function requestCurrency(nodeHash, address, amount) {
    try {
        const url = `${BACKEND_URL}/node/faucet/native/${nodeHash}`

        await fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
            },
            body: `{
                "address": "${address}",
                "balance": "${amount}"
            }`
        })

        const rpc = getNodeRPC(nodeHash);
        const provider = new ethers.providers.JsonRpcProvider(rpc);

        const balance = ethers.utils.formatEther(await provider.getBalance(address));

        return {
            balance
        }
    } catch (_) {
        return {
            err: "error"
        }
    }
}

function getTokenContract(token, provider) {
    const erc20Abi = [
        "function decimals() view returns (uint8)",
        "function balanceOf(address owner) view returns (uint256)",
    ];
    return new ethers.Contract(token, erc20Abi, provider);
}

async function requestCurrencyERC20(nodeHash, address, amount, token) {
    try {
        const url = `${BACKEND_URL}/node/faucet/erc20/${nodeHash}`

        await fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
            },
            body: `{
                "address": "${address}",
                "token": "${token}",
                "balance": "${amount}"
            }`
        });

        const rpc = getNodeRPC(nodeHash);
        const provider = new ethers.providers.JsonRpcProvider(rpc);

        const tokenContract = getTokenContract(token, provider);
        const balance = ethers.utils.formatUnits(await tokenContract.balanceOf(address), await tokenContract.decimals());

        return {
            balance
        }
    } catch (_) {
        return {
            err: "error"
        }
    }
}

export {
    requestCurrency,
    requestCurrencyERC20,
    getNodeRPC,
    getExplorerUrl,
}
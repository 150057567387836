import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import "../styles/Node.css";
import { useState } from "react";
import Navbar from "../components/Navbar";

export default function Home() {
  const navigate = useNavigate();
  const [nodeHash, setNodeHash] = useState("");
  return (
    <>
      <Navbar />
      <Box className="wrapper">
        <Stack
          className="faucet-container"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ height: "75vh", fontSize: '16px' }}
        >
          <Typography variant="h3" style={{ fontSize: '40px', textAlign: 'center', }}><h2>BuildBear Faucet</h2></Typography>
          {/* <br /> */}
          <Typography style={{ fontSize: '24px' }}>{`Enter Node Hash`}</Typography>
          <TextField
            style={{ backgroundColor: '#E8E8E8', width: '400px', borderRadius: '8px', color: '#000', padding: '8px 10px', placeholder: '#000' }}
            label=""
            placeholder="Input Node Hash here"
            variant="standard"
            value={nodeHash}
            sx={{
              minWidth: '50ch',
            }}
            onChange={(e) => {
              setNodeHash(e.target.value);
            }}
          />
          <LoadingButton className="submit-button" color="primary" variant="outlined" onClick={() => {
            navigate(`/${nodeHash}`);
          }}>
            SUBMIT
          </LoadingButton>
          <br />
        </Stack>
      </Box>
    </>
  )
}

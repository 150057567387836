import Navbar from "../components/HomeNavbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
function PageNotFound() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ m: 5 }}>
        <br/>
        <br/>
        <Typography variant="h3" color="white" textAlign={"center"}>
          Page not Found
        </Typography>
          <Link to="">
            <Typography variant="h6" color="white" textAlign={"center"}>
                Go to home
            </Typography>
          </Link>
      </Box>
    </Box>
  );
}

export default PageNotFound;

import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  progressPercentage: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel(props) {
  return (
    <Box sx={{ width: "100%", mt:3 }}>
      {props.progressPercentage === 100 ? (
        <Box sx={{ display: "flex", alignItems: "center", mx:2 }}>
          <Box sx={{ mr: 2 }}>
            Parsing Complete
          </Box>
          <Box sx={{ minWidth: 35, color: 'green' }}>
            <CheckCircleIcon/>
          </Box>
        </Box>
      ) : (
        <>
          Parsing Your Files...
          <LinearProgressWithLabel value={props.progressPercentage} />
        </>
      )}
    </Box>
  );
}
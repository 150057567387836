import { useParams } from "react-router-dom"
import { getBlockData as getNodeBlockDetails } from "../api/customNodeExplorer";
import { getBlockData as getPublicBlockDetails } from "../api/publicExplorer";
import { useContext } from "react";
import { AppContext } from "../eth-scan/App";

function useBlock() {
    let { nHash } = useParams();
    const { state } = useContext(AppContext);
    async function getBlockDetails(blockNum) {
        if (nHash) {
            return await getNodeBlockDetails(nHash, blockNum);
        } else {
            return await getPublicBlockDetails(state.rpcUrl, blockNum);
        }
    }

    return getBlockDetails;
}

export default useBlock;
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../pages/404";
import "./App.css";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Landing from "../Landing";
import TermsOfService from "../pages/TermsOfService";


function LandingPage() {
  return (
    <>
      <Helmet>
        <title>BuildBear</title>
      </Helmet>

      <Routes>
        <Route path="/*" element={<Landing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default LandingPage;

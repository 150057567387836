import { ethers } from "ethers";
import { useEffect, useState } from "react";
import "../../../eth-scan/style.css";
// import { Spinner } from 'react-bootstrap';
import { Tabs, Tab, Box, AppBar } from "@mui/material";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../eth-scan/App";
import { useLocation } from "react-router-dom";
// import TransactionList from "./TransactionList";
import {
    getAbi,
    getAbiFromBackend,
    getSourceFile,
    getVulnerability,
} from "../../../api/explorer";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import ContractTab from "../../../components/Contract/ContractTab";
import Footer from "../../../components/Footer";
import AllTransactions from "../../../components/node/allTransactions";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useNavigateQuery from "../../../hooks/useNavigateQuery";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={{ width: "100% !important" }}
            {...other}
        >
            {value === index && <Box sx={{ py: 4 }}>{children}</Box>}
        </Box>
    );
}
function a11yProps(index) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

async function checkProxy(
    address,
    provider,
    setProxyContractAddress,
    setProxyContractLoadedOnce,
    proxyContractAbi,
    setProxyContractAbi,
    nHash,
    rpcUrl,
    setProxyContractInstance
) {
    const abi = [
        "function proxyType() view returns (uint256)",
        "function implementation() view returns (address)",
    ];
    const contract = new ethers.Contract(address, abi, provider);

    try {
        const proxyType = await contract.proxyType();
        if (proxyType.toNumber() === 1 || proxyType.toNumber() === 2) {
            const implementation = await contract.implementation();
            setProxyContractAddress(implementation);
            // console.log("It is a proxy")

            if (nHash) {
                setProxyContractAbi(
                    await getAbiFromBackend(nHash, implementation)
                );
            } else {
                setProxyContractAbi(
                    await getAbi(nHash, implementation, rpcUrl)
                );
            }

            if (proxyContractAbi) {
                setProxyContractInstance(
                    new ethers.Contract(address, proxyContractAbi, provider)
                );
            }
        }
    } catch (_) {
        try {
            let result = await provider.getStorageAt(
                contract.address,
                "0x7050c9e0f4ca769c69bd3a8ef740bc37934f8e2c036e5a723fd8ee048ed3f8c3"
            ); // org.zeppelinos.proxy.implementation
            let implementation = "0x" + result.slice(-40);

            if (
                implementation === "0x0000000000000000000000000000000000000000"
            ) {
                result = await provider.getStorageAt(
                    contract.address,
                    "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc"
                ); // eip1967.proxy.implementation - 1
                implementation = "0x" + result.slice(-40);
            }

            // console.log("This is the implementation address: ", implementation);

            if (
                implementation === "0x0000000000000000000000000000000000000000"
            ) {
                throw new Error("Not a proxy");
            }

            setProxyContractAddress(implementation);
            // console.log("It is a proxy")

            if (nHash) {
                setProxyContractAbi(
                    await getAbiFromBackend(nHash, implementation)
                );
            } else {
                setProxyContractAbi(
                    await getAbi(nHash, implementation, rpcUrl)
                );
            }

            if (proxyContractAbi) {
                setProxyContractInstance(
                    new ethers.Contract(address, proxyContractAbi, provider)
                );
            }
        } catch (_) {
            // console.log("Not a proxy");
        }
    }

    setProxyContractLoadedOnce(true);
}

const Contract = () => {
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const [abi, setAbi] = useState(null);
    const [contractInstance, setContractInstance] = useState();
    const addressHash = useParams().addressHash;
    const { state } = useContext(AppContext);
    let { nHash } = useParams();
    const rpcUrl = state.rpcUrl;
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

    const [balance, setBalance] = useState();
    const [transactionCount, setTransactionCount] = useState();

    // Tabs
    const [readFunctions, setReadFunctions] = useState([]);
    const [readLoadedOnce, setReadLoadedOnce] = useState(false);

    const [writeFunctions, setWriteFunctions] = useState([]);
    const [writeLoadedOnce, setWriteLoadedOnce] = useState(false);

    const [proxyContractAddress, setProxyContractAddress] = useState(null);
    const [proxyContractLoadedOnce, setProxyContractLoadedOnce] =
        useState(false);
    const [proxyContractAbi, setProxyContractAbi] = useState(null);
    const [proxyContractInstance, setProxyContractInstance] = useState();

    const [readProxyFunctions, setReadProxyFunctions] = useState([]);
    const [readProxyLoadedOnce, setReadProxyLoadedOnce] = useState(false);

    const [writeProxyFunctions, setWriteProxyFunctions] = useState([]);
    const [writeProxyLoadedOnce, setWriteProxyLoadedOnce] = useState(false);

    const [soliditySource, setSoliditySource] = useState();
    const [vulnerability, setVulnerability] = useState();
    const [totalVulnerability, setTotalVulnerability] = useState();
    const [score, setscroe] = useState();
    // console.log("testing ", vulnerability?.scan_report?.scan_summary?.issue_severity_distribution)
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (vulnerability !== undefined) {
            const v1 =
                vulnerability?.scan_report?.scan_summary
                    ?.issue_severity_distribution.critical;
            const v2 =
                vulnerability?.scan_report?.scan_summary
                    ?.issue_severity_distribution.gas;
            const v3 =
                vulnerability?.scan_report?.scan_summary
                    ?.issue_severity_distribution.high;
            const v4 =
                vulnerability?.scan_report?.scan_summary
                    ?.issue_severity_distribution.informational;
            const v5 =
                vulnerability?.scan_report?.scan_summary
                    ?.issue_severity_distribution.low;
            const v6 =
                vulnerability?.scan_report?.scan_summary
                    ?.issue_severity_distribution.medium;
            const s = vulnerability?.scan_report?.scan_summary?.score;
            const sum = v1 + v2 + v3 + v4 + v5 + v6;
            console.log(sum);
            setscroe(s);
            setTotalVulnerability(sum);
        }
    }, [vulnerability]);

    useEffect(() => {
        if (!proxyContractLoadedOnce) {
            checkProxy(
                addressHash,
                provider,
                setProxyContractAddress,
                setProxyContractLoadedOnce,
                proxyContractAbi,
                setProxyContractAbi,
                nHash,
                rpcUrl,
                setProxyContractInstance
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressHash, provider]);

    const [tab, setTab] = useState(0);
    const contractParam = useParams()["*"];
    const location = useLocation();

    console.log("?",location.hash)
    // const initTab =
    //     contractParam === "" ? 0 : contractParam === "transactions" ? 0 : 1;
    const initTab = location.hash === "" ? 0 : 1;
    const [value, setValue] = useState(initTab);
    // const navigate = useNavigate();
    const navigateQuery = useNavigateQuery();
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            navigateQuery("");
        } else {
            
            navigateQuery("#code");
            
        }
    };


    

    useEffect(async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const bytecode = await provider.getCode(addressHash);
            if (bytecode) {
                const res = await getSourceFile(nHash, addressHash);
                if (res.status === 200) {
                    const resVul = await getVulnerability(nHash, addressHash);
                    if (resVul.status === 200) {
                        setVulnerability(resVul.data);
                        setLoader(false);
                        // console.log("vulnerability: ", resVul.data)
                        setError(false);
                    } else {
                        setLoader(false);
                        setError(true);
                    }
                    setSoliditySource(res.data.result[0].SourceCode);
                } else {
                    setError(true);
                    setLoader(false);
                }
            } else {
                setError(true);
                setLoader(false);
            }
        } catch (e) {
            // console.log(e);
        }

        // console.log("contract ", )
        // setSoliditySource(solidity.result[0].SourceCode)
        // console.log(">>source code", res.data.result[0].SourceCode)
    }, []);

    async function getAddressData() {
        setLoading(true);
        setErrMsg(null);
        if (nHash) {
            const res = await getSourceFile(nHash, addressHash);
            // console.log("res: ", res.data.result)
            if (res.status === 200) {
                if (res.data.result[0].ABI) {
                    setAbi(res.data.result[0].ABI);
                    // console.log("ABI from backend: ", res.data);
                }
            } else {
                setAbi(await getAbiFromBackend(nHash, addressHash));
            }
        } else {
            setAbi(await getAbi(nHash, addressHash, rpcUrl));
        }
        try {
            // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const balance = await provider.getBalance(addressHash);
            const txCount = await provider.getTransactionCount(addressHash);
            const gweiBalance = ethers.utils.formatUnits(balance, "ether");
            var roundedBal = Math.round(gweiBalance * 1000) / 1000;
            setBalance(roundedBal);
            setTransactionCount(txCount);
        } catch (err) {
            if (err.message.includes("could not detect network")) {
                setErrMsg(
                    "Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url."
                );
            } else if (err.message.includes("bad address checksum")) {
                setErrMsg("Sorry, Address not found.");
            } else {
                setErrMsg(
                    "Sorry we encountered an error. Please try again later."
                );
            }
            // console.log(err.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (abi) {
            if (!contractInstance) {
                generateContractInstance();
            }
        } else {
            // console.log("Abi does not exist for this contract.");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [abi]);

    async function generateContractInstance() {
        if (abi) {
            setContractInstance(
                new ethers.Contract(addressHash, abi, provider)
            );
        }
    }

    useEffect(() => {
        getAddressData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useParams().addressHash]);

    if (errMsg) {
        return (
            <div className="containerbox">
                <div className="errContainer">
                    <i
                        className="fas fa-exclamation-triangle"
                        style={{
                            color: "red",
                            fontSize: "56px",
                            marginTop: "40px",
                        }}
                    ></i>
                    <h2
                        style={{
                            color: "#403F3F",
                            fontSize: "30px",
                            fontFamily: "Poppins",
                            marginTop: "20px",
                        }}
                    >
                        Oh Snap!
                    </h2>
                    <div className="errMsg">{errMsg}</div>
                    <button>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://mail.google.com/mail/?view=cm&fs=1&to=team@buildbear.io&su=Reporting Bug&body=Please-report-your-issue-and-provide-the-following-tx-hash-or-address-below"
                        >
                            Report
                        </a>
                    </button>
                </div>
            </div>
        );
    } else if (loading) {
        // TODO: Fix Spinner not visible
        return (
            <>
                {/* <Spinner animation="grow" style={{zIndex: '100', color: 'white'}} /> */}
                <div
                    style={{
                        color: "black",
                        textAlign: "center",
                        padding: "400px 0",
                    }}
                >
                    Please wait. BuildBear Explorer is loading
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="contract-wrapper">
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                        <h3>
                            Contract{" "}
                            <span
                                style={{ color: "#808080", fontSize: "medium" }}
                            >
                                {addressHash}
                            </span>
                        </h3>
                        {/* <CopyToClipboard text={addressHash}><i style={{ cursor: 'pointer', padding: '12px' }} className="fa fa-clipboard"></i></CopyToClipboard> */}
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigator.clipboard.writeText(addressHash);
                            }}
                        >
                            <ContentCopyIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                    </div>
                    <div className="tx-hr">
                        <hr />
                    </div>
                    <div
                        className="containerbox"
                        style={{ borderRadius: "16px" }}
                    >
                        <AppBar
                            position="static"
                            style={{
                                backgroundColor: "#E8E8E8",
                                boxShadow: "none",
                                borderRadius: "16px 16px 0 0",
                            }}
                        >
                            <Tabs value={tab}>
                                <Tab
                                    label="Overview"
                                    onClick={() => setTab(0)}
                                />
                                {/* {nHash &&  <Tab label="Transactions" onClick={()=>setTab(1)}/> } */}
                            </Tabs>
                        </AppBar>

                        {/* {!tab ?     */}
                        <div className="transactionContainer">
                            {/* <div className="tx-navbar" style={{ marginLeft: '-35px', marginTop: '-35px', width: '1300px' }}> */}
                            {/* <div className="tx-navbar" style={{ marginLeft: '-35px', marginTop: '-35px' }}>
                            <div>
                                <Link to={`/address/${addressHash}`} >
                                    <h2>Overview</h2>
                                </Link>
                            </div>
                        </div> */}
                            {/* <div className="row" style={{ paddingTop: '45px' }}>
                            <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                <b>
                                    RPC URL:
                                </b>
                            </div>
                            <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                {rpcUrl}
                            </div>
                        </div>
                        <hr /> */}

                            {/* <div className="row">
                                <div className="title" style={{ width: '300px', fontSize: '15px' }}>
                                    <b>
                                        Address:
                                    </b>
                                </div>
                                <div className="value" style={{ width: '700px', fontSize: '15px' }}>
                                    {addressHash}
                                </div>
                            </div>
                            <hr /> */}

                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Balance:</b>
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "15px" }}
                                >
                                    {balance} BB ETH
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div
                                    className="title"
                                    style={{ width: "300px", fontSize: "15px" }}
                                >
                                    <b>Transaction Count:</b>
                                </div>
                                <div
                                    className="value"
                                    style={{ width: "700px", fontSize: "15px" }}
                                >
                                    {transactionCount}
                                </div>
                            </div>
                            <hr />

                            <>
                                <div className="row">
                                    <div
                                        className="title"
                                        style={{
                                            width: "300px",
                                            fontSize: "15px",
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                        }}
                                    >
                                        <b>Vulnerabilities:</b>
                                    </div>
                                    <div
                                        className="value"
                                        style={{ fontSize: "15px" }}
                                    >
                                        {vulnerability?.scan_report
                                            ?.scanner_reference_url ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    width: "700px",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        >
                                                            Vulnerability Count:
                                                        </div>
                                                        <div>
                                                            <a
                                                                style={{
                                                                    marginLeft:
                                                                        "9px",
                                                                    textDecorationColor:
                                                                        "blue",
                                                                }}
                                                                target="_blank"
                                                                href={
                                                                    vulnerability
                                                                        ?.scan_report
                                                                        ?.scanner_reference_url
                                                                }
                                                            >
                                                                {
                                                                    totalVulnerability
                                                                }{" "}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: "30px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        >
                                                            Solidity Score :
                                                        </div>
                                                        <div>
                                                            <a
                                                                style={{
                                                                    marginLeft:
                                                                        "9px",
                                                                    textDecorationColor:
                                                                        "blue",
                                                                }}
                                                                target="_blank"
                                                                href={
                                                                    vulnerability
                                                                        ?.scan_report
                                                                        ?.scanner_reference_url
                                                                }
                                                            >
                                                                {score}{" "}
                                                            </a>
                                                            <span>/ 5</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        padding: "5px",
                                                        background: "white",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                        }}
                                                    >
                                                        Powered By
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <svg
                                                            width="20"
                                                            height="16.25"
                                                            viewBox="0 0 32 29"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1.556 2.056v7.777h3.11V3.611h6.223V.5H3.11a1.556 1.556 0 00-1.555 1.556zm28 7.777V2.056A1.555 1.555 0 0028 .5h-7.778v3.111h6.222v6.222h3.112zM26.444 25.39h-6.222V28.5H28a1.555 1.555 0 001.556-1.556v-7.777h-3.112v6.222zM10.89 28.5v-3.111H4.667v-6.222H1.556v7.777A1.556 1.556 0 003.11 28.5h7.778zM0 12.944h31.111v3.112H0v-3.112z"
                                                                fill="url(#_cnfa3w633)"
                                                            ></path>
                                                            <defs>
                                                                <linearGradient
                                                                    id="_cnfa3w633"
                                                                    x1="3"
                                                                    y1="2.5"
                                                                    x2="29.5"
                                                                    y2="26.5"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop stop-color="#52FF00"></stop>
                                                                    <stop
                                                                        offset="1"
                                                                        stop-color="#00EEFD"
                                                                    ></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "5px",
                                                                fontWeight:
                                                                    "bold",
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            SolidityScan
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div>
                                                    {loader ? "Loading..." : ""}
                                                </div>
                                                <div>
                                                    {error
                                                        ? "Vulnerability details not available"
                                                        : ""}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr />
                            </>
                        </div>
                        {/* // : <AllTransactions />} */}
                    </div>
                    <div
                        className="contractfunction-wrapper"
                        style={{ backgroundColor: "#F4F4F2" }}
                    >
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                width: "100%",
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="basic tabs example"
                            >
                                <Tab label="Transactions" {...a11yProps(0)} />
                                <Tab label="Contract" {...a11yProps(1)} />
                                {/* <Tab label="Events" {...a11yProps(2)} disabled /> */}
                            </Tabs>
                        </Box>
                        <TabPanel
                            value={value}
                            index={0}
                            sx={{ width: "100%" }}
                        >
                            <AllTransactions />
                        </TabPanel>
                        <TabPanel
                            value={value}
                            index={1}
                            sx={{ width: "100%" }}
                        >
                            <ContractTab
                                abi={abi}
                                setAbi={setAbi}
                                contractInstance={contractInstance}
                                readFunctions={readFunctions}
                                setReadFunctions={setReadFunctions}
                                readLoadedOnce={readLoadedOnce}
                                setReadLoadedOnce={setReadLoadedOnce}
                                writeFunctions={writeFunctions}
                                setWriteFunctions={setWriteFunctions}
                                writeLoadedOnce={writeLoadedOnce}
                                setWriteLoadedOnce={setWriteLoadedOnce}
                                proxyContractAddress={proxyContractAddress}
                                proxyContractAbi={proxyContractAbi}
                                proxyContractInstance={proxyContractInstance}
                                readProxyFunctions={readProxyFunctions}
                                setReadProxyFunctions={setReadProxyFunctions}
                                readProxyLoadedOnce={readProxyLoadedOnce}
                                setReadProxyLoadedOnce={setReadProxyLoadedOnce}
                                writeProxyFunctions={writeProxyFunctions}
                                setWriteProxyFunctions={setWriteProxyFunctions}
                                writeProxyLoadedOnce={writeProxyLoadedOnce}
                                setWriteProxyLoadedOnce={
                                    setWriteProxyLoadedOnce
                                }
                                soliditySource={soliditySource}
                            />
                        </TabPanel>
                        <TabPanel
                            value={value}
                            index={2}
                            sx={{ width: "100%" }}
                        >
                            Coming Soon...
                        </TabPanel>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
};

export default Contract;

import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom"
import { getApp } from './utils/helpers';
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

const GTAG = process.env.REACT_APP_GTAG;


// const TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID
// console.log(GTAG)
ReactGA.initialize(GTAG);


function App() {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Landing Page" });
  // }, []);
  const CurrentApp = getApp();
  return (
    <BrowserRouter>
      <CurrentApp />
      {/* <Routes>
          <Route path="/home/*" element={<Home />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/explorer/*" element={<Explorer />} />
          <Route path="/faucet/*" element={<Faucet />} />
        </Routes> */}
    </BrowserRouter>
  );
}

export default App;
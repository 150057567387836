import { Outlet } from "react-router-dom";
import Navbar from "../../components/ExplorerNavbar";
import useRequireRpcParam from "../../hooks/useRequireRpcParam";

function Search() {
  useRequireRpcParam();
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}

export default Search;

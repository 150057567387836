import React from 'react'
import '../styles/Footer.css';
import linkedin from "../landing/assets/linkedin.svg";
import mail from "../landing/assets/mail.svg";
import pp from "../landing/assets/pp.svg";
import telegram from "../landing/assets/telegram.svg";
import tos from "../landing/assets/tos.svg";
import twitter from "../landing/assets/twitter.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import ReactGA from 'react-ga4';


const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'rgba(255,255,255,0.87) !important',
    fontSize: 13,
  },

}));

const twitterEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Twitter Link',
  });
};
const telegramEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Telegram Link',
  });
};
const linkedinEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Linkedin Link',
  });
};
const mailEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Mail Link',
  });
};
const ppEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Privacy Policy',
  });
};
const tosEvent = () => {
  ReactGA.event({
    category: 'BuildBear category',
    action: 'Terms of Service',
  });
};

function LandingFooter() {
  return (
    <>
      {/* <hr style={{ width: '100%', position: 'relative', bottom: '10px' }}></hr> */}
      <div className="bottom">
        <div className="legal">
          <DarkToolTip
            title={`Privacy Policy`}
            arrow
            placement="top"
          >
            <a onClick={ppEvent}>
              <a target="_blank" href="/privacy-policy"><img style={{ width: '2.3rem' }} src={pp} alt="PP Logo" /></a>
            </a>
          </DarkToolTip>
          <DarkToolTip
            title={`Terms of Service`}
            arrow
            placement="top"
          >
            <a onClick={tosEvent}>
              <a target="_blank" href="/terms-of-service"><img style={{ width: '2.3rem' }} src={tos} alt="ToS Logo" /></a>
            </a>
          </DarkToolTip>
        </div>
        <div className="social">
          <DarkToolTip
            title={`Twitter`}
            arrow
            placement="top"
          >
            <a onClick={twitterEvent}>
              <a target="_blank" href="https://twitter.com/_BuildBear"><img style={{ width: '2.3rem' }} src={twitter} alt="Twitter Logo" /></a>
            </a>
          </DarkToolTip>
          <DarkToolTip
            title={`Telegram`}
            arrow
            placement="top"
          >
            <a onClick={telegramEvent}>
              <a target="_blank" href="https://t.me/BuildBearLabs"><img style={{ width: '2.3rem' }} src={telegram} alt="Telegram Logo" /></a>
            </a>
          </DarkToolTip>
          <DarkToolTip
            title={`Linkedin`}
            arrow
            placement="top"
          >
            <a onClick={linkedinEvent}>
              <a target="_blank" href="https://www.linkedin.com/company/build-bear/"><img style={{ width: '2.3rem' }} src={linkedin} alt="Linkedin Logo" /></a>
            </a>
          </DarkToolTip>
          <DarkToolTip
            title={`Mail`}
            arrow
            placement="top"
          >
            <a onClick={mailEvent}>
              <a target="_blank" href="mailto:team@buildbear.io"><img style={{ width: '2.3rem' }} src={mail} alt="Mail Logo" /></a>
            </a>
          </DarkToolTip>
        </div>
      </div>
    </>
  )
}

export default LandingFooter
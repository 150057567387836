import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Tabs, Tab, AppBar, Typography } from '@mui/material'
import Transaction from '../pages/Search/Transaction';
import Logs from './Transaction/Logs';
import ConsoleLog from './Transaction/ConsoleLog';
import Trace from './Trace';
import Footer from '../components/Footer';
import '../styles/TransactionDetails.css'
import { useTransaction, getNodeDetails, getEthersDetails } from "../hooks/useTransaction";
import WarningIcon from '@mui/icons-material/Warning';

import { ethers } from "ethers"
import axios from 'axios';
import { RPC_URL } from "../api/configs"

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && (<h1>{children}</h1>)}
        </div>
    )
}
const TransactionDetails = () => {
    // tabs
    const [tabVal, setTabVal] = useState(0)
    const handleTabs = (e, val) => {
        setTabVal(val)
    }

    let { nHash } = useParams();

    const txHash = useParams().txHash;
    const [txDetails, setTxDetails] = useState({});
    const getTxDetails = useTransaction();

    const [nodeDetails, setNodeDetails] = useState({});

    const [oldTx, setOldTx] = useState(false);

    const [trace, setTrace] = useState([]);
    const [loading, setLoading] = useState({
        text: "Loading Contract Information...",
        dots: 3,
        state: true
    });

    const [waitTrans, setWaitTrans] = useState({})
    const [etTrans, setEtTrans] = useState({})
    const [toggleTxs, setToggleTxs] = useState(true)
    const [resRpc, setResRpc] = useState({})
    const [date, setDate] = useState()
    const networkRpc = {
        1: ["Ethereum Mainnet", "https://rpc.ankr.com/eth"],
        56: ["Binance Smart Chain", "https://rpc.ankr.com/bsc"],
        137: ["Polygon Mainnet", "https://rpc.ankr.com/polygon"],
        80001: ["Polygon Testnet", "https://rpc.ankr.com/polygon_mumbai"],
        5: ["Goerli Testnet", "https://rpc.ankr.com/eth_goerli"],
        11155111: ["Sepolia Testnet", "https://rpc.sepolia.org/"],
        10: ["Optimism Mainnet", "https://mainnet.optimism.io"],
        42161: ["Arbitrum Mainnet", "https://arb1.arbitrum.io/rpc"],
        421613: ["Arbitrum Goerli", "https://goerli-rollup.arbitrum.io/rpc"],
        250: ["Fantom Mainnet", "https://rpc.fantom.network"],
        4002: ["Fantom Testnet", "https://rpc.ankr.com/fantom_testnet"],
    }

    const [errMsg, setErrMsg] = useState(false);
    const faucetFrom = "0xC014BA5EC014ba5ec014Ba5EC014ba5Ec014bA5E"

    useEffect(async () => {
        const url = window.location.href;
        const word = url.split('/');
        const urlTxHash = word[word.length - 1]
        const testNetName = word[word.length - 3]

        const result = await axios.get(`${RPC_URL}/${testNetName}`);
        const response = result.data;
        // console.log("??RPC" , JSON.stringify(response))
        setResRpc(response);
        if (resRpc.blockNumber && resRpc.chainId) {
            const provider = new ethers.providers.JsonRpcProvider(networkRpc[resRpc.chainId][1])
            const txWfTx = await provider.waitForTransaction(txHash);
            const txInfo = await provider.getTransaction(urlTxHash)
            setWaitTrans(txWfTx)
            // setEtTrans(txInfo)
            // console.log(">>>"+txWfTx)
            // const blockInfoEthers = await provider.getBlock(
            //     txWfTx.blockNumber
            // );
            // const date = new  Date(blockInfoEthers.timestamp * 1000)
            // const stringDate = date.toLocaleString()
            // setDate(stringDate)


        }
    }, [])

    useEffect(async () => {
        try {
            const response = await getTxDetails(txHash);
            // console.log(JSON.stringify(response.data))
            setErrMsg(false)

            if (response.data !== null) {
                setErrMsg(false)
                setOldTx(false)
                // console.log("data prsent")
                setLoading({ ...loading, state: true })

                setTxDetails(response);
            } else if (resRpc.blockNumber && resRpc.chainId) {


                setErrMsg(false)
                setLoading({ ...loading, state: false })

                const res = await getEthersDetails(networkRpc[resRpc.chainId][1], txHash)
                if (resRpc.blockNumber > res.blockNumber) {
                    setTxDetails(res)

                } else {
                    setErrMsg(true)
                }


                // console.log("not present")
            } else {
                // setTimeout(()=>{
                setErrMsg(true)
                // }, 1000)
                // set
                // transaction is not valid or not found
            }
        } catch (error) {
            // console.log(error);
        }
    }, [txHash, resRpc])

    // useEffect(async () => {
    //     try {
    //         const response = await getTxDetails(txHash);
    //         setTxDetails(response);
    //     } catch (error) {
    //         // console.log(error);
    //     }
    // }, [txHash])

    useEffect(async () => {
        const response = await getNodeDetails(nHash);
        setNodeDetails(response);
    }, [nHash])

    useEffect(() => {
        if (nodeDetails && txDetails && nodeDetails.blockNumber && txDetails.blockNumber && nodeDetails.blockNumber >= txDetails.blockNumber) {
            setOldTx(true);
            setLoading({ ...loading, state: false })
        }
    }, [nodeDetails, txDetails])

    return (
        <>
            <div className='txDetails-wrapper'>

                <div><h4>Transaction Details</h4></div>
                {
                    oldTx ?
                        <div className='containerboxmod' style={{ paddingBottom: '0', paddingTop: '0px', borderRadius: '16px' }}>
                            <WarningIcon style={{ height: "24px", margin: '10px 0px 10px 10px' }} />
                            <Typography variant="h1" component="h2" style={{ margin: '10px 10px 0px 10px' }}>
                                As this is a transaction that was executed outside of the private testnet, we can only provide limited data
                            </Typography>
                        </div>
                        : null}
                {
                    oldTx ?
                        <br />
                        : null
                }
                {
                    errMsg ? <div className="containerbox" style={{ zIndex: 2, position: "absolute" }}>
                        <div className="errContainer">
                            <i className='fas fa-exclamation-triangle' style={{ color: 'red', fontSize: '56px', marginTop: '40px' }}></i>
                            <h2 style={{ color: '#403F3F', fontSize: '30px', fontFamily: 'Poppins', marginTop: '20px' }}>Oh Snap!</h2>
                            <div className="errMsg">
                                Transaction is not valid or not found !
                            </div>

                        </div>
                    </div> : <></>
                }
                {/* <div className='tx-hr'><hr /></div> */}
                <div className='containerbox' style={{ paddingBottom: '0', paddingTop: '0px', borderRadius: '16px' }}>
                    <AppBar position="static" style={{ backgroundColor: '#E8E8E8', boxShadow: 'none', borderRadius: '16px 16px 0 0' }}>
                        <Tabs value={tabVal} onChange={handleTabs} >
                            <Tab label="Overview" />
                            <Tab label="Events" />
                            {oldTx ? <></> :
                                <Tab label="Trace" />}
                            {/* <Tab label="Console Log" /> */}
                        </Tabs>
                    </AppBar>
                </div>
                <TabPanel value={tabVal} index={0}> <Transaction txDetails={txDetails} /></TabPanel>
                <TabPanel value={tabVal} index={1}><Logs txDetails={txDetails} /> </TabPanel>
                <TabPanel value={tabVal} index={2}><Trace
                    nodeDetails={nodeDetails}
                    txDetails={txDetails}
                    trace={trace}
                    setTrace={setTrace}
                    loading={loading}
                    setLoading={setLoading}
                />  </TabPanel>
                {/* <TabPanel value={tabVal} index={3}><ConsoleLog /> </TabPanel> */}
            </div>
            <Footer />
        </>

    )
}

export default TransactionDetails
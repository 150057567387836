import React from "react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { Tooltip } from "@mui/material";

const bbToken = "0xC014BA5EC014ba5ec014Ba5EC014ba5Ec014bA5E";

const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        color: "rgba(255,255,255,0.87) !important",
        fontSize: 13,
        minWidth: "345px",
    },
}));

const TokenToolTip = (props) => {
    if (props.value === bbToken) {
        return (
            // <p style={props.styles} >bbToken</p>
            <>
                <DarkToolTip title={`${props.value}`} arrow placement="bottom">
                    <p className={`${props.class}`} style={{ pading: "0px" }}>
                        Buildbear Faucet
                    </p>
                </DarkToolTip>
            </>
        );
    } else {
        return (
            <>
                <p style={props.styles} className={`${props.class}`}>
                    {props.value}
                </p>
            </>
        );
    }
};

export default TokenToolTip;

import { useSearchParams, useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getDefaultNetworks, getNodeUrl } from "../api/explorer";
import { AppContext } from "../eth-scan/App";
import { useContext } from "react";

function useRequireRpcParam() {
    let [searchParams] = useSearchParams();
    const { dispatch } = useContext(AppContext);
    const { nHash } = useParams();
    const networks = getDefaultNetworks();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // console.log("nHash", nHash);
        if (nHash) {
            dispatch({
                type: "SET_RPC",
                payload: { rpcUrl: getNodeUrl(nHash) },
            });
        }
        else if (searchParams.has("chainId")) {
            // console.log("chainId exists in param");
            const chainId = decodeURIComponent(searchParams.get("chainId"));
            // console.log("chainId", chainId);
            const index = networks.findIndex((networkObj) => {
                return networkObj.chainId === chainId;
            })
            if (index !== -1) {
                localStorage.setItem("networkType", networks[index].name);

                // Got valid chain Id, so overwrite any rpc
                // or extra query that exist in url
                navigate(`${location.pathname}?chainId=${chainId}`);
            } else {
                // We got an Invalid Chain Id, thus have to go to
                // rpc input page to get a chain Id
                // console.log("Invalid Chain ID");
                navigate(`/?returnPath=${encodeURIComponent(location.pathname)}`);
            }
        } else if (searchParams.has("rpc")) {
            // console.log("Rpc exists in param");
            const rpcUrl = decodeURIComponent(searchParams.get("rpc"));
            const index = networks.findIndex((networkObj) => {
                return networkObj.rpc === rpcUrl;
            })
            if (index !== -1) {
                localStorage.setItem("networkType", networks[index].name);
            } else {
                localStorage.setItem("networkType", "Custom");
            }
            // dispatch({
            //     type: "SET_RPC",
            //     payload: { rpcUrl: rpcUrl },
            // });
        } else {
            // console.log("Rpc does not exist in param, goto rpc choice page");
            navigate(`/?returnPath=${encodeURIComponent(location.pathname)}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return searchParams;
}

export default useRequireRpcParam;
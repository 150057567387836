import axios from "axios";
import { ethers } from "ethers";
import { getBackendBaseUrl, getRpcUrl } from "../../api/explorer";

async function getTxDetails(nodeHash, txHash) {
    try {
        const axiosRes = await axios.get(`${getBackendBaseUrl()}/node/transaction/${nodeHash}/${txHash}`);
        const response = axiosRes.data.transaction;
        // console.log("Response from backend", response);


        // Check if is contract, 
        // Not saving address details or other stuff in db, hence query directly from node
        const provider = new ethers.providers.JsonRpcProvider(`${getRpcUrl()}/${nodeHash}`);
        // const txFromEthers = await  provider.getTransaction(txHash);
        // console.log("From Ethers:", txFromEthers);
        try {
            const code = await provider.getCode(response.to);
            response.isContract = (code > 0);
        } catch (e) {
            // console.log(e)
        }
        try {
            const erc20TransferEvent = new ethers.utils.Interface([
                "event Transfer(address indexed from, address indexed to, uint256 amount)"
            ]);
            const erc721TransferEvent = new ethers.utils.Interface([
                "event Transfer(address indexed from, address indexed to, uint256 indexed id)"
            ]);

            const erc20TransferTopic = erc20TransferEvent.getEventTopic("Transfer");
            // const erc721TransferTopic = erc721TransferEvent.getEventTopic("Transfer");

            const logsArray = response.receipt.logs

            let erc20tokenTransferArray = [];
            let erc721tokenTransferArray = [];

            for (const e of logsArray) {
                if (e.topics[0] === erc20TransferTopic) {
                    try {
                        // console.log(e)
                        const temp = erc20TransferEvent.parseLog(e)
                        const decimals = await getDecimal(provider, e.address);
                        const symbol = await getSymbol(provider, e.address);
                        const parsedEventData = {
                            from: temp["args"]["from"],
                            to: temp["args"]["to"],
                            amount: ethers.utils.formatUnits((temp["args"]["amount"]), decimals),
                            address: e.address,
                            symbol: (symbol ?? "TOKEN"),
                        }
                        erc20tokenTransferArray.push(parsedEventData)
                    } catch (err) {
                        const temp = erc721TransferEvent.parseLog(e)
                        const parsedEventData = {
                            from: temp["args"]["from"],
                            to: temp["args"]["to"],
                            id: (temp["args"]["id"]).toString(),
                        }
                        erc721tokenTransferArray.push(parsedEventData)
                    }
                }
            }

            response.erc20tokenTransferArray = erc20tokenTransferArray;
            response.erc721tokenTransferArray = erc721tokenTransferArray;

        } catch (e) {
            // console.log(e)
        }
        // console.log(response);
        return response;

    } catch (err) {
        let errMsg;
        if (err.message.includes("could not detect network")) {
            errMsg = "Unable to connect to given Node. Please check your internet connection.";
        } else if (err.message.includes("invalid hash")) {
            errMsg = "This transaction address is not valid. Please recheck it.";
        } else {
            errMsg = "Sorry we encountered an error. Please try again later.";
        }
        // console.log("Err", err.message);
        return {
            data: null,
            err: {
                msg: errMsg,
            },
        }
    }
}

async function getDecimal(provider, address) {
    try {
        const erc20Abi = [
            "function decimals() view returns (uint8)"
        ];
        const erc20Contract = new ethers.Contract(address, erc20Abi, provider);
        const decimals = await erc20Contract.decimals();
        return decimals;
    } catch (e) {
        return 18;
    }
}

async function getSymbol(provider, address) {
    try {
        const erc20Abi = [
            "function symbol() view returns (string)"
        ];
        const erc20Contract = new ethers.Contract(address, erc20Abi, provider);
        const symbol = await erc20Contract.symbol();
        return symbol;
    } catch (e) {
        return null
    }
}

async function getBlockData(nodeHash, blockNum) {
    try {

        // const axiosRes = await axios.post(
        //     `${getBackendBaseUrl()}/scan/${nodeHash}/getBlockdetails`,
        //     {
        //         blockNumber: blockNum,
        //     }
        // );
        // console.log(axiosRes.data);
        // if(!axiosRes.data) {
        //     throw new Error('block not found');
        // }
        // const block = JSON.parse(axiosRes.data);
        // console.log(block);
        // if (!block)
        //     throw new Error("block not found");


        // We're not saving block in the db as of now, hence we'll query from node directly
        const rpcUrl = `${getRpcUrl()}/${nodeHash}`;
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const block = await provider.getBlock(blockNum);
        if (!block)
            throw new Error("block not found");

        return block;
    } catch (err) {
        let errMsg;
        if (err.message.includes("could not detect network")) {
            errMsg = "Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.";
        } else if (err.message.includes("block not found") || err.message.includes("invalid block hash or block tag")) {
            errMsg = "Sorry, Block not found.";
        } else {
            errMsg = "Sorry we encountered an error. Please try again later.";
        }
        // console.log("Err", err.message);
        return {
            data: null,
            err: {
                msg: errMsg,
            },
        }
    }
}


export {
    getTxDetails,
    getBlockData
}
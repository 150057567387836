import "../styles/ApiKey.css";
import { getAllUserApiKeys, createApi, deleteApi } from "../api/home";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import IconButton from "@mui/material/IconButton";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'rgba(255,255,255,0.87) !important',
    fontSize: 13,
  },

}));
function ApiComponent() {

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([])
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState('2629800');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // console.log("value: ", value)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (value) => {
    console.log("handlevalue: ", value)
    createApiFun(value)
    setOpen(false);
  }

  const handleDelete = (apiKey) => {
    console.log("apiKey: ", apiKey)
    setOpen(false);
    deleteApiFun(apiKey)
  }

  useEffect(() => {
    async function getApiKeys() {
      const res = await getAllUserApiKeys();
      if (res.err) {
        toast.error("Sorry, couldn't fetch your API Keys", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setData(res.data.apiKeys)
      }
      // console.log("data: ", data)
    }
    getApiKeys();
  }, []);

  async function createApiFun(value) {
    try {
      const res = await createApi(value);
      if (res.err) {
        toast.error("Sorry, couldn't create a new API Key", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        const updatedRes = await getAllUserApiKeys();
        if (updatedRes.err) {
          // handle error
          console.log("error: ", updatedRes.err)
          toast.error("Sorry, couldn't create a new API Key", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setData(updatedRes.data.apiKeys);
        }
        toast.success("Hurray! Your API Key is made", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
    } catch (err) {
      toast.error("Sorry, couldn't create a new API key", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  console.log("value: ", value)
  async function deleteApiFun(apiKey) {
    try {
      const res = await deleteApi(apiKey)
      console.log("delRes: ", res)
      if (res.err) {
        toast.error('Sorry there was an error. Try again Later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        if (res.status === 200) {
          setData(data.filter(item => item.key !== apiKey))
          toast.success('Deleted successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error('Sorry there was an error. Try again Later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }

    } catch (err) {
      toast.error('Sorry there was an error. Try again Later.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <>
      <div className="api-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5rem', marginBottom: '2rem' }}>
          <h2>API Keys</h2>

          {
            data.length === 2 ?
              <DarkToolTip
                title="Apologies, you already have 2 live API Keys. Currently, all users are limited to have only 2 live API Keys.  If you need to create additional API Keys, you could reach out to us at team@buildbear.io"
                arrow
                placement="top"
                disableHoverListener={!disabled}
              >
                <span>
                  <Button
                    className="new-api-key"
                    disabled={disabled}
                    variant="contained"
                    onClick={() => {
                      handleClickOpen()
                    }}
                  >
                    + Add
                  </Button>
                </span>
              </DarkToolTip> :
              <Button className="new-api-key" style={{ backgroundColor: '#000' }} variant="contained" onClick={handleClickOpen}>+ Add</Button>
          }
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Create API Key</DialogTitle>
            <IconButton
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              <CancelIcon sx={{ fontSize: "25px" }} />
            </IconButton>
          </div>
          <DialogContent>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              style={{ width: '25rem' }}
            >
              <FormControl>
                <Select
                  id="demo-select"
                  value={value}
                  onChange={handleChange}
                >
                  <MenuItem value="2629800">1 month</MenuItem>
                  <MenuItem value="7889400">3 months</MenuItem>
                  <MenuItem value="15778800">6 months</MenuItem>
                  <MenuItem value="Never">Never</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleCreate(value);
            }} variant="contained" style={{ backgroundColor: '#000' }} className="new-api-key">Create New API Key</Button>
          </DialogActions>
        </Dialog>
        <table className="api-table">
          <thead>
            <tr>
              <th>API Key Token</th>
              <th>Expires At</th>
              {
                data.length != 0 ? <th></th> : null
              }

            </tr>
          </thead>
          {
            data.length != 0 ?
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.key}</td>
                    {
                      item.expiresAt ?
                        <td>{item.expiresAt}</td> :
                        <td>Never</td>
                    }
                    <td style={{ textAlign: 'end' }}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          handleDelete(item.key);
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: "25px" }} />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
              :
              // <div style={{ textAlign: "center" }}>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <IconButton
                      color="primary"
                      style={{ margin: 'auto', display: 'block' }}
                    >
                      <Inventory2Icon sx={{ fontSize: "35px" }} />
                    </IconButton>
                    <h4 style={{ textAlign: 'center' }}>You have yet to create an API-Key Token.</h4>
                  </td>
                </tr>
              </tbody>
            // </div>
          }

        </table>
      </div>
    </>
  )
}

export default ApiComponent;
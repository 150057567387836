import { formatCall } from "../format/call.js";

export async function printCall(
  op,
  gasCost,
  params,
  dependencies
) {

  const str = await formatCall(
    params.to,
    params.input,
    params.ret,
    params.value,
    params.gas,
    dependencies
  );
  return "CALL" +
          " " +
          str +
          ` (cost: ${gasCost})` +
          ` (to: ${params.to})`
}

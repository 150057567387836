import { Interface } from "ethers/lib/utils";




import { formatParam } from "./param.js";
import { formatResult } from "./result.js";

export async function formatError(
  revertData,
  dependencies
) {
  const commonErrors = [
    "function Error(string reason)",
    "function Panic(uint256 code)",
  ];
  try {
    const iface = new Interface(commonErrors);
    const parsed = iface.parseTransaction({
      data: revertData,
    });

    const formatted = formatResult(
      parsed.args,
      parsed.functionFragment,
      { decimals: -1, isInput: true, shorten: false },
      dependencies
    );
    return `${parsed.name}(${formatted})`;
  } catch {}

  // if error not common then try to parse it as a custom error
 

  for (const arti of dependencies.artifacts) {
    const artifact = arti;
    const iface = new Interface(artifact.abi);

    try {
      const errorDesc = iface.parseError(revertData);
      return `${errorDesc.name}(${formatResult(
        errorDesc.args,
        errorDesc.errorFragment,
        { decimals: -1, isInput: true, shorten: false },
        dependencies
      )})`;
    } catch {}
  }

  return `${"UnknownError"}(${formatParam(
    revertData,
    dependencies
  )})`;
}

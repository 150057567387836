import React, { useState, useEffect, useContext, } from 'react';
import { useNavigate } from "react-router-dom";
import Styled from "styled-components";
import GoogleIcon from 'mdi-react/GoogleIcon';
import { AuthContext } from "../home/App";
import { getGoogleAuthUrl, googleAuth, login, isAdmin } from "../api/home";
import Nav from "../components/Nav";

export default function GoogleLogin() {

    const navigate = useNavigate();
    const { state, dispatch } = useContext(AuthContext);
    const [data, setData] = useState({ errorMessage: "", isLoading: false });
    
    const [code, setCode] = useState();

    useEffect(() => {
        // console.log("Inside the function -> Code", code);

        const url = window.location.href;
        const hasCode = url.includes("?code=");
        // console.log(url, hasCode);

        if (hasCode) {
            setData({ ...data, isLoading: true });
            const newUrl = url.split("?code=");
            setCode(newUrl[1])
        }
    }, []);

    useEffect(async () => {
        // console.log("Inside the function -> Code", code);
    
        if (code) {
          const response = await login(code, "google");
          // console.log("res"+response)
          if (response.err) {
            setData({
              errorMessage: response.err.msg,
              isLoading: true,
            });
          } else {
            // console.log("user", response.data.user);
    
            // Check if current user is an admin
            const adminResponse = await isAdmin();
            if (adminResponse.err) {
              setData({
                errorMessage: adminResponse.err.msg,
                isLoading: true,
              });
            } else {
              if (adminResponse.data.status === 200) {
                // console.log("Is Admin");
                dispatch({
                  type: "ADMIN",
                  payload: { isAdmin: true },
                });
              } else {
                // console.log("Is not admin");
                dispatch({
                  type: "ADMIN",
                  payload: { isAdmin: false },
                });
              }
              setData({
                errorMessage: "",
                isLoading: false,
              });
              dispatch({
                type: "LOGIN",
                payload: { user: response.data.user, isLoggedIn: true },
              });
              // console.log("Navigate to login/google!!")
              navigate("/");
            }
          }
        }
      }, [code]);

    return (
        <>
            <Nav />
            <Wrapper>
                <section className="container" style={{ backgroundColor: '#fff', maxHeight: '83vh' }}>
                    <div className="github-login" style={{ height: '309px' }}>
                        <h3>Get Started with BuildBear</h3>
                        <span>{data.errorMessage}</span>
                        <div className="login-container">
                            {data.isLoading ? (
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <>
                                    <a
                                        className="login-link"
                                        href={getGoogleAuthUrl()}
                                        onClick={() => {
                                            setData({ ...data, errorMessage: "" });
                                        }}
                                    >
                                        <GoogleIcon style={{ color: '#000' }} />
                                        <span>Log in with Google</span>
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </Wrapper>
        </>
    )
}

const Wrapper = Styled.section`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Arial;
    
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      max-width: 600px;
      min-width: 400px;
      width: 55%;
      height: 55%;
      > h1 {
        font-size: 2rem;
        margin-bottom: 20px;
      }
      > span:nth-child(2) {
        font-size: 1.1rem;
        color: #808080;
        margin-bottom: 70px;
      }
      > span:nth-child(3) {
        margin: 10px 0 20px;
        color: red;
      }
      .login-container {
        width: 70%;
        border-radius: 3px;
        color: #3A3B3C;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > .login-link {
          text-decoration: none;
          color: #fff;
          cursor: default;
          display: flex;
          align-items: center;          
          height: 40px;
          cursor: pointer;
          > span:nth-child(2) {
            margin-left: 5px;
          }
        }
        
        .loader-container {
          display: flex;
          justify-content: center;
          align-items: center;          
          height: 40px;
        }
        .loader {
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          animation: spin 2s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
`;
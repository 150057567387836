import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/Nav.css';
// import BuildBear from '../assets/BuildBear.png'
import BuildBear2 from '../assets/BuildBear2.png'

function Nav() {
  return (
    <div className='nav-container'>
        <Link to="/">
        {/* <h3>BuildBear</h3> */}
        <img src={BuildBear2} alt="logo" width="250px" style={{marginTop: '-87px', marginLeft: '30px'}} />
        </Link>
    </div>
  )
}

export default Nav
import AdminDetails from "../components/Admin/AdminDetails"
import AllContainerTable from "../components/Admin/AllContainerTable";
import { Box } from "@mui/material";
// import Navbar from "../components/Navbar";
import Nav from "../components/Nav";
import SideNav from "../components/SideNav";


function Admin() {
    
  return (
    <Box>
      <Nav />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <SideNav />
        <Box sx={{ m: 5 }} style={{margin: 'auto'}}>
            <AdminDetails />
          <AllContainerTable />
        </Box>
      </div>
    </Box>
  );
}

export default Admin;
import { APPS } from "./constants"

export const getApp = () => {
  // console.log(window.location.hostname)
  const subdomain = getSubdomain(window.location.hostname);
  // console.log(subdomain)
  const main = APPS.find((app) => app.main);

  if (!main) throw new Error("Must have main app");

  if (subdomain === "") return main.app;

  const app = APPS.find(app => subdomain === app.subdomain);

  // console.log(window.location.hostname);

  if (!app) return main.app;
  return app.app;
};

const getSubdomain = (location) => {
  const locationParts = location.split(".");
  let initialIndex = 0;
  let lastIndex = -2;

  if (locationParts.slice(-1)[0] === "localhost") {
    initialIndex = 1;
    lastIndex = -1
  }
  return locationParts.slice(initialIndex, lastIndex)[0] ?? "";
}

import AllTransactionsComponent from "../components/node/allTransactions";
import Footer from "../components/Footer";

function AllTransaction() {
    return (
        <>
            <AllTransactionsComponent />
            <Footer />
        </>
    )
}

export default AllTransaction;
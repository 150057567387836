import { formatCall } from "../format/call.js";

export async function printCallCode(
  op,
  gasCost,
  params,
  dependencies
) {

  const str = await formatCall(
    params.to,
    params.input,
    params.ret,
    params.value,
    params.gas,
    dependencies
  );
  return "CALLCODE" +
          " " +
          str +
          ` (cost: ${gasCost})`
}

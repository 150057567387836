import { useParams } from "react-router-dom"
import { getTxDetails as getNodeTxDetails } from "../api/customNodeExplorer";
import { getTxDetails as getPublicTxDetails } from "../api/publicExplorer";
import { useContext } from "react";
import { AppContext } from "../eth-scan/App";

import axios from "axios";
import { getBackendBaseUrl } from "../api/explorer";

export function useTransaction(txHash) {
    let { nHash } = useParams();
    const { state } = useContext(AppContext);
    async function getTransactionDetails(txHash) {
        if (nHash) {
            return await getNodeTxDetails(nHash, txHash);
        } else {
            return await getPublicTxDetails(state.rpcUrl, txHash);
        }
    }

    return getTransactionDetails;
}

export async function getNodeDetails(nodeId) {
    const axiosRes = await axios.get(`${getBackendBaseUrl()}/node/${nodeId}`);
    return axiosRes.data;
}

export async function getEthersDetails(rpcURL, txHash){

    return  getPublicTxDetails( rpcURL, txHash);
}

export default useTransaction;
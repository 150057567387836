import { formatError } from "../format/error.js";

export async function printRevert(
  op,
  gasCost,
  params,
  dependencies
) {

  const str = await formatError(
    params.error,
    dependencies
  );
  return "REVERT" +
    " " +
    str +
    ` (cost: ${gasCost})`
}

export async function printRevertError(
  op,
  gasCost,
  params,
  dependencies
) {

  const str = await formatError(
    params.error,
    dependencies
  );
  return "REVERT" +
    " " +
    str
}
import { ethers } from "ethers";
import { formatContract } from "../format/contract.js";

export async function printCreate(
  op,
  gasCost,
  params,
  dependencies,
) {

  const str = await formatContract(
    params.code,
    ethers.BigNumber.from(params.value),
    null,
    params.deployedAddress,
    dependencies
  );
  return "CREATE" +
    " " +
    str +
    ` (cost: ${gasCost})`
}

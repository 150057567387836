import React, { useEffect, useState, useCallback, useRef } from "react";
// import { Link } from "react-router-dom";
import { QueryLink } from "../QueryLinkNavigate";
// import useNavigateQuery from "hooks/useNavigateQuery";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
// import { providers } from "ethers";
import { useContext } from "react";
import { AppContext } from "../../eth-scan/App";
// import { arrayify } from "ethers/lib/utils";
import { CircularProgress } from "@mui/material";

// Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function WriteContract(props) {
    const {
        writeFunctions,
        setWriteFunctions,
        writeLoadedOnce,
        setWriteLoadedOnce,
        pageName,
    } = props;
    // const [writeFunctions, setWriteFunctions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contractInstance, setContractInstance] = useState(
        props.contractInstance
    );

    useEffect(() => {
        // console.log("WriteContract useEffect");
        // console.log("props.contractInstance", props.contractInstance);
        setContractInstance(props.contractInstance);
        setLoading(false);
    }, [props.contractInstance]);

    const [tempLoading, setTempLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            console.log("WriteContract useEffect");
            setTempLoading(false);
        }, 4000);
    }, []);

    const [modalProvider, setModalProvider] = useState();
    const [, setProvider] = useState();
    const [account, setAccount] = useState();
    const [walletChainId, setWalletChainId] = useState(null);
    const [pageChainId, setPageChainId] = useState(null);

    const { state } = useContext(AppContext);
    const web3ModalRef = useRef();

    const [, forceUpdateState] = useState();
    // eslint-disable-next-line
    const forceUpdate = useCallback(() => forceUpdateState({}, []));
    useEffect(() => {
        if (!writeLoadedOnce) {
            generateWriteFunctionList();
            if (contractInstance && contractInstance.signer) {
                (async function () {
                    setAccount(await contractInstance.signer.getAddress());
                })();
            }
        }
        // eslint-disable-next-line
    }, [writeLoadedOnce, contractInstance]);

    async function callWriteFunctionBase(functionSignature, options, ...args) {
        try {
            // console.log(await contractInstance.signer.getAddress());
            // let estimatedGas = await contractInstance.estimateGas[functionSignature](
            //   ...args, options
            // );
            let tx = await contractInstance[functionSignature](
                ...args,
                options
            );
            // const weiEstimatedGas = estimatedGas.toString();
            // console.log("estimatedGas", weiEstimatedGas);
            // const receipt = await tx.wait();
            // console.log(receipt);
            return tx;
        } catch (err) {
            // try {
            //   // console.log('args', args)
            //   let tx = await contractInstance[functionSignature](...args, { ...options, gasLimit: 2000000 });

            //   return tx;
            // } catch (err) {
            throw err;
            // }
        }
    }

    async function generateWriteFunctionList() {
        try {
            // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            // console.log("Generating WriteFunction List");
            setLoading(true);
            let writeFunctions = [];
            for (const key of Object.keys(
                contractInstance.interface.functions
            )) {
                if (!contractInstance.interface.functions[key].constant) {
                    const functionData = {
                        inputs: contractInstance.interface.functions[
                            key
                        ].inputs.map((param) => {
                            return {
                                name: param.name,
                                type: param.type,
                                value: "", // The value of the input field in the frontend
                            };
                        }),
                        txHash: null,
                        status: null,
                    };
                    if (
                        contractInstance.interface.functions[key]
                            .stateMutability === "payable"
                    ) {
                        functionData.inputs.push({
                            name: "Payable Amount",
                            type: "default token",
                            value: "", // The value of the input field in the frontend
                        });
                    }
                    writeFunctions.push({
                        functionName:
                            contractInstance.interface.functions[key].name,
                        functionSignature: key,
                        functionData: functionData,
                        payable:
                            contractInstance.interface.functions[key]
                                .stateMutability === "payable",
                        loading: false,
                        err: null,
                    });
                }
            }

            setWriteFunctions(writeFunctions);
            setWriteLoadedOnce(true);
        } catch (err) {
            // console.log(err);
            return false;
        } finally {
            setLoading(false);
        }
    }

    async function queryWriteFunction(functionSignature) {
        let index = -1;
        let writeFunctionsCopy = writeFunctions;
        try {
            index = writeFunctionsCopy.findIndex((elem) => {
                return elem.functionSignature === functionSignature;
            });
            await ensureValidSigner();

            writeFunctionsCopy[index]["loading"] = true;
            writeFunctionsCopy[index]["err"] = null;
            writeFunctionsCopy[index].functionData.txHash = null;
            writeFunctionsCopy[index].functionData.status = null;
            setWriteFunctions(writeFunctionsCopy);
            forceUpdate();
            let ethAmt = null;
            let array = writeFunctionsCopy[index].functionData.inputs
                .filter((input) => {
                    if (input.name === "Payable Amount") {
                        ethAmt = input.value;
                        return false;
                    } else {
                        return true;
                    }
                })
                .map((input) => {
                    if (
                        input.value[0] === "[" &&
                        input.value[input.value.length - 1] === "]"
                    ) {
                        return JSON.parse(input.value);
                    } else {
                        return input.value;
                    }
                });
            // console.log(array, ethAmt)
            let tx = await callWriteFunctionBase(
                functionSignature,
                {
                    value: ethAmt ? ethers.utils.parseEther(ethAmt) : 0,
                },
                ...array
            );

            writeFunctionsCopy[index]["loading"] =
                "Tx sent, waiting for Receipt...";
            setWriteFunctions(writeFunctionsCopy);
            forceUpdate();
            const receipt = await tx.wait();
            writeFunctionsCopy[index].functionData.txHash =
                receipt.transactionHash;
            writeFunctionsCopy[index].functionData.status =
                receipt.status === 1 ? "Success" : "Failed";
        } catch (err) {
            // console.log(err.message);
            // console.log(err);
            // console.log(err.message, "This is error message ");
            if (err.message.includes("could not detect network")) {
                err.message =
                    "Could not detect network. Please check your internet connection or the rpc url (event='noNetwork')";
            } else if (
                err.message.includes(
                    "VM Exception while processing transaction: reverted with reason string"
                )
            ) {
                const errorJson = JSON.parse(
                    err.message.slice(
                        err.message.indexOf("{"),
                        err.message.lastIndexOf("}") + 1
                    )
                );
                let failedTxHash = errorJson.value.data.data.txHash;
                // console.log('hash', failedTxHash)
                err.txHash = failedTxHash;
                err.message = (
                    <QueryLink to={`/tx/${failedTxHash}`}>
                        {failedTxHash}
                    </QueryLink>
                );
                // `Tx Hash:`+ {<QueryLink to={`/tx/${failedTxHash}`}>View Transaction</QueryLink>} +`| Failed`;
            } else if (
                err.message.includes(
                    "Transaction reverted without a reason string"
                )
            ) {
                const errorJson = JSON.parse(
                    err.message.slice(
                        err.message.indexOf("{"),
                        err.message.lastIndexOf("}") + 1
                    )
                );
                // console.log(errorJson); // To be removed
                let failedTxHash = errorJson.value.data.data.txHash; // FIXME: Checked and fix if required
                // console.log('hash', failedTxHash)
                err.txHash = failedTxHash;
                err.message = (
                    <QueryLink to={`/tx/${failedTxHash}`}>
                        {failedTxHash}
                    </QueryLink>
                );
                // `Tx Hash:`+ {<QueryLink to={`/tx/${failedTxHash}`}>View Transaction</QueryLink>} +`| Failed`;
            } else if (err.message.includes("user rejected transaction")) {
                const errorJson = JSON.parse(
                    err.message.slice(
                        err.message.indexOf("{"),
                        err.message.lastIndexOf("}") + 1
                    )
                );
                // console.log(errorJson);
                err.userRejected = true;
                // let failedTxHash = "Test"; // FIXME: Checked and fix if required
                // console.log('hash', failedTxHash)
                // err.txHash = failedTxHash;
                // err.message = <QueryLink to={`/tx/${failedTxHash}`}>
                //   {failedTxHash}
                // </QueryLink>
            } else if (err.data && err.data.message) {
                err.message = `${err.message} | ${err.data.message}`;
                // console.log(err.message, "This is error message ");
            }
            writeFunctionsCopy[index]["err"] = err;
        } finally {
            writeFunctionsCopy[index]["loading"] = false;
            setWriteFunctions(writeFunctionsCopy);
            forceUpdate();
        }
    }

    function onInputChange(e, functionSignature, inputName) {
        let writeFunctionsCopy = writeFunctions;
        let index = writeFunctionsCopy.findIndex((elem) => {
            return elem.functionSignature === functionSignature;
        });

        writeFunctionsCopy[index].functionData.inputs = writeFunctionsCopy[
            index
        ].functionData.inputs.map((input) => {
            if (input.name === inputName) {
                input.value = e.target.value;
                return input;
            } else {
                return input;
            }
        });
        setWriteFunctions(writeFunctionsCopy);
        forceUpdate();
    }

    /********  Wallet  **********/

    async function ensureValidSigner() {
        try {
            if (!contractInstance.signer) {
                // alert("Please connect your web3 Account!");
                toast.error("Please connect your web3 Account!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                throw new Error("Please connect your web3 Account");
            }

            const pageRpcProvider = new ethers.providers.JsonRpcProvider(
                state.rpcUrl
            );
            const pageChainId = (await pageRpcProvider.getNetwork()).chainId;

            const chainIdWallet = (await contractInstance.provider.getNetwork())
                .chainId;
            if (chainIdWallet === pageChainId) {
                return true;
            } else {
                // alert(
                //   `Your Wallet is connected to a different chain id.\nPlease Ensure that you are connected to the same chain.
                //             Eth-scan is connected to : ChainId : ${pageChainId}
                //             Wallet is connected to : ChainId : ${chainIdWallet}
                //         `
                // );

                toast.error(
                    `Your Wallet is connected to a different chain id.\nPlease Ensure that you are connected to the same chain.
        Eth-scan is connected to : ChainId : ${pageChainId}
        Wallet is connected to : ChainId : ${chainIdWallet}`,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
                throw new Error(`Wallet and Page are connected to different chains.
                Eth-scan is connected to : ChainId : ${pageChainId}
                Wallet is connected to : ChainId : ${chainIdWallet}
                `);
            }
        } catch (err) {
            // console.log(err);
            throw err;
            // return false;
        }
    }

    /*******   Listeners  ********/
    // TODO: Handle Changes?
    async function onAccountChange(accounts) {
        // console.log("Accounts:", accounts);
        setAccount(accounts[0]);
    }

    async function onChainChanged(chainId) {
        // console.log("Chain ID:", Number(chainId));
        setWalletChainId(Number(chainId));
    }

    async function onDisconnect(err) {
        // console.log("Disconnected", err);
        setAccount(null);
    }

    const onWriteFunctionHandler = async (functionSignature) => {
        await queryWriteFunction(functionSignature);
    };

    /*****************************/
    useEffect(() => {
        // console.log(account);
        if (modalProvider) {
            modalProvider.on("accountsChanged", onAccountChange);
            modalProvider.on("chainChanged", onChainChanged);
            // eslint-disable-next-line
            modalProvider.on("disconnect", onDisconnect);
        }
        // eslint-disable-next-line
    }, [modalProvider]);

    const getSignerOrProvider = async (needSigner = false) => {
        const instance = await web3ModalRef.current.connect();
        setModalProvider(instance);
        const provider = new ethers.providers.Web3Provider(instance);
        setProvider(provider);
        const accounts = await provider.listAccounts();
        if (accounts) {
            setAccount(accounts[0]);
        }
        const { chainId } = await provider.getNetwork();
        setWalletChainId(chainId);
        const pageRpcProvider = new ethers.providers.JsonRpcProvider(
            state.rpcUrl
        );
        const pageChainId = (await pageRpcProvider.getNetwork()).chainId;
        setPageChainId(pageChainId);

        // provider.onAccountChange = onAccountChange;
        // provider.onChainChanged = onChainChanged;
        // instance.onDisconnect = onDisconnect;
        // instance.onConnect = onConnect;

        // const { chainId } = await provider.getNetwork();

        // if (chainId !== pageChainId) {
        //   alert(
        //     `Your Wallet is connected to a different chain id.\nPlease Ensure that you are connected to the same chain.
        //         Eth-scan is connected to : ChainId : ${pageChainId}
        //         Wallet is connected to : ChainId : ${chainId}
        //     `);
        //   console.log(chainId, pageChainId);
        //   throw new Error(`Change network to chainId:${pageChainId}`);
        // }

        if (needSigner) {
            const signer = provider.getSigner();
            return signer;
        }
        return provider;
    };

    const connectWallet = async () => {
        try {
            const signer = await getSignerOrProvider(true);
            setContractInstance(contractInstance.connect(signer));
            // console.log(contractInstance);
        } catch (error) {
            // console.log("Error", error);
        }
    };
    useEffect(() => {
        web3ModalRef.current = new Web3Modal({
            providerOptions: {},
            network: "any",
        });
    }, []);

    if (tempLoading) {
        return (
            <div  className="abi-container" >
                <div>
                    <div>
                        <h4>{pageName ?? "Write Contract"}</h4>
                    </div>
                </div>
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <>
                <div className="abi-container">
                    <div className="abi-wrapper">
                        <div>
                            <div>
                                <h4>{pageName ?? "Write Contract"}</h4>
                            </div>
                            {account ? (
                                <span className="connectedAccount">
                                    <div style={{ paddingLeft: "20px" }}>
                                        Connected Acc : (
                                        <span className="connectedAccount--address">
                                            {account}
                                        </span>
                                        )
                                    </div>
                                    <div>
                                        {walletChainId !== pageChainId ? (
                                            <>
                                                <div
                                                    style={{
                                                        paddingLeft: "20px",
                                                    }}
                                                    className="connectedAccount--chain--warning"
                                                >
                                                    Wallet Chain Id:{" "}
                                                    {walletChainId}
                                                </div>
                                                <div
                                                    style={{
                                                        paddingLeft: "20px",
                                                    }}
                                                >
                                                    Page Chain Id: {pageChainId}
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                style={{ paddingLeft: "20px" }}
                                            >
                                                Chain Id: {pageChainId}
                                            </div>
                                        )}
                                    </div>
                                </span>
                            ) : (
                                <div className="connect-wallet">
                                    <button
                                        className="customButton"
                                        onClick={connectWallet}
                                    >
                                        Connect to Web3
                                    </button>
                                </div>
                            )}
                        </div>
                        <div
                            className="readfunction-wrapper"
                            style={{ fontSize: "16px" }}
                        >
                            {/* <p>{ tempLoading ? <CircularProgress/> : "" }</p> */}
                            {loading ? "Loading..." : ""}
                            {Array.isArray(writeFunctions) &&
                            writeFunctions.length === 0
                                ? "No Write Functions"
                                : ""}
                            {writeFunctions.map((el, idx) => {
                                // console.log(el)
                                return (
                                    <div key={idx + 1}>
                                        <div className="readfunctionname">
                                            <div className="readfunctionname-header">
                                                <div className="left-column">
                                                    <div className="left-col-wrapper">
                                                        <div className="counter">
                                                            {" "}
                                                            {idx + 1}.
                                                        </div>
                                                        <div>
                                                            {el.functionName}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                {el.functionData.inputs
                                                    .length ? (
                                                    <div className="functionResponseHeader">
                                                        methodParams
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {!el.functionData.inputs.length
                                                    ? ""
                                                    : el.functionData.inputs &&
                                                      el.functionData.inputs.map(
                                                          (input, idx) => {
                                                              return (
                                                                  <div
                                                                      className="input-readfunction"
                                                                      key={idx}
                                                                  >
                                                                      <div>
                                                                          {
                                                                              input.name
                                                                          }{" "}
                                                                          <i>
                                                                              (
                                                                              {
                                                                                  input.type
                                                                              }
                                                                              )
                                                                          </i>
                                                                      </div>
                                                                      <input
                                                                          type="text"
                                                                          value={
                                                                              input.value
                                                                          }
                                                                          style={{
                                                                              width: "100%",
                                                                              color: "black",
                                                                          }}
                                                                          onChange={(
                                                                              e
                                                                          ) => {
                                                                              onInputChange(
                                                                                  e,
                                                                                  el.functionSignature,
                                                                                  input.name
                                                                              );
                                                                          }}
                                                                      />
                                                                  </div>
                                                              );
                                                          }
                                                      )}
                                                <button
                                                    className="functionQueryButton"
                                                    disabled={el.loading}
                                                    // onClick={async()=> await callReadFunction(el.functionName)}
                                                    onClick={async () => {
                                                        onWriteFunctionHandler(
                                                            el.functionSignature
                                                        );
                                                    }}
                                                >
                                                    {el.loading
                                                        ? "Writing..."
                                                        : "Write"}
                                                </button>
                                            </div>
                                            <div>
                                                {el.loading &&
                                                el.loading !== true
                                                    ? el.loading
                                                    : ""}
                                            </div>
                                            <div>
                                                {!el.functionData.txHash ? (
                                                    ""
                                                ) : (
                                                    <>
                                                        <div className="functionResponseHeader">
                                                            <QueryLink
                                                                to={`/tx/${el.functionData.txHash}`}
                                                            >
                                                                View
                                                                Transaction:{" "}
                                                                {
                                                                    el
                                                                        .functionData
                                                                        .txHash
                                                                }
                                                            </QueryLink>
                                                            {el.functionData
                                                                .status
                                                                ? `  |  ${el.functionData.status}`
                                                                : ``}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {el.err ? (
                                                <div
                                                    className="tx-failed"
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    {el.err.txHash ? (
                                                        <QueryLink
                                                            className="tx-failed"
                                                            to={`/tx/${el.err.txHash}`}
                                                        >
                                                            View Transaction:{" "}
                                                            {el.err.txHash} |
                                                        </QueryLink>
                                                    ) : el.err.userRejected ? (
                                                        "User Rejected | "
                                                    ) : (
                                                        el.err.message + " | "
                                                    )}
                                                    {` Failed`}
                                                    {/* <div className="functionResponseHeader">
                          Error during function call:
                        </div> */}
                                                    {/* {"->>  "} */}
                                                    {/* {el.err.message} */}
                                                    {/* {el.err.address} */}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WriteContract;

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BACKEND_URL = `https://backend.${BASE_URL}`;
export const RPC_URL = `https://rpc.${BASE_URL}`;
export const EXPLORER_BASE = `https://explorer.${BASE_URL}`;

// FOR TESTING LOCALLY
// export const GITHUB_AUTH_URL = 'https://github.com/login/oauth/authorize?client_id=75e54385731822086386';

// PRODUCTION SERVER GITHUB URL
// export const GITHUB_AUTH_URL = `https://github.com/login/oauth/authorize?client_id=${CLIENT_ID}`;
export const GITHUB_AUTH_URL = `${BACKEND_URL}/user/login/github`;

export const ETH_SCAN_BASE_URL = `${EXPLORER_BASE}/node`;
export const FAUCET_BASE_URL = `https://faucet.${BASE_URL}`;
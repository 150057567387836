import React from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ethers } from "ethers";
import "../styles/Layout.css";
import { getNodeUrl, getNodeExplorerUrl } from "../api/explorer";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";

// Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Footer = () => {

  const [metamaskLock, setMetamaskLock] = useState();

  async function checkMetamaskLock() {
    const { ethereum } = window
    const accounts = await ethereum._metamask.isUnlocked();
    if (accounts) {
      setMetamaskLock(true)
    }
    else {
      setMetamaskLock(false)
    }
    // console.log(accounts);
  }

  useEffect(() => {
    checkMetamaskLock()
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", checkMetamaskLock);
    // console.log(checkMetamaskLock)
    return () => document.removeEventListener("visibilitychange", checkMetamaskLock);
  }, []);

  let { nHash } = useParams();
  const connectMetaMask = async () => {
    if (window.ethereum) {
      let provider = new ethers.providers.JsonRpcProvider(getNodeUrl(nHash));
      const { chainId } = await provider.getNetwork();
      // console.log(chainId);
      let formattedName = null;
      try {
        formattedName = nHash
          .split("_")
          .slice(0, -1)
          .join(" ");
      } catch (err) {
        // console.log("Error during name formating");
      }

      if (!formattedName) {
        formattedName = nHash;
      }
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: `Buildbear ${formattedName}`,
              chainId: ethers.utils.hexlify(chainId),
              rpcUrls: [getNodeUrl(nHash)],
              blockExplorerUrls: [getNodeExplorerUrl(nHash)],
              nativeCurrency: {
                name: "BB Ether",
                symbol: "BB ETH",
                decimals: 18,
              },
            },
          ],
        })
        .then(() =>
          // alert("Succesffully added")
          toast.success('Succesffully added', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }),
          checkMetamaskLock()
        )
        .catch((e) => {
          if (e.code === 4902) {
            // console.log("network is not available, add it");
          } else {
            // alert("Could not set network");
            toast.error('Could not set network', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } else {
      // alert("please add MetaMask to your browser");
      toast.warn('please add MetaMask to your browser', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div className="footer-container">
      {nHash ? (
        <div className="edit-rpc">
          <Button
            // style={{
            //   color: "white",
            //   background: "#495464",
            //   borderRadius: "10px",
            //   padding: "8px",
            // }}
            variant="outlined"
            onClick={connectMetaMask}
          >
            {/* Add Network to MetaMask{" "} */}
            {
              !metamaskLock ? "Unlock and Add to Metamask" : "Add to Metamask"
            }
          </Button>
        </div>
      ) : (
        <Link to="/">
          <div className='edit-rpc'>
            Edit RPC
          </div>
        </Link>
      )}
    </div>
  );
};

export default Footer;

import { isFunction } from "lodash";

function handleArtifactParse(files, onAbiParsed, onParsingProgress, nodeId) {
  let payload = [];
  let fileCount = 0;
  let netCount = 0;
  function handleReaderError() {
    fileCount--;
  }
  function handleReaderAbort() {
    fileCount--;
  }

  function handleReaderLoad(e) {
    try {
      const fileData = JSON.parse(e.target.result);
      if (
        fileData.abi &&
        fileData.bytecode &&
        fileData.deployedBytecode &&
        fileData.contractName
      ) {
        payload.push({
          abi: JSON.stringify(fileData.abi),
          contractName: fileData.contractName,
          bytecode: fileData.bytecode,
          deployedBytecode: fileData.deployedBytecode,
        });
      }

      //   console.log(payload);
    } catch (err) {
      // console.log(err);
    } finally {
      fileCount--;
    }
  }
  if (isFunction(onParsingProgress)) {
    onParsingProgress(0);
  }
  for (let file of files) {
    if (file.type === "application/json") {
      if (file.name.endsWith(".dbg.json")) {
        // Ignore files ending with .dbg.json
      } else {
        try {
          const reader = new FileReader();
          reader.onload = handleReaderLoad;
          reader.onerror = handleReaderError;
          reader.onabort = handleReaderAbort;

          reader.readAsText(file);
          fileCount++;
          netCount++;
          //   console.log(fileCount);
        } catch (err) {
          // console.log(err);
        }
      }
    }
  }

  async function waitForParsing() {
    if (fileCount > 0) {
      // console.log("Parsing in progress");
      if (isFunction(onParsingProgress)) {
        try {
          onParsingProgress(Math.floor(((netCount - fileCount) / netCount) * 100));
        } catch (err) {
          // console.log(err);
        }
      }
      setTimeout(waitForParsing, 500);
    } else {
      if (isFunction(onParsingProgress)) {
        try {
          onParsingProgress(100);
        } catch (err) {
          // console.log(err);
        }
      }
      if (isFunction(onAbiParsed)) {
        onAbiParsed(payload);
      }
    }
  }
  waitForParsing();
}

export default handleArtifactParse;
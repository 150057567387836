import React, { useState, useEffect } from 'react';
import '../../styles/Abi.css';
import { saveAbi, saveAbiWithBackend, getSourceFile } from "../../api/explorer";
import { json, useParams } from "react-router-dom";
// import { QueryLink, QueryNavigate } from "../QueryLinkNavigate";
import useNavigateQuery from "../../hooks/useNavigateQuery";
import { ethers } from 'ethers';
import { useContext } from "react";
import { AppContext } from "../../eth-scan/App";
// import ProxyAddress from './ProxyAddress';
import axios from 'axios';

const Code = (props) => {
    // const navigate = useNavigate();
    const navigateQuery = useNavigateQuery();
    const addressHash = useParams().addressHash;
    const nHash = useParams().nHash;
    const { state } = useContext(AppContext);
    const provider = new ethers.providers.JsonRpcProvider(state.rpcUrl);
    const [abi, setAbi] = useState(props.abi);
    const [abiErrMsg, setAbiErrMsg] = useState("");
    const [isSave, setIsSave] = useState(true);
    const [solidityGet, setSolidityGet] = useState()

    async function onAbiSubmit(e) {
        e.preventDefault();
        setAbiErrMsg("");
        try {
            JSON.parse(abi);
        } catch (err) {
            if (err.message.includes("Unexpected")) {
                setAbiErrMsg("Invalid JSON");
            }
            // console.log("err", err.message);
            return;
        }
        const deployedBytecode = await provider.getCode(addressHash);
        // console.log(nHash);
        if (nHash) {
            await saveAbiWithBackend(
                nHash,
                addressHash,
                abi,
                deployedBytecode
            )
        } else {
            await saveAbi(
                nHash,
                addressHash,
                abi,
                deployedBytecode
            );
        }
        props.setAbi(abi);
        props.setReadLoadedOnce(false);
        props.setWriteLoadedOnce(false);
        navigateQuery(`/address/${addressHash}#readContract`);
        // window.location.reload();
    }

    

    function getContract() {
        if (typeof props.soliditySource === 'string') {
            try {
                const parseData = JSON.parse(props.soliditySource.slice(1, -1))
                if (typeof parseData === "object") {
                    //For multiple contracts
                    return (<div>
                        <h4 style={{textAlign: "center"}}>Contract Source Code (Solidity)</h4>
                        {Object.entries(parseData.sources).map(([key, value]) => (
                            <form key={key} style={{ marginBottom: "4vh" }} >
                                <h4>{key}</h4>
                                <textarea
                                    type="text"
                                    rows={20}
                                    cols={30}
                                    spellCheck="false"
                                    autoCorrect='off'
                                    autoCapitalize='off'
                                    // wrap="off"
                                    value={value.content}
                                >
                                </textarea>
                            </form>

                        ))}
                    </div>)
                }
            } catch (e) {
                try {
                    if (typeof props.soliditySource === "string") {
                        // solData(props.soliditySource)
                        //For single contract
                        return (<div>
                            <form  >
                                <h4>Contract Source Code (Solidity)</h4>
                                <textarea
                                    type="text"
                                    rows={20}
                                    cols={30}
                                    spellCheck="false"
                                    autoCorrect='off'
                                    autoCapitalize='off'
                                    // wrap="off"
                                    value={props.soliditySource}
                                >
                                </textarea>
                            </form>

                        </div>)
                    }
                } catch (e) {
                    // console.log(e)
                }
            }
        }
    }




    return (
        <>
            <div className='abi-container'>
                <div className='abi-wrapper'>

                    {getContract()}

                    <form onSubmit={onAbiSubmit}>
                        <h4>Contract ABI</h4>
                        <textarea
                            type="text"
                            rows={10}
                            cols={30}
                            value={abi}
                            spellCheck="false"
                            autoCorrect='off'
                            autoCapitalize='off'
                            onChange={(e) => setAbi(e.target.value)}
                        />
                        <div className="abi--error" style={{ color: 'red' }}>{abiErrMsg}</div>

                        { props.soliditySource ? "" : (<>{isSave ? <button className="customButton" type='submit'>Save</button> : <></>}</>) }
                        {/* { isSave ? <button className="customButton" type='submit'>Save</button> : <></>} */}
                        {/* <button className="customButton" type='submit'>{props.abi ? "Update" : "Save"}</button> */}
                    </form>
                </div>
            </div>
            {/* <ProxyAddress /> */}
        </>
    )
}

export default Code;
import { txList } from "./data";
import { ethers } from "ethers";
import axios from "axios";
import { compareBytecode } from "../components/ParseTrace/utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Backend_Url = `https://backend.${BASE_URL}`;
const Rpc_Url = `https://rpc.${BASE_URL}`;
const Explorer_Url = `https://explorer.${BASE_URL}`;

async function getTransactions(address, count) {
  // TODO: Query From Backend once backend is set up

  return txList.slice(0, count);
}
function initialiseLocalStorageAbi(nodeId) {
  const abis = JSON.parse(localStorage.getItem(`abis-${nodeId}`));

  if (!abis) {
    // console.log("ABIS Don't Exist, Initialising");
    localStorage.setItem(`abis-${nodeId}`, JSON.stringify([]));
  } else if (!Array.isArray(abis)) {
    // console.log("Old Abi storage scheme, changing to array. one time fix up.");
    localStorage.setItem(`abis-${nodeId}`, JSON.stringify([]));
  }
}

async function saveAbi(nodeId, contractAddress, abi, deployedBytecode, code) {
  // TODO: Save ABI in the server
  // for now, we'll use localStorage
  let response = {};
  // try {
  //   initialiseLocalStorageAbi();

  //   const abis = JSON.parse(localStorage.getItem("abis"));
  //   abis[contractAddress] = abi;
  //   localStorage.setItem("abis", JSON.stringify(abis));

  //   response = {
  //     status: 200,
  //   };
  // } catch (err) {
  //   response = {
  //     status: 500,
  //   };
  // }

  // return response;

  try {
    initialiseLocalStorageAbi(nodeId);

    const abis = JSON.parse(localStorage.getItem(`abis-${nodeId}`));
    // console.log(abis);

    const index = abis.findIndex((singleAbi) => {
      return singleAbi.address === contractAddress;
    });
    if (index >= 0) {
      abis.splice(index);
    }
    abis.push({
      address: contractAddress,
      abi: abi,
      deployedBytecode: deployedBytecode,
      bytecode: code,
    });
    // console.log(abis);
    localStorage.setItem(`abis-${nodeId}`, JSON.stringify(abis));

    response = {
      status: 200,
    };
  } catch (err) {
    response = {
      status: 500,
    };
  }
  return response;
}

async function saveAbiWithBackend(
  nHash,
  addressHash,
  abi,
  deployedBytecode,
  code
) {
  let response = {};
  const nodeId = nHash;
  try {
    const abiSaveEndPoint = `${getBackendBaseUrl()}/abi`;
    await axios.post(abiSaveEndPoint, {
      address: addressHash.toLowerCase(),
      abi: abi,
      nodeId: nHash,
      deployedBytecode,
    });
    await saveAbi(nodeId, addressHash, abi, deployedBytecode);
    response = {
      status: 200,
    };
  } catch (err) {
    // console.log(err);
    response = {
      status: 500,
    };
  }
  return response;
}

async function getAbi(nodeId, contractAddress, rpcUrl) {
  initialiseLocalStorageAbi(nodeId);

  const abis = JSON.parse(localStorage.getItem(`abis-${nodeId}`));
  // console.log(abis);

  const singleAbi = abis.find((singleAbi) => {
    return singleAbi.address === contractAddress;
  });
  if (singleAbi) {
    // console.log("Found ABI in localStorage");
    return singleAbi.abi;
  }

  // Check if abi exists in etherscan/polygon/mumbaiPolygon
  try {
    const networkType = localStorage.getItem("networkType");
    if (networkType !== "Custom") {
      let scanBaseUrl = "";
      let apiKey = "";
      switch (networkType) {
        case "Mainnet":
          scanBaseUrl = "api.etherscan.io";
          apiKey = "CTCRNFHY7UTPTE32G9EEJBF1SN4AXZ7JNM";
          break;
        case "Polygon Mainnet":
          scanBaseUrl = "api.polygonscan.com";
          apiKey = "TSBXYBYT5II4N446V7TUVFNWGUSCU9R19W";
          break;
        case "Polygon Mumbai":
          scanBaseUrl = "api-testnet.polygonscan.com";
          apiKey = "TSBXYBYT5II4N446V7TUVFNWGUSCU9R19W";
          break;
        default:
        //used to resolve the warning  // Line 129:7:Expected a default case  default-case
      }

      const response = await (
        await fetch(
          `https://${scanBaseUrl}/api?module=contract&action=getabi&address=${contractAddress}&apikey=${apiKey}`
        )
      ).json();
      // console.log(response);
      if (response.message === "OK") {
        if (rpcUrl) {
          const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
          const deployedBytecode = await provider.getCode(contractAddress);
          await saveAbi(nodeId, contractAddress, response.result, deployedBytecode);
        } else {
          await saveAbi(nodeId, contractAddress, response.result);
        }
        // console.log("Found Abi from *scan api");
        return response.result;
      }
    } else {
      // console.log("Using custom network, not fetching from *scan");
    }
  } catch (err) {
    // console.log(err.message);
    // console.log("Didn't find abi in *scan api");
  }
}

async function getAbiFromBackend(nHash, contractAddress) {
  try {
    const nodeId = nHash
    initialiseLocalStorageAbi(nodeId);

    const abis = JSON.parse(localStorage.getItem(`abis-${nodeId}`));
    // console.log(abis);

    const singleAbi = abis.find((singleAbi) => {
      return singleAbi.address === contractAddress;
    });
    if (singleAbi) {
      // console.log("Found ABI in localStorage");
      return singleAbi.abi;
    } else {
      const abiGetEndPoint = `${getBackendBaseUrl()}/abi/${nHash}/${contractAddress}`;
      let res
      try {
        res = await axios.get(abiGetEndPoint);
      } catch (_) { }
      if ((res?.data?.error)) {
        // console.log("no abi from backend")
      } else {
        if (res?.data?.abi) {
          const rpcUrl = getNodeUrl(nHash);
          if (rpcUrl) {
            const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const deployedBytecode = await provider.getCode(contractAddress);
            await saveAbi(nodeId, contractAddress, res.data.abi, deployedBytecode);
          } else {
            await saveAbi(nodeId, contractAddress, res.data.abi);
          }

          return res.data.abi;
        }
      }
    }

    try {
      // If ABI not in localStorage, look in artifact Array from Backend.
      const rpcUrl = getNodeUrl(nHash);
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const deployedBytecode = await provider.getCode(contractAddress);
      // console.log("deployedBytecode", deployedBytecode);
      const abis2 = await axios.get(`${Backend_Url}/abis/${nHash}`);
      let nodeABIs = abis2.data;
      // console.log(nodeABIs);
      let singleNodeABIs = nodeABIs?.find((singleAbi) => {
        return (compareBytecode(deployedBytecode, singleAbi.deployedBytecode) > 0.5);
      });
      if (singleNodeABIs) {
        // console.log("Found ABI in Artifact Array in Backend");
        // console.log("abi abi", singleNodeABIs.abi)
        return singleNodeABIs.abi;

      }
      else {
        return;
      }
    } catch (err) {
      // console.log(err);
    }



  } catch (err) {
    // console.log(err);
  }
}



async function getSourceFile(nodeId, address) {

  const url = `${Backend_Url}/node/verify/${nodeId}?address=${address}&module=contract&action=getsourcecode`

  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    // console.log(response)
    const data = (await response.json());
    return {
      data,
      err: null,
      status: response.status,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };

  }


}

async function getVulnerability(nodeId, address) {
  const url = `${Backend_Url}/node/verify/vulnerability/${nodeId}/${address}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = (await response.json());
    return {
      data,
      err: null,
      status: response.status,
    };
  } catch (err) {
    // console.log(err);
    return {
      data: null,
      err,
    };
  }
}

function getDefaultNetworks() {
  const networks = [
    {
      name: "Mainnet",
      rpc: "",
      // rpc: "https://eth-mainnet.alchemyapi.io/v2/9QgTwF5fdKsv_H7TKTvIrWkzctYKmEp1",
      chainId: "1",
    },
    {
      name: "Polygon Mainnet",
      rpc: "",
      // rpc: "https://polygon-mainnet.g.alchemy.com/v2/0NgDEIx1DVeP_LcRjOO4BD4dyUmHeE2E",
      chainId: "137",
    },
    {
      name: "Polygon Mumbai",
      rpc: "",
      // rpc: "https://polygon-mumbai.g.alchemy.com/v2/VBg5j34wNmSurLLcJhVrcngnyh3tgf21",
      chainId: "80001",
    },
    { name: "Custom", rpc: "" },
  ];
  return networks;
}

function getNodeUrl(nodeHash) {
  return `${Rpc_Url}/${nodeHash}`;
}

function getNodeExplorerUrl(nodeHash) {
  return `${Explorer_Url}/${nodeHash}`;
}

function getBackendBaseUrl() {
  return Backend_Url;
}

function getRpcUrl() {
  return Rpc_Url
}



async function getAbiForTracer(contractAddress, nHash) {

  const nodeId = nHash;

  const labis = JSON.parse(localStorage.getItem(`abis-${nodeId}`));

  if (labis) {
    const arti = labis.find((singleAbi) => {
      return singleAbi.address === contractAddress;
    });
    if (arti)
      return arti.abi
  }
  const abiGetEndPoint = `${getBackendBaseUrl()}/abi/${nHash}/${contractAddress}`
  let res;
  try {
    res = await axios.get(abiGetEndPoint);
  } catch {
    return [];
  }
  // console.log(res)


  if (res?.data?.abi) {
    return res.data.abi
  }


  return []

}


export {
  getTransactions,
  saveAbi,
  saveAbiWithBackend,
  getAbi,
  getAbiFromBackend,
  getDefaultNetworks,
  getNodeUrl,
  getAbiForTracer,
  getNodeExplorerUrl,
  getBackendBaseUrl,
  getRpcUrl,
  getSourceFile,
  getVulnerability,
};

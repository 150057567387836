import React, { useState } from "react";
import { QueryLink } from "./QueryLinkNavigate";
import useNavigateQuery from "../hooks/useNavigateQuery";
import '../styles/ExplorerNavbar.css'
// import { useNavigate } from "react-router-dom";
// import BuildBear from '../assets/BuildBear.png'
import BuildBear2 from '../assets/BuildBear2.png'
import SearchIcon from "@material-ui/icons/Search";



const Navbar = (props) => {
    var url;
    const [navInput, setNavInput] = useState("");
    // const navigate = useNavigate();
    const navigateQuery = useNavigateQuery();

    function handleNavSubmit(e) {
        e.preventDefault();
        navigateQuery(`/${url}/${navInput}`);
        setNavInput("");
    }

    if (navInput.length > 50) {
        url = 'tx';
    } else if (navInput.length === 42) {
        url = 'address';
    } else {
        url = 'block';
    }
    return (
        <div className="navbar-container">
            <div className="navbar-logo">
                <QueryLink to="/home">
                    <div className="logo">
                        <img src={BuildBear2} alt="logo" width="250px" style={{ marginTop: '24px', marginLeft: '-10px' }} />
                    </div>
                </QueryLink>
            </div>
            <div className="navbar-right-col">
                <div className="navbar-search">
                    <form onSubmit={handleNavSubmit}>
                        <input
                            className="datainput-field"
                            name="transaction"
                            label="transaction"
                            value={navInput}
                            variant="filled"
                            placeholder="Enter Address / Tx Hash / Block"
                            onChange={e => { setNavInput(e.target.value.replace(/^\s+|\s+$/gm,'')) }}
                        />
                        <button className="datainput-submit" variant="contained" type="submit">
                            <SearchIcon style={{ fill: "#e8e8e8" }} />
                        </button>
                    </form>
                </div>
                <div className="rpc-btn">
                    {/* <QueryLink to="/">
                    <button>Edit RPC</button>
                </QueryLink> */}
                </div>
            </div>

        </div>
    )
}

export default Navbar

import React, { useEffect, useState } from 'react'
// import { ethers } from 'ethers';
import { Spinner } from 'react-bootstrap'
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from 'react-router-dom';
// import { useContext } from "react";
// import { AppContext } from "../../App";
import useTransaction from "../../hooks/useTransaction";
const Logs = ({ txDetails }) => {
    const txHash = useParams().txHash;
    // const { state } = useContext(AppContext);
    // const rpcUrl = state.rpcUrl;

    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState(null);

    const [logs, setLogs] = useState()
    // const [topics, setTopics] = useState()

    // const getTxDetails = useTransaction();

    const getLogs = async () => {
        setLoading(true);
        setErrMsg(null);
        try {
            // const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
            // const response = await provider.getTransactionReceipt(txHash)
            const response = txDetails;
            const responseLogs = response.receipt.logs;
            setLogs(responseLogs)

            // console.log("Logs", logs)
        } catch (err) {
            if (err.message.includes("could not detect network")) {
                setErrMsg("Unable to connect to given RPC URL. Please check your internet connection or recheck the rpc url.")
            } else {
                setErrMsg(err.message);
            }
            // console.log(err.message);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (errMsg) {
        return (
            <div className="containerbox">
                <div className="errContainer">
                    <h2>Error</h2>
                    <div className="errMsg">
                        {errMsg}
                    </div>
                </div>
            </div>
        )
    } else if (loading) {
        return <Spinner animation="grow" style={{ width: '100px', height: '100px', marginLeft: '30vw', marginTop: '10vw' }} />
    }
    else {
        return (
            <>
                <div className='containerbox' style={{ paddingTop: '0' }}>
                    {/* <h2>Transaction Logs</h2> */}
                    <div className='log-box'>
                        {/* <div className='logContainer' style={{backgroundColor: 'transparent'}}>
                    <Link to={`/tx/${txHash}`} >
                        <ArrowBackIcon/> 
                        {`Go Back to Tx`}
                    </Link>
                </div> */}
                        {Array.isArray(logs) && logs.length === 0 ? "Event is empty" : ""}
                        {logs.map(log => (
                            <div className='logContainer'>
                                <div>
                                    <div className='row' style={{ display: 'flex' }}>
                                        <p className='titlelog' style={{ width: '200px', fontSize: '17px' }}>Address: </p>
                                        <p className='valuelog' style={{ width: '700px', fontSize: '15px' }}>  {log.address}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='titlelog' style={{ width: '200px', fontSize: '17px' }}>Block Number</p>
                                        <p className='valuelog' style={{ width: '700px', fontSize: '15px' }}>{log.blockNumber}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='titlelog' style={{ width: '200px', fontSize: '17px' }}>Data:</p>
                                        <p className='valuelog' style={{ width: '700px', overflowX: 'auto', fontSize: '15px' }}>{log.data}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='titlelog' style={{ width: '200px', fontSize: '17px' }}>Log Index:</p>
                                        <p className='valuelog' style={{ width: '700px', fontSize: '15px' }}>{log.logIndex}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='titlelog' style={{ width: '200px', fontSize: '17px' }}>Topics:</p>
                                        <p className='valuelog' style={{ width: '700px', fontSize: '15px' }}>{log.topics.map(e => (
                                            <li style={{ marginBottom: '3px' }}>{e}</li>
                                        ))}</p>
                                    </div>
                                    <div className='row'>
                                        <p className='titlelog' style={{ width: '200px', fontSize: '17px' }}>Transaction Index:</p>
                                        <p className='valuelog' style={{ width: '700px', fontSize: '15px' }}>{log.transactionIndex}</p>
                                    </div>




                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </>

        )
    }
}
export default Logs